import React, { Component } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft,faPlus,faSave } from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from '../../../config';
import lg1 from "../../../images/funarpen.jpg"
import lg2 from "../../../images/logotj.png"
import dcmt from "../../../images/document.png"
class EditarCertRj extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
    };
   
  }

  componentDidMount() {
    document.title = "Editar certificado";
    var url  = window.location.href;
    const match = url.match(/edit\/(.*)$/);
    fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/dados-certificado/${match[1]}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({data,Loader:false})
})
}else if(response.status == 404){

  this.setState({error:true,Loader:false})
}
      })
}

 


  render() {
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    if(this.state.error == true){
      return(<>
      <div class="p404">
       <div>
        <img src={dcmt}/>
       <p>Desculpe, Esse documento não foi encontrado.</p>
       <button type="submit" class="btn btn-primary float-left ml-2" onClick={()=>{
                  this.props.navigate(`/${window.location.pathname.split("/")[1]}`)
               }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Início</button>
       </div>
     
      
      </div>
      </>)
    }
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight">Editar Certidão TjRj </h2></div></header>
        <main>
          <div className="py-12">
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="col-12">
        
                  <div className="card">
                    <div className="card-header">
                      <h3>Emissão de certidão:</h3>
                    </div>
                    <div className="card-body">
                      <div className="form-group">
                        <label>Nome do oficio:</label>
                        <select
                        className="form-control form-control-sm"
                        value={this.state.data.cartorio.id}
                        onChange={(e) => {
                          this.setState(prevState => ({
                            data: {
                              ...prevState.data,
                              cartorio: {
                                ...prevState.data.cartorio,
                                id: e.target.value
                              }
                            }
                          }));
                        }}
                      >
                        <option style={{ display: 'none' }}></option>
                        {this.state.data.cartorios.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.nome}
                          </option>
                        ))}
                      </select>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group mt-2">
                            <label>Tipo de Ato:</label>
                            <select
                            className="form-control form-control-sm"
                            value={this.state.data.tipo_ato}
                            onChange={(e) =>
                              this.setState(prevState => ({
                                data: { ...prevState.data, tipo_ato: e.target.value }
                              }))
                            }
                          >
                            <option value="CERTIDÃO">CERTIDÃO</option>
                            <option value="CERTIDÃO DE NASCIMENTO">CERTIDÃO DE NASCIMENTO</option>
                            <option value="CERTIDÃO DE CASAMENTO">CERTIDÃO DE CASAMENTO</option>
                            <option value="CERTIDÃO DE ÓBITO">CERTIDÃO DE ÓBITO</option>
                            <option value="PROCURAÇÃO PÚBLICA">PROCURAÇÃO PÚBLICA</option>
                            <option value="ESCRITURA PÚBLICA">ESCRITURA PÚBLICA</option>
                          </select>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group mt-2">
                            <label>Tipo de Certidão:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              value={this.state.data.tipo_certidao}
                              onChange={(e) =>
                                this.setState(prevState => ({
                                  data: { ...prevState.data, tipo_certidao: e.target.value }
                                }))
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group mt-2">
                            <label>Número do Livro:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              value={this.state.data.numero_livro}
                              onChange={(e) =>
                                this.setState(prevState => ({
                                  data: { ...prevState.data, numero_livro: e.target.value }
                                }))
                              }
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group mt-2">
                            <label>Data da Prática:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              value={this.state.data.data_pratica}
                              onChange={(e) =>
                                this.setState(prevState => ({
                                  data: { ...prevState.data, data_pratica: e.target.value }
                                }))
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group mt-2">
                            <label>Tipo de Cobrança:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              value={this.state.data.tipo_cobranca}
                              onChange={(e) =>
                                this.setState(prevState => ({
                                  data: { ...prevState.data, tipo_cobranca: e.target.value }
                                }))
                              }
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group mt-2">
                            <label>Hipossuficiente:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              value={this.state.data.hipossuficiente}
                              onChange={(e) =>
                                this.setState(prevState => ({
                                  data: { ...prevState.data, hipossuficiente: e.target.value }
                                }))
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group mt-2">
                            <label>Nº Folha:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              value={this.state.data.numero_folha}
                              onChange={(e) =>
                                this.setState(prevState => ({
                                  data: { ...prevState.data, numero_folha: e.target.value }
                                }))
                              }
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group mt-2">
                            <label>Nº Termo:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              value={this.state.data.numero_termo}
                              onChange={(e) =>
                                this.setState(prevState => ({
                                  data: { ...prevState.data, numero_termo: e.target.value }
                                }))
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group mt-2">
                            <label>Matricula Nacional CNJ:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              value={this.state.data.cnj}
                              onChange={(e) =>
                                this.setState(prevState => ({
                                  data: { ...prevState.data, cnj: e.target.value }
                                }))
                              }
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group mt-2">
                            <label>Tipo de Livro CNJ:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              value={this.state.data.tipo_livro_cnj}
                              onChange={(e) =>
                                this.setState(prevState => ({
                                  data: { ...prevState.data, tipo_livro_cnj: e.target.value }
                                }))
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group mt-2">
                        <label>Observações:</label>
                        <textarea
                          className="form-control form-control-sm"
                          value={this.state.data.observacoes}
                          onChange={(e) =>
                            this.setState(prevState => ({
                              data: { ...prevState.data, observacoes: e.target.value }
                            }))
                          }
                        />
                      </div>
                      <h3 className="mt-3">Participantes</h3>
                    <div className="row">
                      <div className="col-4">
                        <div className="form-group mt-2">
                          <label>Nome:</label>
                          <input type="text" className="form-control form-control-sm" onChange={(e)=>{
                          this.setState({nomeP:e.target.value}) 
                          }} value={this.state.nomeP} />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group mt-2">
                          <label>Nomeclatura:</label>
                          <input type="text" className="form-control form-control-sm" onChange={(e)=>{
                          this.setState({nmcP:e.target.value}) 
                          }} value={this.state.nmcP}  />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group mt-2">
                          <label>Data Nascimento:</label>
                          <input type="text" className="form-control form-control-sm" onChange={(e)=>{
                          this.setState({dtnacP:e.target.value}) 
                          }} value={this.state.dtnacP}  />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <div className="form-group mt-2">
                          <label>CPF / CNPJ:</label>
                          <input type="text" className="form-control form-control-sm" onChange={(e)=>{
                          this.setState({docP:e.target.value}) 
                          }} value={this.state.docP} />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group mt-2">
                          <label>Identidade:</label>
                          <input type="text" className="form-control form-control-sm" onChange={(e)=>{
                          this.setState({idtP:e.target.value}) 
                          }} value={this.state.idtP} />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group mt-2">
                          <label>Orgão Emissor:</label>
                          <input type="text" className="form-control form-control-sm" onChange={(e)=>{
                          this.setState({orgeP:e.target.value}) 
                          }} value={this.state.orgeP} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <div className="form-group mt-2">
                          <label>Estado Civil:</label>
                          <input type="text" className="form-control form-control-sm" onChange={(e)=>{
                          this.setState({estcP:e.target.value}) 
                          }} value={this.state.estcPP} />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group mt-2">
                          <label>Nacionalidade:</label>
                          <input type="text" className="form-control form-control-sm" onChange={(e)=>{
                          this.setState({ncldP:e.target.value}) 
                          }} value={this.state.ncldP}  />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group mt-2">
                          <label>Just. CPF/CNPJ:</label>
                          <input type="text" className="form-control form-control-sm" onChange={(e)=>{
                          this.setState({jdocP:e.target.value}) 
                          }} value={this.state.jdocP} />
                        </div>
                      </div>
                    </div>
                    <div className="p-2">
                      <button type="button" className="btn btn-primary" onClick={()=>{
                          var novoParticipante = {
                            nomeP:this.state.nomeP,
                            nmcP:this.state.nmcP,
                            dtnacP:this.state.dtnacP,
                            docP:this.state.docP,
                            idtP:this.state.idtP,
                            orgeP:this.state.orgeP,
                            estcP:this.state.estcP,
                            ncldP:this.state.ncldP,
                            jdocP:this.state.jdocP
                          }
                          
                          this.setState(prevState => ({
                            data: {
                              ...prevState.data,
                              participantes: [...prevState.data.participantes, novoParticipante]
                            }
                          }));
                      }}>
                       <FontAwesomeIcon color='#fff' icon={faPlus} />  Adicionar participante
                      </button>
                    </div>
                    <div className="mt-4">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">Nome</th>
                            <th scope="col">Nomeclatura</th>
                            <th scope="col">Data Nascimento</th>
                            <th scope="col">CPF / CNPJ</th>
                            <th scope="col">Identidade</th>
                            <th scope="col">Orgão Emissor</th>
                            <th scope="col">Estado Civil</th>
                            <th scope="col">Nacionalidade</th>
                            <th scope="col">Just. CPF/CNPJ</th>
                          
                          </tr>
                        </thead>
                        <tbody>
                    
                        {this.state.data.participantes.map((item,index)=><>
                            <tr>
                            <td>{item.nomeP}</td>
                            <td>{item.nmcP}</td>
                            <td>{item.dtnacP}</td>
                            <td>{item.docP}</td>
                            <td>{item.idtP}</td>
                            <td>{item.orgeP}</td>
                            <td>{item.estcP}</td>
                            <td>{item.ncldP}</td>
                            <td>{item.jdocP}</td>
                         
                          </tr>
                        </>)}
                        
                         
                        </tbody>
                      </table>
                    </div>
                    
                    </div>
                    <div class="card-footer"><button class="btn btn-danger" onClick={()=>{
                      this.props.navigate(-1)
                    }}><FontAwesomeIcon color='#fff' icon={faArrowLeft} />  Voltar </button><button type="submit" class="btn btn-primary float-right ml-2" onClick={()=>{
                        var url  = window.location.href;
                        const match = url.match(/edit\/(.*)$/);
                        const id = toast.loading("Enviando...")
                        fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/atualizar-certificado/${match[1]}`, {
                          method: 'POST',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer '+Cookies.get("auth_token"),
                          },
                          body:JSON.stringify({
                          cartorio_id:this.state.data.cartorio.id,
                          tipo_ato:this.state.data.tipo_ato,
                          tipo_certidao:this.state.data.tipo_certidao,
                          numero_livro:this.state.data.numero_livro,
                          numero_folha:this.state.data.numero_folha,
                          numero_termo:this.state.data.numero_termo,
                          cnj:this.state.data.cnj,
                          tipo_livro_cnj:this.state.data.tipo_livro_cnj,
                          observacoes:this.state.data.observacoes
                          })
                        }).then(response => {
if(response.status == 200){
  this.props.navigate(`/${window.location.pathname.split("/")[1]}/preview/${this.state.data.token}`)        
                        
}
                        })
                    }}><FontAwesomeIcon color='#fff' icon={faSave} />  Salvar </button></div>
                  </div>
               
                <ToastContainer />
              </div>
            </div>
          </div>
        </main>
    

        
<ToastContainer/>
        </>
  
    );
  }
}

export default EditarCertRj;