import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft,faPen,faLink } from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from '../../../config';
import lg1 from "../../../images/funarpen.jpg"
import lg2 from "../../../images/logotj.png"
import dcmt from "../../../images/document.png"
class PreViewSeloPR extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
    };
  }

  componentDidMount() {
    document.title = "Pré-visualização da propriedade";
    var url  = window.location.href;
    const match = url.match(/preview\/(.*)$/);
    fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/selo/preview/${match[1]}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({data,Loader:false})
})
}else if(response.status == 404){

    this.setState({error:true,Loader:false})
 }
      })
}

 


  render() {
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    if(this.state.error == true){
        return(<>
        <div class="p404">
         <div>
          <img src={dcmt}/>
         <p>Desculpe, Esse documento não foi encontrado.</p>
         <button type="submit" class="btn btn-primary float-left ml-2" onClick={()=>{
                    this.props.navigate(-1)
                 }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Voltar</button>
         </div>
       
        
        </div>
        </>)
      }
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight">Pré-visualização do selo</h2></div></header>
       
        <main>
        <div class="py-12">
   <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="col-12">
       
            <div class="card">
               <div class="card-header d-flex justify-content-between">
                  <h3>PROPRIEDADES:</h3>
                  <Link to={`/funarpen/selo/edit/${this.state.data.token}`} className='btn btn-xs btn-primary'> <FontAwesomeIcon color='#fff' icon={faPen} />  Editar</Link>
               </div>
             
               <div class="card-body">
        
             
                  <div class="form-group mt-2"><label>Tipo Envolvido: {this.state.data.tipo_env}</label>
                 </div>
             
                  <div class="form-group mt-2"><label>nome ou Razão Social: {this.state.data.name_raz}</label>
                 </div>
                  <div class="form-group mt-2"><label>CPF/CNPJ: {this.state.data.doc}</label>
                </div>
                  
               </div>
               <div class="card-footer">
               <button type="submit" class="btn btn-danger float-left ml-2" onClick={()=>{
                  this.props.navigate(-1)
               }}>      <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Voltar</button>
                  
                  
                </div>
            </div>
       
      </div>
   </div>
</div>
        </main>

        
<ToastContainer/>
        </>
  
    );
  }
}

export default PreViewSeloPR;