import React, { Component } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave,faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from '../../../config';
import lg1 from "../../../images/funarpen.jpg"
import lg2 from "../../../images/logotj.png"
import dcmt from "../../../images/document.png"
class EditarCertSp extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
    };
   
  }

  componentDidMount() {
    document.title = "Editar certificado";
    var url  = window.location.href;
    const match = url.match(/edit\/(.*)$/);
    fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/dados-certificado/${match[1]}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({data,Loader:false})
})
}else if(response.status == 404){

  this.setState({error:true,Loader:false})
}
      })
}

 


  render() {
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    if(this.state.error == true){
      return(<>
      <div class="p404">
       <div>
        <img src={dcmt}/>
       <p>Desculpe, Esse documento não foi encontrado.</p>
       <button type="submit" class="btn btn-primary float-left ml-2" onClick={()=>{
                  this.props.navigate(`/${window.location.pathname.split("/")[1]}`)
               }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Início</button>
       </div>
     
      
      </div>
      </>)
    }
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight">Editar Certidão TjSp </h2></div></header>
       
        <main>
      <div className="py-12">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="row">
            <div className="col-12">
         
                <div className="card">
                  <div className="card-header">
                    <h3>Editar certidão:</h3>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label>Nome da Serventia:</label>
                      <select className="form-control form-control-sm" onChange={(e)=>{
                         this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              certificado: {
                                ...prevState.data.certificado,
                                nomeSer: e.target.value
                              }
                            }
                          }));
                      }} value={this.state.data.certificado.nomeSer}>
                       {this.state.data.cartorios.map((item,index)=><>
                        <option value={item.id}>{item.nome}</option>
                       </>)}
                        
                       
                      </select>
                    </div>
                    <div className="form-group mt-2">
                      <label>Tipo Do Ato:</label>
                      <select className="form-control form-control-sm" onChange={(e)=>{
                         this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              certificado: {
                                ...prevState.data.certificado,
                                docEmt: e.target.value
                              }
                            }
                          }));
                      }} value={this.state.data.certificado.docEmt}>
                      
                      {[
  "CERTIDÃO",
  "CERTIDÃO DE NASCIMENTO",
  "CERTIDÃO DE CASAMENTO",
  "CERTIDÃO DE ÓBITO",
  "CERTIDÃO DE NASCIMENTO – EM BREVE RELATO",
  "CERTIDÃO DE CASAMENTO – EM BREVE RELATO",
  "CERTIDÃO DE ÓBITO – EM INTEIRO BREVE RELATO",
  "CERTIDÃO DE NASCIMENTO – EM INTEIRO TEOR",
  "CERTIDÃO DE CASAMENTO - EM INTEIRO TEOR",
  "CERTIDÃO DE ÓBITO - EM INTEIRO TEOR",
  "PROCURAÇÃO PÚBLICA",
  "ESCRITURA PÚBLICA",
  "DECLARAÇÃO",
  "PROCURAÇÃO",
  "ESCRITURA PÚBLICA DECLARATÓRIA DE MATERNIDADE",
  "ESCRITURA PÚBLICA DECLARATÓRIA DE PATERNIDADE",
  "ESCRITURA PÚBLICA DE DIVÓRCIO CONSENSUAL",
  "ESCRITURA PÚBLICA DE UNIÃO ESTÁVEL"
].map((option, index) => (
   <option key={index} value={option}>
       {option}
   </option>
))}            </select>
                    </div>
                    <div className="form-group mt-2">
                      <label>CNS:</label>
                      <input type="text" className="form-control form-control-sm" onChange={(e)=>{
                         this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              certificado: {
                                ...prevState.data.certificado,
                                cns: e.target.value
                              }
                            }
                          }));
                      }}  value={this.state.data.certificado.cns} />
                    </div>
                    <div className="form-group mt-2">
                      <label>Iniciais do Requerente:</label>
                      <input type="text" className="form-control form-control-sm" 
                      onChange={(e)=>{
                        this.setState((prevState) => ({
                           data: {
                             ...prevState.data,
                             certificado: {
                               ...prevState.data.certificado,
                               iniReq: e.target.value
                             }
                           }
                         }));
                     }} 
                      value={this.state.data.certificado.iniReq} />
                    </div>
                    <div className="form-group mt-2">
                      <label>Número CPF/CNPJ:</label>
                      <input type="text" className="form-control form-control-sm" onChange={(e)=>{
                         this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              certificado: {
                                ...prevState.data.certificado,
                                nmrDoc: e.target.value
                              }
                            }
                          }));
                      }}  value={this.state.data.certificado.nmrDoc}/>
                    </div>
                    <div className="form-group mt-2">
                      <label>Data e Hora do Ato Praticado:</label>
                      <input type="text" className="form-control form-control-sm" onChange={(e)=>{
                         this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              certificado: {
                                ...prevState.data.certificado,
                                dataGer: e.target.value
                              }
                            }
                          }));
                      }}  value={this.state.data.certificado.dataGer}/>
                    </div>
                    <div className="form-group mt-2">
                      <label>Valor do Ato:</label>
                      <input type="text" className="form-control form-control-sm" onChange={(e)=>{
                         this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              certificado: {
                                ...prevState.data.certificado,
                                valor: e.target.value
                              }
                            }
                          }));
                      }}  value={this.state.data.certificado.valor} />
                    </div>
                    <div className="form-group mt-2">
                      <label>Ato vinculador:</label>
                      <input type="text" className="form-control form-control-sm" onChange={(e)=>{
                         this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              certificado: {
                                ...prevState.data.certificado,
                                atovi: e.target.value
                              }
                            }
                          }));
                      }}  value={this.state.data.certificado.atovi}/>
                    </div>
                  </div>
                  <div className="card-footer">
                   

                  <button type="submit" class="btn btn-danger float-left ml-2" onClick={()=>{
                  this.props.navigate(-1)
               }}><FontAwesomeIcon icon={faArrowLeft} /> Voltar</button>



                    <button type="submit" className="btn btn-primary float-right ml-2" onClick={()=>{
                            var url  = window.location.href;
                            const match = url.match(/edit\/(.*)$/);
                        
                            fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/atualizar-certificado/${match[1]}`, {
                            method: 'POST',
                            credentials: 'include',
                            headers: {
                              'Content-Type': 'application/json',
                              'Authorization': 'Bearer '+Cookies.get("auth_token"),
                            },
                            body:JSON.stringify({
                            nomeSer:this.state.data.certificado.nomeSer,
                            docEmt:this.state.data.certificado.docEmt,
                            cns:this.state.data.certificado.cns,
                            iniReq:this.state.data.certificado.iniReq,
                            nmrDoc:this.state.data.certificado.nmrDoc,
                            dataGer:this.state.data.certificado.dataGer,
                            valor:this.state.data.certificado.valor,
                            atovi:this.state.data.certificado.atovi
                            })
                          }).then(response => {
                            if(response.status == 200){
                            response.json().then(data=>{

                              this.props.navigate(`/${window.location.pathname.split("/")[1]}/preview/${this.state.data.certificado.token}`)        
                            })
                                 }
                          })
                    }}>
               <FontAwesomeIcon icon={faSave} /> Salvar
                    </button>
                  </div>
                </div>
          
            </div>
          </div>
        </div>
      </div>
    </main>

        
<ToastContainer/>
        </>
  
    );
  }
}

export default EditarCertSp;