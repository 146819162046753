import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "./style.css"

class Modal extends Component {
  constructor(props) {
    super(props);
    this.elRef = document.createElement('div');
    this.modalRoot = document.getElementById('modal-root');
    this.handleOutsideClick = this.handleOutsideClick.bind(this); // Bind do método
  this.state = {
    super:undefined
  }
  }

  componentDidMount() {
console.log(this.props.Load())
    this.modalRoot.appendChild(this.elRef);
    document.addEventListener('click', this.handleOutsideClick);
  }
  componentDidUpdate(prev){
   
  }

  componentWillUnmount() {
    this.modalRoot.removeChild(this.elRef);
    document.removeEventListener('click', this.handleOutsideClick);
  }

  destroyModal = () => {
    ReactDOM.unmountComponentAtNode(this.elRef);
    this.modalRoot.innerHTML = '';
  };

  handleOutsideClick(event) {
    if (event.target.classList.contains('fyGHVY')) {
      document.removeEventListener('click', this.handleOutsideClick);
      this.destroyModal();
    }
  }

  render() {
    console.log(this.props)
    return ReactDOM.createPortal(
      <div>
     
        <div className="sc-912e3955-0 fyGHVY">
          <div role="dialog" aria-modal="true" aria-labelledby="Ver meus bilhetes" className="sc-b992e925-1 kVYZTt">
            <div className="sc-b992e925-4 eCuIZO">
              <h1 className="sc-b992e925-2 iEZsiF">{this.props.titulo}</h1>
              <button type="button" className="sc-b992e925-3 hoeLrA" onClick={() => this.destroyModal()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x ">
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </button>
            </div>
            <div className="sc-b992e925-5 gmRYUp">
              {this.props.html != undefined ? <>{this.props.html}</> : <>
                {this.props.titulo === 'teste' && <p>Conteúdo personalizado para o título 'teste'</p>}
              </>}
              {this.props.btn != undefined && <>{this.props.btn}</> }
            </div>
            {this.props.btn2 != undefined && <>{this.props.btn2}</> }
          </div>
        </div>
      </div>,
      this.elRef
    );
  }
}


Modal.close = () => {
  const modalRoot = document.getElementById('modal-root');
  modalRoot.innerHTML = '';
};
Modal.Load = (a) => {
  window.minha = a;
return a;
}
Modal.create = ({ titulo, html, btn, btn2, teste }) => {

  const modalRoot = document.getElementById('modal-root');
  const elRef = document.createElement('div');
  modalRoot.appendChild(elRef);

  ReactDOM.render(
    <>
   
      <div style={{ opacity: 1 }} className="sc-912e3955-1 guWPuq" onClick={() => {}}></div>
      <Modal titulo={titulo} html={html} teste={teste} btn={btn} btn2={btn2} Load={Modal.Load}/>
    </>,
    elRef
  );

  return {
    destroy: () => {
      modalRoot.removeChild(elRef);
    },
  };
};

export default Modal;
