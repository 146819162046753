import React, { Component } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave,faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from '../../../config';
import lg1 from "../../../images/funarpen.jpg"
import lg2 from "../../../images/logotj.png"
import dcmt from "../../../images/document.png"
class EditarCertGo extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
    };
   
  }

  componentDidMount() {
    document.title = "Editar certificado";
    var url  = window.location.href;
    const match = url.match(/edit\/(.*)$/);
    fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/dados-certificado/${match[1]}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({data,Loader:false})
})
}else if(response.status == 404){

  this.setState({error:true,Loader:false})
}
      })
}

 


  render() {
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }

    if(this.state.error == true){
      return(<>
      <div class="p404">
       <div>
        <img src={dcmt}/>
       <p>Desculpe, Esse documento não foi encontrado.</p>
       <button type="submit" class="btn btn-primary float-left ml-2" onClick={()=>{
                  this.props.navigate(`/${window.location.pathname.split("/")[1]}`)
               }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Início</button>
       </div>
     
      
      </div>
      </>)
    }
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight">Editar Certidão TjGo </h2></div></header>
       
        <main>
      <div className="py-12">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="row">
            <div className="col-12">
         
                <div className="card">
                  <div className="card-header">
                    <h3>Editar certidão:</h3>
                  </div>
                  <div className="card-body">
                  <div className="form-group mt-2">
                      <label>Civil do Ato :</label>
                  <input type="text" className="form-control form-control-sm" 
                   value={this.state.data.certificado.civilato} name="civilato"
                   onChange={(e)=>{
                    this.setState((prevState) => ({
                      data: {
                        ...prevState.data,
                        certificado: {
                          ...prevState.data.certificado,
                          civilato: e.target.value
                        }
                      }
                    }));
                   }}
                  />
                    </div>
                    <div className="form-group">
                      <label>Nome da Serventia:</label>
                      <select className="form-control form-control-sm" onChange={(e)=>{
                         this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              certificado: {
                                ...prevState.data.certificado,
                                nomeSer: e.target.value
                              }
                            }
                          }));
                      }} value={this.state.data.certificado.nomeSer}>
                       {this.state.data.cartorios.map((item,index)=><>
                        <option value={item.id}>{item.nome}</option>
                       </>)}
                        
                       
                      </select>
                    </div>
                    <div className="form-group mt-2">
                      <label>Documento emitido:</label>
                  <input type="text" className="form-control form-control-sm" 
                   value={this.state.data.certificado.docEmt}
                  disabled="true"/>
                    </div>
                    <div className="form-group mt-2">
                      <label>Nome do serventuário que praticou o ato:</label>
                      <input type="text" className="form-control form-control-sm" onChange={(e)=>{
                         this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              certificado: {
                                ...prevState.data.certificado,
                                resp: e.target.value
                              }
                            }
                          }));
                      }}  value={this.state.data.certificado.resp} />
                    </div>
                    <div className="form-group mt-2">
                      <label>Emolumento:</label>
                      <input type="text" className="form-control form-control-sm" 
                     disabled="true"
                     onChange={(e)=>{
                        this.setState((prevState) => ({
                           data: {
                             ...prevState.data,
                             certificado: {
                               ...prevState.data.certificado,
                               emonumento: e.target.value
                             }
                           }
                         }));
                     }} 
                      value={this.state.data.certificado.emonumento} />
                    </div>
                    <div className="form-group mt-2">
                      <label>Taxa Judiciário:</label>
                      <input type="text" className="form-control form-control-sm" 
                             disabled="true"
                      onChange={(e)=>{
                         this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              certificado: {
                                ...prevState.data.certificado,
                                taxa_j: e.target.value
                              }
                            }
                          }));
                      }}  value={this.state.data.certificado.taxa_j}/>
                    </div>
                    <div className="form-group mt-2">
                      <label>Fundos Estaduais:</label>
                      <input type="text" className="form-control form-control-sm"        disabled="true"
                      onChange={(e)=>{
                         this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              certificado: {
                                ...prevState.data.certificado,
                                fundos_e: e.target.value
                              }
                            }
                          }));
                      }}  value={this.state.data.certificado.fundos_e}/>
                    </div>
                    <div className="form-group mt-2">
                      <label>Endereço:</label>
                      <input type="text" className="form-control form-control-sm"
                             disabled="true"
                              onChange={(e)=>{
                         this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              certificado: {
                                ...prevState.data.certificado,
                                edrc: e.target.value
                              }
                            }
                          }));
                      }}  value={this.state.data.certificado.edrc} />
                    </div>
                    <div className="form-group mt-2">
                      <label>Cidade:</label>
                      <input type="text" className="form-control form-control-sm" 
                             disabled="true"
                             onChange={(e)=>{
                         this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              certificado: {
                                ...prevState.data.certificado,
                                cidade: e.target.value
                              }
                            }
                          }));
                      }}  value={this.state.data.certificado.cidade}/>
                    </div>
                    <div className="form-group mt-2">
                      <label>Telefone:</label>
                      <input type="text" className="form-control form-control-sm" 
                             disabled="true"
                      onChange={(e)=>{
                         this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              certificado: {
                                ...prevState.data.certificado,
                                telefone: e.target.value
                              }
                            }
                          }));
                      }}  value={this.state.data.certificado.telefone}/>
                    </div>
                  </div>
                  <div className="card-footer">
                   

                  <button type="submit" class="btn btn-danger float-left ml-2" onClick={()=>{
                  this.props.navigate(-1)
               }}><FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Voltar</button>



                    <button type="submit" className="btn btn-primary float-right ml-2" onClick={()=>{
                            var url  = window.location.href;
                            const match = url.match(/edit\/(.*)$/);
                        
                            fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/atualizar-certificado/${match[1]}`, {
                            method: 'POST',
                            credentials: 'include',
                            headers: {
                              'Content-Type': 'application/json',
                              'Authorization': 'Bearer '+Cookies.get("auth_token"),
                            },
                            body:JSON.stringify({
                            civilato:this.state.data.certificado.civilato,
                            nomeSer:this.state.data.certificado.nomeSer,
                            docEmt:this.state.data.certificado.docEmt,
                            emonumento:this.state.data.certificado.emonumento,
                            resp:this.state.data.certificado.resp,
                            taxa_j:this.state.data.certificado.taxa_j,
                            fundos_e:this.state.data.certificado.fundos_e,
                            edrc:this.state.data.certificado.edrc,
                            cidade:this.state.data.certificado.cidade,
                            telefone:this.state.data.certificado.telefone
                            })
                          }).then(response => {
                            if(response.status == 200){
                            response.json().then(data=>{

                              this.props.navigate(`/${window.location.pathname.split("/")[1]}/preview/${this.state.data.certificado.token}`)        
                            })
                                 }
                          })
                    }}>
                     <FontAwesomeIcon color='#fff' icon={faSave} /> Salvar
                    </button>
                  </div>
                </div>
          
            </div>
          </div>
        </div>
      </div>
    </main>

        
<ToastContainer/>
        </>
  
    );
  }
}

export default EditarCertGo;