import React, { Component } from 'react';
import logo from "../../../src/images/logo.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, NavLink } from 'react-router-dom';
import { apiUrl } from '../../config';
import { Drop, Dropdown, Item } from '../Dropdown';
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
    enableMenu:false
    };
  }



  getClassNames = ({ isActive }) => {
  return isActive ? "inline-flex items-center px-1 pt-1 border-b-2 border-indigo-400 text-sm font-medium leading-5 text-gray-900 focus:outline-none focus:border-indigo-700 " :
   "inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
  };




  render() {

    return (
     
      <>
      <nav class="bg-white border-b border-gray-100">

   <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between h-16">
         <div class="flex">
      
            <div class="shrink-0 flex items-center">
               <Link to="/">
               <img src={logo}/>
               </Link>
            </div>


           


            <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
            <NavLink exact className={this.getClassNames} to="/"> inicio </NavLink></div>
           
            <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex"><NavLink  className={this.getClassNames} to="/funarpen"> TJPR </NavLink></div>
            <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex"><NavLink className={this.getClassNames} to="/tjsp"> TJSP </NavLink></div>
            <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex"><NavLink className={this.getClassNames} to="/tjrj"> TJRJ </NavLink></div>
            <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex"><NavLink className={this.getClassNames} to="/tjmg"> TJMG </NavLink></div>
            <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex"><NavLink className={this.getClassNames}  to="/tjgo"> TJGO</NavLink></div>
            <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex"><NavLink className={this.getClassNames}  to="/tjrs"> TJRS</NavLink></div>
            <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex"><NavLink className={this.getClassNames}  to="/tjsc"> TJSC</NavLink></div>
            <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex"><NavLink className={this.getClassNames}  to="/tjes"> TJES</NavLink></div>
            <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex"><a href='https://arvore.webcidadania.com/' className="inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300">Genealogia</a></div>
            <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex"><NavLink className={this.getClassNames} to="/apostil"> Apostil </NavLink></div>
           </div>
         <div class="hidden sm:flex sm:items-center sm:ml-6">
            <div class="ml-3 relative">
        
            </div>
        
            <div class="ml-3 relative">
               <div class="relative">
                  
                
                 
              
            
       
     
                
               </div>
            </div>
         </div>
       
         <div class="-mr-2 flex items-center sm:hidden">
            <button class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition" onClick={()=>{
               this.setState({enableMenu:!this.state.enableMenu})
            }}>
               <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                {this.state.enableMenu == false ? <>
                  <path class="inline-flex" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
                 
                </> : <>
                <path class="inline-flex" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            
                </>}
                 
                     </svg>
            </button>
         </div>
      </div>
      {this.state.enableMenu && <>
         <div class="block sm:hidden">
  
   <div class="pt-4 pb-1 border-t border-gray-200">
      <div class="flex items-center px-4">

         
      </div>
      <div class="mt-3 space-y-1">
        
       
            <div><button type="submit" class="block w-full px-4 py-2 text-sm leading-5 text-gray-700 text-left hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition" onClick={()=>{
                              function deleteCookie(cookieName) {
                                 document.cookie = cookieName + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
                             }
                             
                             // Deletar o cookie específico
                             deleteCookie('auth_token');
                             
                             // Redirecionar para a página inicial
                             window.location = window.location.href = '/';
                           }}> Sair </button></div>
         
       
      </div>
   </div>
</div>

      </>}
   </div>

   <div class="hidden sm:hidden">
      <div class="pt-2 pb-3 space-y-1">
         <div><Link class="block pl-3 pr-4 py-2 border-l-4 border-indigo-400 text-base font-medium text-indigo-700 bg-indigo-50 focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition" to="/"> Dashboard </Link></div>
      </div>

      <div class="pt-4 pb-1 border-t border-gray-200">
         <div class="flex items-center px-4">
        
            <div>
               <div class="font-medium text-base text-gray-800">Administrador</div>
               <div class="font-medium text-sm text-gray-500">admin@admin.com.br</div>
            </div>
         </div>
         <div class="mt-3 space-y-1">
           
          
               <div>
               <button type="submit" class="block w-full px-4 py-2 text-sm leading-5 text-gray-700 text-left hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition" onClick={()=>{
                              function deleteCookie(cookieName) {
                                 document.cookie = cookieName + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
                             }
                             
                             // Deletar o cookie específico
                             deleteCookie('auth_token');
                             
                             // Redirecionar para a página inicial
                             window.location = window.location.href = '/';
                           }}> Sair </button>
               </div>
     
           
         </div>
      </div>
   </div>
</nav>

      </>
  
    );
  }
}

export default Header;