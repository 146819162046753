import React, { Component } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft,faSave } from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from '../../../config';
import lg1 from "../../../images/funarpen.jpg"
import lg2 from "../../../images/logotj.png"
import dcmt from "../../../images/document.png"
class EditarSeloFun extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
  data:[],
  prop:[]
    };
   
  }

  componentDidMount() {
    document.title = "Editar propriedade";
    var url  = window.location.href;
    const match = url.match(/edit\/(.*)$/);
    fetch(`${apiUrl}/funarpen/dados-selo/${match[1]}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
    response.json().then(data=>{
        this.setState({prop:data.prop,Loader:false})
    })
}else if(response.status == 404){

  this.setState({error:true,Loader:false})
}
      })
}

 
handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
     prop: {
        ...prevState.prop,
        [name]: value
      }
    }));
  };


  render() {
    var d = this.state.prop;
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    if(this.state.error == true){
      return(<>
      <div class="p404">
       <div>
        <img src={dcmt}/>
       <p>Desculpe, Esse documento não foi encontrado.</p>
       <button type="submit" class="btn btn-primary float-left ml-2" onClick={()=>{
                  this.props.navigate(`/${window.location.pathname.split("/")[1]}`)
               }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Início</button>
       </div>
     
      
      </div>
      </>)
    }
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight">Editar propriedade </h2></div></header>
        <div class="py-12">
   <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="col-12">
       
            <div class="card">
               <div class="card-header">
                  <h3>PROPRIEDADES:</h3>
               </div>
               <div class="card-body">
        
           
                  <div class="form-group mt-2"><label>Tipo Envolvido:</label>
 <input type="text" class="form-control form-control-sm" name='tipo_env' value={d.tipo_env}
            onChange={this.handleChange}/></div>
             
                  <div class="form-group mt-2"><label>nome ou Razão Social:</label>
                  <input type="text" class="form-control form-control-sm" name='name_raz' value={d.name_raz}
            onChange={this.handleChange}/></div>
                  <div class="form-group mt-2"><label>CPF/CNPJ:</label>
                  <input type="text" class="form-control form-control-sm"  name='doc' value={d.doc}
            onChange={this.handleChange}/></div>
                  
               </div>
               <div class="card-footer">
               <button type="submit" class="btn btn-danger float-left ml-2" onClick={()=>{
                  this.props.navigate(-1)
               }}>      <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Voltar</button>
                  
                  
                  <button type="submit" class="btn btn-primary float-right ml-2" onClick={()=>{
     
              
                const id = toast.loading("Enviando...")
                fetch(`${apiUrl}/funarpen/atualizar-prop/${d.token}`, {
                  method: 'POST',
                  credentials: 'include',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+Cookies.get("auth_token"),
                  },
                  body:JSON.stringify({prop:d})
                }).then(response => {
if(response.status == 200){
   toast.update(id, { render:"Certificadi criado com sucesso!", type: "success", isLoading: false,autoClose: 3000});
   
   response.json().then(data=>{
this.props.navigate(`/${window.location.pathname.split("/")[1]}/selo/preview/${data.token}`)
   })
}
})

               }}>      <FontAwesomeIcon color='#fff' icon={faSave} /> Salvar </button></div>
            </div>
       
      </div>
   </div>
</div>
        <main>
    
    </main>

        
<ToastContainer/>
        </>
  
    );
  }
}

export default EditarSeloFun;