import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import  "./p.css";
import logo from "../../../images/logoTjsp.png"
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faEye,faUniversity,faPen,faArrowLeft,faLink,faHome } from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from '../../../config';
import lg1 from "../../../images/funarpen.jpg"
import lg2 from "../../../images/logotj.png"
import dcmt from "../../../images/document.png"
class PreViewCertSp extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
     error:false
    };
  }

  componentDidMount() {
    document.title = "Pré-visualização do certificado";
    var url  = window.location.href;
    const match = url.match(/preview\/(.*)$/);
    fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/preview/${match[1]}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({data,Loader:false})
})
}else if(response.status == 404){

   this.setState({error:true,Loader:false})
}
      })
}

 


  render() {
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    if(this.state.error == true){
      return(<>
      <div class="p404">
       <div>
        <img src={dcmt}/>
       <p>Desculpe, Esse documento não foi encontrado.</p>
       <button type="submit" class="btn btn-primary float-left ml-2" onClick={()=>{
                  this.props.navigate(-1)
               }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Voltar</button>
       </div>
     
      
      </div>
      </>)
    }

    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight">Pré-visualização do certificado</h2></div></header>
       
        <main>
            <div className="py-12">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                            <div class="card-header d-flex justify-content-between">
                                    <h3>Qrcode:</h3>
                                    <a href={this.state.data.link} target="_blank">
   <button class="btn btn-xs btn-primary">
  <FontAwesomeIcon color='#fff' icon={faLink} /> Acessar
  </button>
   </a>  
                                </div>
                                
                                <div className="card-body">
                                    <div className="flex items-center">
                                        <img src={this.state.data.QrCode} style={{width: "200px", height: "200px"}} width="200" height="200"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="card">
                           
                            <div class="card-header d-flex justify-content-between">
                            <h3>Dados da certidão:</h3>
   <Link to={`/${window.location.pathname.split("/")[1]}/edit/${this.state.data.hash}`}>
   <button class="btn btn-xs btn-primary">
  <FontAwesomeIcon color='#fff' icon={faPen} /> Editar
  </button>
   </Link>                         

</div>
<div class="card-body">
   <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <header class="tj-headerbar">
         <div class="container">
            <div class="tj-socialLinks float-right"><a target="_blank" href="http://twitter.com/tjspoficial"><i class="fa fa-twitter"></i></a><a target="_blank" href="http://www.facebook.com/TJSPoficial"><i class="fa fa-facebook-square"></i></a><a target="_blank" href="http://www.youtube.com/TJSPoficial"><i class="fa fa-youtube-play"></i></a><a target="_blank" href="http://www.flickr.com/photos/tjsp_oficial/sets/"><i class="fa fa-flickr"></i></a><a target="_blank" href="http://www.foursquare.com/tjspoficial"><i class="fa fa-foursquare"></i></a><a target="_blank" href="http://www.instagram.com/tjspoficial"><i class="fa fa-instagram"></i></a></div>
         </div>
      </header>
      <header class="tj-header">
         <div class="container">
            <div class="row">
               <div class="col-xs-12 col-md-8 tj-logo-reader">
                  <a href="https://selodigital.tjsp.jus.br">
                     <img src={logo}/>
                     <div>
                        <label>Tribunal de Justiça</label>
                        <text>Estado de São Paulo</text>
                     </div>
                     <span class="hidden-xs tj-slogan"><strong> A Justiça próxima <br/> do cidadão </strong></span>
                  </a>
               </div>
            </div>
         </div>
      </header>
      <div class="container body-content">
         <section class="row">
            <div class="col-sm-12">
               <h2 class="title"><span>SELO DIGITAL</span></h2>
            </div>
            <div class="col-sm-12">
               <h3 class=""> Resultado da Consulta do Selo Digital </h3>
               <p class=""> Nesta consulta você verifica a procedência do selo eletrônico, bem como as informações referentes aos dados do Ato praticado pela Serventia indicada. </p>
            </div>
            <div class="col-md-8 col-sm-12 tj-mtop-30px">
               <div class="panel panel-default">
                  <div class="panel-heading"> DADOS DO SELO DIGITAL </div>
                  <div class="panel-body">
                     <p><b class="tj-dsp-block"> Código do Selo Digital </b></p>
                     <div class="tj-dsp-block title tj-word-wrap">{this.state.data.hash}</div>
                     <p></p>
                     <p><b class="tj-dsp-block">Nome da Serventia </b></p>
                     <div class="tj-dsp-block title">{this.state.data.nomeSer}</div>
                     <p></p>
                     <p><b class="tj-dsp-block">Tipo de Ato</b></p>
                     <div class="tj-dsp-block title">{this.state.data.docEmt}</div>
                     <p></p>
                     <p><b class="tj-dsp-block">Iniciais do Requerente</b></p>
                     <div class="tj-dsp-block title">{this.state.data.iniReq}</div>
                     <p></p>
                     <p><b class="tj-dsp-block">Número CPF/CNPJ</b></p>
                     <div class="tj-dsp-block title">{this.state.data.nmrDoc}</div>
                     <p></p>
                     <p><b class="tj-dsp-block">Data e Hora do Ato Praticado</b></p>
                     <div class="tj-dsp-block title">{this.state.data.dataGer}</div>
                     <p></p>
                     <p><b class="tj-dsp-block">Valor do Ato</b></p>
                     <div class="tj-dsp-block title">R$ {this.state.data.valor}</div>
                     <p></p>
                     <p><b class="tj-dsp-block">Ato Vinculador</b></p>
                     <div class="tj-dsp-block title tj-word-wrap">{this.state.data.atovi}</div>
                     <p></p>
                  </div>
               </div>
               <div class="row tj-mtop-15px">
                  <div class="form-group">
          
                  </div>
               </div>
            </div>
         </section>
         <hr/>
      </div>
      <footer class="tj-footerbar">
         <div class="container">
            <p class="text-center tj-mtop-30px"> Tribunal de Justiça de São Paulo </p>
            <p class="text-center"> Desenvolvido pela Secretaria de Tecnologia da Informação do TJSP </p>
         </div>
      </footer>
   </div>
   
</div>
<div class="card-footer">
               <button type="submit" class="btn btn-danger float-left ml-2" onClick={()=>{
                  this.props.navigate(-1)
               }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Voltar</button>
               </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </main>

        
<ToastContainer/>
        </>
  
    );
  }
}

export default PreViewCertSp;