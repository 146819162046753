import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { apiUrl } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faEye,faUniversity,faPen,faPlus, faMagnifyingGlass, faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../Comps/Modal';
import InputMask from 'react-input-mask';
class TjRj extends Component {
  constructor(props) {
    super(props);
    this.state = {
    Loader:true,
    currentPage: 1,
    itemsPerPage: 5,
    totalPages: 0,

    currentPage2: 1,
    itemsPerPage2: 5,
    totalPages2: 0
    };
    this.iptNome = React.createRef();
    this.iptResp = React.createRef();
    this.iptEdrc = React.createRef();
    this.iptCidade = React.createRef();
    this.iptTelefone = React.createRef();
    this.iptCns = React.createRef();
    this.iptCds = React.createRef();
    this.iptCma = React.createRef();
    this.iptCep = React.createRef();
    this.iptBairro = React.createRef();
    this.iptMcp = React.createRef();
  }

  componentDidMount() {
    document.title = "Rio de janeiro - Consulta";
    fetch(`${apiUrl}/list`+window.location.pathname, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({data,
      totalPages:data.totalPage,
      totalPages2:data.totalPage2,
      Loader:false})
})
}
      })


}

 

 
fetchItems(page) {
   fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/certificados/${page}/${this.state.itemsPerPage}`)
       .then(response => response.json())
       .then(resp => {
         this.setState((prevState) => ({
           data: {
             ...prevState.data,
             certs:resp,
           },
         }));
       })
       .catch(error => {
           console.error('Error fetching items:', error);
       });
}
 
fetchItems2(page) {
   fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}nt/certificados/${page}/${this.state.itemsPerPage}`)
       .then(response => response.json())
       .then(resp => {
         this.setState((prevState) => ({
           data: {
             ...prevState.data,
             certs2:resp,
           },
         }));
       })
       .catch(error => {
           console.error('Error fetching items:', error);
       });
 }
 
 
 handlePageChange = (page) => {
   this.setState({ currentPage: page }, () => {
       this.fetchItems(page);
   });
 }

 handlePageChange2 = (page) => {

   this.setState({ currentPage2: page }, () => {
       this.fetchItems2(page);
   });
 }


  render() {
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }


    const { totalPages,currentPage,totalPages2,currentPage2 } = this.state;
    const pageNumbers = [];
    const pageNumbers2 = [];

    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    for (let i = 1; i <= totalPages2; i++) {
      pageNumbers2.push(i);
  }


    return (
     
      <>
      <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight"> TjRj </h2></div></header>
      <main>
 
   

      <div class="py-12">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
         <div class="col-12">
            <div class="card">
               <div class="card-header">
            <button class="btn btn-xs btn-primary-2" style={{background:"#dc3545",color:"#fff"}} onClick={()=>{
                Modal.create({
                    titulo:"Adicionar Cartório",
                    html:<>
<div class="card-body">

<div class="form-group mt-2"><label>Código da serventia:</label>
   <input type="text" class="form-control form-control-sm" onChange={(e)=>{
    this.setState({Cds:e.target.value})
   }}/></div>



   <div class="form-group mt-2"><label>Nome do Ofício:</label>
   <input type="text" class="form-control form-control-sm" onChange={(e)=>{
    this.setState({nomeOfc:e.target.value})
   }}/></div>

<div class="form-group mt-2"><label>Cep:</label>
   <InputMask mask='99.999-999' type="text" onBlur={(e)=>{
   
var cep = e.target.value.replace(/\D/g, "");

if(cep.length > 0){
  fetch(`${apiUrl}/cep/${cep}`).then((response) => {
   
    if(response.status == 404){
toast.error("Cep inválido!")
      return false;
    }
   
    if(response.status == 200){
      response.json().then(data=>{
        this.iptEdrc.current.value = data.logradouro	
 this.iptBairro.current.value = data.bairro
 this.iptMcp.current.value = data.localidade
this.setState({
  cep,
  Edrc:data.logradouro,
  bairro:data.bairro,
  municipio:data.localidade
})
      })
    }
   })
}
   
   




   }} class="form-control form-control-sm" onChange={(e)=>{
    this.setState({cep:e.target.value})
   }}/>


</div>

   <div class="form-group mt-2"><label>Endereço:</label>
   <input type="text" class="form-control form-control-sm" ref={this.iptEdrc} value={this.iptEdrc.value} onChange={(e)=>{
    this.setState({Edrc:e.target.value})
   }}/>


<div class="form-group mt-2"><label>Bairro:</label>
   <input type="text" class="form-control form-control-sm" ref={this.iptBairro} value={this.iptBairro.value} onChange={(e)=>{
    this.setState({Edrc:e.target.value})
   }}/>
</div>
   </div>
   <div class="form-group mt-2"><label>Município:</label>
   <input type="text" class="form-control form-control-sm"
   ref={this.iptMcp}
   value={this.iptMcp.value}
   onChange={(e)=>{
    this.setState({municipio:e.target.value})
   }}
   /></div>
   <div class="form-group mt-2">
    <label>Telefone:</label>
 

   <InputMask mask='(99) 9999-9999' type="text" class="form-control form-control-sm" onChange={(e)=>{
    this.setState({Telefone:e.target.value})
   }}/>


<div class="form-group mt-2"><label>Comarca:</label>
   <input type="text" class="form-control form-control-sm" onChange={(e)=>{
    this.setState({Cma:e.target.value})
   }}/>
   </div>

<div class="form-group mt-2"><label>Cns:</label>
   <input type="text" class="form-control form-control-sm" onChange={(e)=>{
    this.setState({Cns:e.target.value})
   }}/>
   </div>
   </div>




</div>

<div class="card-footer">
<button type="submit" style={{background:"#0d6efd"}} class="btn btn-primary float-right ml-2" onClick={()=>{

  
    const id = toast.loading("Enviando...")
 
    fetch(`${apiUrl}${window.location.pathname}/adicionar-cartorio/`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        },
        body:JSON.stringify({
        Cds:this.state.Cds,
        nomeOfc:this.state.nomeOfc,
        cep:this.state.cep,
        Edrc:this.state.Edrc,
        Bairro:this.state.bairro,
        Municipio:this.state.municipio,
        Telefone:this.state.Telefone,
        Cma:this.state.Cma,
        Cns:this.state.Cns,
        })
      }).then(response => {
      if(response.status == 200){
       
        response.json().then(data=>{
            toast.update(id, { render:"cartório adicionado com sucesso!", type: "success", isLoading: false,autoClose: 3000});
            Modal.close()
            const newItem = {
              id:data.id,
              Cds:this.state.Cds,
              nome:this.state.nomeOfc,
              cep:this.state.cep,
              edrc:this.state.Edrc,
              bairro:this.state.bairro,
              municipio:this.state.municipio,
              cidade:this.state.Cidade,
              telefone:this.state.Telefone,
              Cma:this.state.Cma,
              Cns:this.state.Cns
            }
console.log(this.state)
            this.setState((prevState) => ({
                data: {
                  ...prevState.data,
                  cartorio: [...prevState.data.cartorio, newItem]
                }
              }));
       })
       



      }
      })
}}><i class="fas fa-download"></i> Salvar </button>
</div>
                    </>
                })
            }}>  <FontAwesomeIcon icon={faUniversity} /> Adicionar cartório </button></div>
               <div class="card-body">
                  <div>
                 
                  </div>
                  
                       
                       {this.state.data.cartorio.length > 0 ? <>
                     <table class="table table-sm table-bordered">
                     <thead>
                        <tr>
                           <th scope="col">#</th>
                           <th scope="col">Nome</th>
                         
                           <th scope="col">Endereço</th>
                           <th></th>
                        </tr>
                     </thead>
                     <tbody>
                       {this.state.data.cartorio.map((item,index)=><>
                        <tr>
                           <td>{index + 1}</td>
                           <td>{item.nome}</td>
                    
                           <td>{item.edrc}</td>
                           <td>
                              <div class="btn-group btn-group-sm">
                            <button class="btn btn-info" onClick={()=>{
                                console.log(item)
                                Modal.create({
                                    titulo:"Editar cartório",
                                    html:<>
                <div class="card-body">

                <div class="form-group mt-2"><label>Código da serventia:</label>
                   <input type="text" class="form-control form-control-sm" ref={this.iptCds} />
                </div>

                   <div class="form-group mt-2"><label>Nome do Ofício:</label>
                   <input type="text" class="form-control form-control-sm" ref={this.iptNome} />
                   </div>
                  
                   <div class="form-group mt-2"><label>Cep:</label>
                   <input type="text" class="form-control form-control-sm" ref={this.iptCep}/>
                   </div>
                   
                   <div class="form-group mt-2"><label>Endereço:</label>
                   <input type="text" class="form-control form-control-sm" ref={this.iptEdrc}/>
                   </div>

                   <div class="form-group mt-2"><label>Bairro:</label>
                   <input type="text" class="form-control form-control-sm" ref={this.iptBairro}/>
                   </div>

                   <div class="form-group mt-2"><label>Município::</label>
                   <input type="text" class="form-control form-control-sm" ref={this.iptMcp}/></div>
                   <div class="form-group mt-2"><label>Telefone:</label>
                   <input  type="text" class="form-control form-control-sm" ref={this.iptTelefone}/>
                   </div>
                <div class="form-group mt-2"><label>Comarca:</label>
                   <input type="text" class="form-control form-control-sm" ref={this.iptCma} />
                </div>
                   <div class="form-group mt-2"><label>Cns:</label>
                   <input type="text" class="form-control form-control-sm"  ref={this.iptCns}/>
                   </div>
                </div>
                
                <div class="card-footer">
                
                <button type="submit" style={{background:"#0d6efd"}} class="btn btn-primary float-right ml-2" onClick={()=>{
                    const id = toast.loading("Enviando...")
                   fetch(`${apiUrl}${window.location.pathname}/atualizar-cartorio/${item.id}`, {
                        method: 'PUT',
                        credentials: 'include',
                        headers: {
                          'Content-Type': 'application/json',
                          'Authorization': 'Bearer '+Cookies.get("auth_token"),
                        },
                        body:JSON.stringify({
                        Cds:this.iptCds.current.value,
                        nomeOfc:this.iptNome.current.value,
                        Cep:this.iptCep.current.value,
                        Edrc:this.iptEdrc.current.value,
                        Bairro:this.iptBairro.current.value,
                        Municipio:this.iptMcp.current.value,
                        Telefone:this.iptTelefone.current.value,
                        Cma:this.iptCma.current.value,
                        Cns:this.iptCns.current.value
                        })
                      }).then(response => {
                      if(response.status == 200){
                       
                       response.json().then(data=>{

                        toast.update(id, { render:"Cartório atualizado com sucesso!", type: "success", isLoading: false,autoClose: 3000});
                      
                        this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              cartorio: prevState.data.cartorio.map(i =>
                                i.id === item.id ? { ...i,
                                Cds: this.iptCds.current.value,
                                nome: this.iptNome.current.value,
                                cep:this.iptCep.current.value,
                                edrc:this.iptEdrc.current.value,
                                bairro:this.iptBairro.current.value,
                                municipio:this.iptMcp.current.value,
                                telefone:this.iptTelefone.current.value,
                                Cma:this.iptCma.current.value,
                                Cns:this.iptCns.current.value
                            } : i
                              )
                            }
                          }));
                        Modal.close()
                       })
                       
                
                
                
                      }
                      })
                }}><i class="fas fa-download"></i> Atualizar </button>
                </div>
                                    </>
                                 })
                            
                                 this.iptCds.current.value = item.Cds;
                                 this.iptNome.current.value = item.nome;
                                 this.iptCep.current.value = `${item.cep}`
                                 this.iptEdrc.current.value = item.edrc;
                                 this.iptBairro.current.value = item.bairro;
                            
                                 this.iptMcp.current.value = `${item.municipio}`
                                 this.iptTelefone.current.value = item.telefone;
                                 this.iptCma.current.value = item.Cma;
                                 this.iptCns.current.value = item.Cns
                            }}>
                            <FontAwesomeIcon color='#fff' icon={faPen} />
                            </button>
                            <button  class="btn btn-danger" onClick={()=>{
                            Modal.create({
                                titulo:"Tem certeza?",
                                html:<><p>Após a remoção, não será possível recuperar o dado.</p>
                                <div class="card-footer"><button type="submit" style={{background:"#dc3545"}} class="btn btn-danger float-right ml-2" onClick={()=>{
                                 const id = toast.loading("Carregando...")
                                 fetch(`${apiUrl}${window.location.pathname}/remover-cartorio/${item.id}`, {
                                    method: 'DELETE',
                                    credentials: 'include',
                                    headers: {
                                      'Content-Type': 'application/json',
                                      'Authorization': 'Bearer '+Cookies.get("auth_token"),
                                    }
                                  }).then(response => {
                                  if(response.status == 200){
                                    toast.update(id, { render:"Cartório removido com sucesso!", type: "success", isLoading: false,autoClose: 3000});

                                    this.setState((prevState) => ({
                                        data: {
                                          ...prevState.data,
                                          cartorio: prevState.data.cartorio.filter(i => i.id !== item.id)
                                        }
                                      }));
                                  Modal.close()

                                  }
                                  })



                                }}><i class="fas fa-download"></i> Remover </button></div>
                                </>
                            })
                            }}>
                            <FontAwesomeIcon icon={faTrash} />
                            </button>
                            </div>
                           </td>
                        </tr>
                       </>)}
                       </tbody>
                  </table>
                       </> : <>
                       Não há dados cadastrados!
                       </>}
                        
                        
                      


                
               </div>
            </div>
         </div>
      </div>
   </div>


  

   <div class="py-5">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
         <div class="col-12">
            <div class="card">
<div class="card-header d-flex">
 
  <div className='area-buscas'>

  <Link class="btn btn-xs btn-primary-2" style={{background:"#dc3545",color:"#fff"}} to={`${window.location.pathname}/create-natalina`}>
  <FontAwesomeIcon icon={faPlus} /> Adicionar Certidão Natalina
  </Link>

<input type="text" placeholder='Pesquisar:' style={{marginLeft:10}}/>
<button>
  <FontAwesomeIcon color='#fff' icon={faMagnifyingGlass}/>
</button>
</div>
</div>

               <div class="card-body">
                  <div class="row">
                     <div class="col-12">
                        <p>Lista de certidão</p>
                        <div>
                   
                        </div>
                        {this.state.data.certs2.length > 0 ? <>
                           <table class="table table-sm table-bordered">
                           <thead>
                              <tr>
                                 <th scope="col">#</th>
                                 <th scope="col">Nome</th>
                                 <th scope="col">Tipo de documento</th>
                              </tr>
                           </thead>
                           <tbody>

                           {this.state.data.certs2.map((item,index)=><>
                           <tr>
                                 <td>{index + 1}</td>
<td>{item.participante.nomeP}</td>
                                 <td>{item.tipo_ato}</td>
                                 <td>
                                    <div class="btn-group btn-group-sm">
                                    <Link class="btn btn-success" to={`/${window.location.pathname.split("/")[1]}nt/preview/${item.token}`}><FontAwesomeIcon color='#fff' icon={faEye} /></Link>
                                    <Link class="btn btn-info" to={`/${window.location.pathname.split("/")[1]}/edit/${item.token}`}><FontAwesomeIcon color='#fff' icon={faPen} /></Link>
                                    <button class="btn btn-danger" onClick={()=>{
                                         Modal.create({
                                          titulo:"Tem certeza?",
                                          html:<><p>Após a remoção, não será possível recuperar o dado.</p>
                                          <div class="card-footer"><button type="submit" style={{background:"#dc3545"}} class="btn btn-danger float-right ml-2" onClick={()=>{
                                           const id = toast.loading("Carregando...")
                                           fetch(`${apiUrl}${window.location.pathname}nt/remover-certificado/${item.id}`, {
                                              method: 'DELETE',
                                              credentials: 'include',
                                              headers: {
                                                'Content-Type': 'application/json',
                                                'Authorization': 'Bearer '+Cookies.get("auth_token"),
                                              }
                                            }).then(response => {
                                            if(response.status == 200){
                                              toast.update(id, { render:"Certificado removido com sucesso!", type: "success", isLoading: false,autoClose: 3000});
          
                                              this.setState((prevState) => ({
                                                  data: {
                                                    ...prevState.data,
                                                    certs2: prevState.data.certs2.filter(i => i.id !== item.id)
                                                  }
                                                }));
                                            Modal.close()
          
                                            }
                                            })
          
          
          
                                          }}><i class="fas fa-download"></i> Remover </button></div>
                                          </>
                                      })
                                    }}> <FontAwesomeIcon icon={faTrash} /></button></div>
                                 </td>
                              </tr>
                           </>)}
                              

                           </tbody>
                        </table>
                        </> : <>
                        Não há dados cadastrados!
                        </>}
                       
                     </div>
                  </div>
               </div>
               
               {pageNumbers2.length > 0 && <>
  <nav className='page-nvgt'>
               <ul class="pagination">
               <li class="page-item" onClick={() => this.handlePageChange2(currentPage2 - 1)}
                        disabled={currentPage2 === 1}><a class="page-link" href="#">
                        <FontAwesomeIcon icon={faAngleLeft}/>
                        </a></li>
                   
                    {pageNumbers2.map(number => (
                      
                      <li class="page-item" key={number}
                      onClick={() => this.handlePageChange2(number)}
                      className={number === currentPage2 ? 'active' : ''}><a class="page-link" href="#"> {number}</a></li>  
                      
                    
                    ))}
                    <li class="page-item" onClick={() => this.handlePageChange2(currentPage2 + 1)}
                        disabled={currentPage === totalPages2}><a class="page-link" href="#">
                           <FontAwesomeIcon icon={faAngleRight}/>
                        </a></li>
                   
                </ul>
        </nav>
</>}
            </div>
         </div>
      </div>
   </div>

   <div class="py-2">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
         <div class="col-12">
            <div class="card">
<div class="card-header d-flex">
 
  <div className='area-buscas'>

  <Link class="btn btn-xs btn-primary-2" style={{background:"#dc3545",color:"#fff"}} to={`${window.location.pathname}/create`}>
  <FontAwesomeIcon icon={faPlus} /> Adicionar Certidão CRC
  </Link>

<input type="text" placeholder='Pesquisar:' style={{marginLeft:10}}/>
<button>
  <FontAwesomeIcon color='#fff' icon={faMagnifyingGlass}/>
</button>
</div>
</div>

               <div class="card-body">
                  <div class="row">
                     <div class="col-12">
                        <p>Lista de certidão</p>
                        <div>
                   
                        </div>
                        {this.state.data.certs.length > 0 ? <>
                           <table class="table table-sm table-bordered">
                           <thead>
                              <tr>
                                 <th scope="col">#</th>
                                 <th scope="col">Nome</th>
                                 <th scope="col">Tipo de documento</th>
                              </tr>
                           </thead>
                           <tbody>

                           {this.state.data.certs.map((item,index)=><>
                           <tr>
                                 <td>{index + 1}</td>
                                 <td>{item.participante.nomeP}</td>
                                 <td>{item.tipo_ato}</td>
                              
                                 <td>
                                    <div class="btn-group btn-group-sm">
                                    <Link class="btn btn-success" to={`/${window.location.pathname.split("/")[1]}/preview/${item.token}`}><FontAwesomeIcon color='#fff' icon={faEye} /></Link>
                                    <Link class="btn btn-info" to={`/${window.location.pathname.split("/")[1]}/edit/${item.token}`}><FontAwesomeIcon color='#fff' icon={faPen} /></Link>
                                    <button class="btn btn-danger" onClick={()=>{
                                         Modal.create({
                                          titulo:"Tem certeza?",
                                          html:<><p>Após a remoção, não será possível recuperar o dado.</p>
                                          <div class="card-footer"><button type="submit" style={{background:"#dc3545"}} class="btn btn-danger float-right ml-2" onClick={()=>{
                                           const id = toast.loading("Carregando...")
                                           fetch(`${apiUrl}${window.location.pathname}/remover-certificado/${item.id}`, {
                                              method: 'DELETE',
                                              credentials: 'include',
                                              headers: {
                                                'Content-Type': 'application/json',
                                                'Authorization': 'Bearer '+Cookies.get("auth_token"),
                                              }
                                            }).then(response => {
                                            if(response.status == 200){
                                              toast.update(id, { render:"Certificado removido com sucesso!", type: "success", isLoading: false,autoClose: 3000});
          
                                              this.setState((prevState) => ({
                                                  data: {
                                                    ...prevState.data,
                                                    certs: prevState.data.certs.filter(i => i.id !== item.id)
                                                  }
                                                }));
                                            Modal.close()
          
                                            }
                                            })
          
          
          
                                          }}><i class="fas fa-download"></i> Remover </button></div>
                                          </>
                                      })
                                    }}> <FontAwesomeIcon icon={faTrash} /></button></div>
                                 </td>
                              </tr>
                           </>)}
                              

                           </tbody>
                        </table>
                        </> : <>
                        Não há dados cadastrados!
                        </>}
                       
                     </div>
                  </div>
               </div>
               {pageNumbers.length > 0 && <>
  <nav className='page-nvgt'>
               <ul class="pagination">
               <li class="page-item" onClick={() => this.handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}><a class="page-link" href="#">
                        <FontAwesomeIcon icon={faAngleLeft}/>
                        </a></li>
                   
                    {pageNumbers.map(number => (
                      
                      <li class="page-item" key={number}
                      onClick={() => this.handlePageChange(number)}
                      className={number === currentPage ? 'active' : ''}><a class="page-link" href="#"> {number}</a></li>  
                      
                    
                    ))}
                    <li class="page-item" onClick={() => this.handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}><a class="page-link" href="#">
                           <FontAwesomeIcon icon={faAngleRight}/>
                        </a></li>
                   
                </ul>
        </nav>
</>}
            </div>
         </div>
      </div>
   </div>


</main>
<div id="modal-root"></div>
<ToastContainer/>
      </>
  
    );
  }
}

export default TjRj;