import React, { Component } from 'react';
import p404 from "../../images/undraw_Warning_re_eoyh.png"
import "./p404.css"
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome} from '@fortawesome/free-solid-svg-icons'
class NotFound extends Component {
    componentDidMount(){
    document.title = "Página não encontrada"
 
    }
  render() {
    return (
      <div class="p404">
       <div>
        <img src={p404}/>
       <p>Desculpe, a página que você está procurando não existe.</p>
       <Link to="/" className='inc'><FontAwesomeIcon icon={faHome} />  Início</Link>
       </div>
     
      
      </div>
    );
  }
}

export default NotFound;