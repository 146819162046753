import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { apiUrl } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faEye,faUniversity,faPen,faPlus, faMagnifyingGlass, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../Comps/Modal';
import InputMask from 'react-input-mask';
import { Confirm,useDialog } from 'react-st-modal';

class TjGo extends Component {
  constructor(props) {
    super(props);
    this.state = {
    Loader:true,
    currentPage: 1,
    itemsPerPage: 5,
    totalPages: 0
    };
    this.iptCode = React.createRef();
    this.iptNome = React.createRef();
    this.iptCodeSer = React.createRef();
    this.iptResp = React.createRef();
    this.iptEdrc = React.createRef();
    this.iptCidade = React.createRef();
    this.iptTelefone = React.createRef();
    this.iptCns = React.createRef();
    this.dialog = useDialog;
  }

  componentDidMount() {
    document.title = "Goías - Consulta";
    
    fetch(`${apiUrl}/list`+window.location.pathname, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
this.setState({data,
totalPages:data.totalPage,
Loader:false})
})
}
      })


}

 
fetchItems(page) {
  fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/certificados/items?page=${page}&limit=${this.state.itemsPerPage}`)
      .then(response => response.json())
      .then(data => {
          this.setState({ items: data });
      })
      .catch(error => {
          console.error('Error fetching items:', error);
      });
}



handlePageChange = (page) => {
  this.setState({ currentPage: page }, () => {
      this.fetchItems(page);
  });
}

  render() {
   
    const { totalPages,currentPage } = this.state;
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    return (
     
      <>
      <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight"> TjGo </h2></div></header>
      <main>
 
   
   
      <div class="py-12">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
         <div class="col-12">
            <div class="card">
               <div class="card-header">
            <button class="btn btn-xs btn-primary-2" style={{background:"#dc3545",color:"#fff"}} onClick={()=>{
                Modal.create({
                    titulo:"Adicionar cartório",
                    html:<>
<div class="card-body">

<div class="form-group mt-2"><label>Código de cartório:</label>
   <input type="text" class="form-control form-control-sm" onChange={(e)=>{
    this.setState({code:e.target.value})
   }}/></div>



   <div class="form-group mt-2"><label>Nome do Ofício:</label>
   <input type="text" class="form-control form-control-sm" onChange={(e)=>{
    this.setState({nomeOfc:e.target.value})
   }}/></div>


<div class="form-group mt-2"><label>Código da serventia:</label>
   <InputMask type="text" value={this.state.codeser}  mask="9999" class="form-control form-control-sm"  onChange={(e)=>{

    this.setState({codeser:e.target.value})

    
   }}/></div>




   <div class="form-group mt-2"><label>Endereço:</label>
   <input type="text" class="form-control form-control-sm" onChange={(e)=>{
    this.setState({Edrc:e.target.value})
   }}/>
   </div>
   <div class="form-group mt-2"><label>Cidade/UF:</label>
   <input type="text" class="form-control form-control-sm"
   onChange={(e)=>{
    this.setState({Cidade:e.target.value})
   }}
   /></div>
   <div class="form-group mt-2"><label>Telefone:</label>
    <InputMask
          mask="(99) 9999-9999" class="form-control form-control-sm" onChange={(e)=>{
            this.setState({Telefone:e.target.value})
           }} />


<div class="form-group mt-2"><label>Cns:</label>
   <input type="text" class="form-control form-control-sm" onChange={(e)=>{
    this.setState({Cns:e.target.value})
   }}/>
   </div>
   </div>
</div>

<div class="card-footer">
<button type="submit" style={{background:"#0d6efd"}} class="btn btn-primary float-right ml-2" onClick={()=>{
    const id = toast.loading("Enviando...")
 
    fetch(`${apiUrl}${window.location.pathname}/adicionar-cartorio/`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        },
        body:JSON.stringify({
        code:this.state.code,
        nomeOfc:this.state.nomeOfc,
        codeser:this.state.codeser.replace(/_/g, '').padStart(4, '0'),
        Resp:this.state.Resp,
        Edrc:this.state.Edrc,
        Cidade:this.state.Cidade,
        Telefone:this.state.Telefone,
        Cns:this.state.Cns,
        })
      }).then(response => {
      if(response.status == 200){
       
        response.json().then(data=>{
            toast.update(id, { render:"cartório adicionado com sucesso!", type: "success", isLoading: false,autoClose: 3000});
            Modal.close()
            const newItem = {
              id:data.id,
              code:this.state.code,
              nome:this.state.nomeOfc,
              codeser:this.state.codeser.padStart(4, '0'),
              resp:this.state.Resp,
              edrc:this.state.Edrc,
              cidade:this.state.Cidade,
              telefone:this.state.Telefone,
              Cns:this.state.Cns
            }

            this.setState((prevState) => ({
                data: {
                  ...prevState.data,
                  cartorio: [...prevState.data.cartorio, newItem]
                }
              }));
       })
       



      }
      })
}}><i class="fas fa-download"></i> Salvar </button>
</div>
                    </>
                })
            }}>  <FontAwesomeIcon icon={faUniversity} /> Adicionar cartório </button></div>
               <div class="card-body">
                  <div>
                 
                  </div>
                  
                       
                       {this.state.data.cartorio.length > 0 ? <>
                     <table class="table table-sm table-bordered">
                     <thead>
                        <tr>
                           <th scope="col">#</th>
                           <th scope="col">Nome</th>
                        
                           <th scope="col">Endereço</th>
                           <th></th>
                        </tr>
                     </thead>
                     <tbody>
                       {this.state.data.cartorio.map((item,index)=><>
                        <tr>
                           <td>{index + 1}</td>
                           <td>{item.nome}</td>
                          
                           <td>{item.edrc}</td>
                           <td>
                              <div class="btn-group btn-group-sm">
                            <button class="btn btn-info" onClick={()=>{
                                console.log(item)
                                Modal.create({
                                    titulo:"Editar cartório",
                                    html:<>
                <div class="card-body">

                <div class="form-group mt-2"><label>Código do cartório:</label>
                <input type="text" class="form-control form-control-sm" ref={this.iptCode} /></div>


                   <div class="form-group mt-2"><label>Nome do Ofício:</label>
                   <input type="text" class="form-control form-control-sm" ref={this.iptNome} /></div>
                  


                   <div class="form-group mt-2"><label>Código da serventia:</label>
                   <input mask="9999" type="text" class="form-control form-control-sm" ref={this.iptCodeSer} /></div>



                
                   <div class="form-group mt-2"><label>Endereço:</label>
                   <input type="text" class="form-control form-control-sm" ref={this.iptEdrc}/>
                   </div>
                   <div class="form-group mt-2"><label>Cidade/UF:</label>
                   <input type="text" class="form-control form-control-sm" ref={this.iptCidade}/></div>
                   <div class="form-group mt-2"><label>Telefone:</label>
                   <input type="text" class="form-control form-control-sm" ref={this.iptTelefone}/>
                   </div>
                   <div class="form-group mt-2"><label>Cns:</label>
                   <input type="text" class="form-control form-control-sm"  ref={this.iptCns}/>
                   </div>
                </div>
                
                <div class="card-footer">
                
                <button type="submit" style={{background:"#0d6efd"}} class="btn btn-primary float-right ml-2" onClick={()=>{
                    const id = toast.loading("Enviando...")
                   fetch(`${apiUrl}${window.location.pathname}/atualizar-cartorio/${item.id}`, {
                        method: 'PUT',
                        credentials: 'include',
                        headers: {
                          'Content-Type': 'application/json',
                          'Authorization': 'Bearer '+Cookies.get("auth_token"),
                        },
                        body:JSON.stringify({
                        code:this.iptCode.current.value,
                        nomeOfc:this.iptNome.current.value,
                        codeser:this.iptCodeSer.current.value.padStart(4, '0'),
                   
                        Edrc:this.iptEdrc.current.value,
                        Cidade:this.iptCidade.current.value,
                        Telefone:this.iptTelefone.current.value,
                        Cns:this.iptCns.current.value
                        })
                      }).then(response => {
                      if(response.status == 200){
                       
                       response.json().then(data=>{

                        toast.update(id, { render:"Cartório atualizado com sucesso!", type: "success", isLoading: false,autoClose: 3000});
                      
                        this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              cartorio: prevState.data.cartorio.map(i =>
                                i.id === item.id ? { ...i,
                                code: this.iptCode.current.value,
                                nome: this.iptNome.current.value,
                                codeser:this.iptCodeSer.current.value,
                                
                                edrc:this.iptEdrc.current.value,
                                cidade:this.iptCidade.current.value,
                                telefone:this.iptTelefone.current.value,
                                Cns:this.iptCns.current.value
                            } : i
                              )
                            }
                          }));
                        Modal.close()
                       })
                       
                
                
                
                      }
                      })
                }}><i class="fas fa-download"></i> Atualizar </button>
                </div>
                                    </>
                                 })
                              
                                 this.iptCode.current.value = item.code;
                                 this.iptNome.current.value = item.nome;
                                 this.iptCodeSer.current.value = item.codeser;
                            
                                 this.iptEdrc.current.value = item.edrc;
                                 this.iptCidade.current.value = item.cidade;
                                 this.iptTelefone.current.value = item.telefone;
                                 this.iptCns.current.value = item.Cns
                            }}>
                            <FontAwesomeIcon color='#fff' icon={faPen} />
                            </button>
                            <button  class="btn btn-danger" onClick={async ()=>{
                           
           
                
                           
                         var r = await  Confirm('Após a remoção, não será possível recuperar as informações!', 
                            'Tem certeza?');
                           if(r){
                            const id = toast.loading("Carregando...")
                            fetch(`${apiUrl}${window.location.pathname}/remover-cartorio/${item.id}`, {
                              method: 'DELETE',
                              credentials: 'include',
                              headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer '+Cookies.get("auth_token"),
                              }
                            }).then(response => {
                            if(response.status == 200){
                              toast.update(id, { render:"Cartório removido com sucesso!", type: "success", isLoading: false,autoClose: 3000});

                              this.setState((prevState) => ({
                                  data: {
                                    ...prevState.data,
                                    cartorio: prevState.data.cartorio.filter(i => i.id !== item.id)
                                  }
                                }));
                       

                            }
                            })
                           }
                           
                          /* Modal.create({
                                titulo:"Tem certeza?",
                                html:<><p>Após a remoção, não será possível recuperar o dado.</p>
                                <div class="card-footer"><button type="submit" style={{background:"#dc3545"}} class="btn btn-danger float-right ml-2" onClick={()=>{
                                 const id = toast.loading("Carregando...")
                                 fetch(`${apiUrl}${window.location.pathname}/remover-cartorio/${item.id}`, {
                                    method: 'DELETE',
                                    credentials: 'include',
                                    headers: {
                                      'Content-Type': 'application/json',
                                      'Authorization': 'Bearer '+Cookies.get("auth_token"),
                                    }
                                  }).then(response => {
                                  if(response.status == 200){
                                    toast.update(id, { render:"Cartório removido com sucesso!", type: "success", isLoading: false,autoClose: 3000});

                                    this.setState((prevState) => ({
                                        data: {
                                          ...prevState.data,
                                          cartorio: prevState.data.cartorio.filter(i => i.id !== item.id)
                                        }
                                      }));
                                  Modal.close()

                                  }
                                  })



                                }}><i class="fas fa-download"></i> Remover </button></div>
                                </>
                            })
                                */
                            }}>
                            <FontAwesomeIcon icon={faTrash} />
                            </button>
                            </div>
                           </td>
                        </tr>
                       </>)}
                       </tbody>
                  </table>
                       </> : <>
                       Não há dados cadastrados!
                       </>}
                        
                        
                      


                
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="py-12">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
         <div class="col-12">
            <div class="card">
<div class="card-header d-flex">
  <Link class="btn btn-xs btn-primary-2" style={{background:"#dc3545",color:"#fff"}} to={`${window.location.pathname}/create`}>
  <FontAwesomeIcon icon={faPlus} /> Adicionar certidão
  </Link>
<div className='area-buscas'>
<input type="text" placeholder='Pesquisar:'/>
<button>
  <FontAwesomeIcon color='#fff' icon={faMagnifyingGlass}/>
</button>
</div>
</div>

               <div class="card-body">
                  <div class="row">
                     <div class="col-12">
                        <p>Lista de certidão</p>
                        <div>
                   
                        </div>
                        {this.state.data.certs.length > 0 ? <>
                           <table class="table table-sm table-bordered">
                           <thead>
                              <tr>
                                 <th scope="col">#</th>
                                 <th scope="col">Civil do ato</th>
                                 <th scope="col">Tipo de documento</th>
                              </tr>
                           </thead>
                           <tbody>

                           {this.state.data.certs.map((item,index)=><>
                           <tr>
                                 <td>{index + 1}</td>
<td>{item.civilato}</td>
                                 <td>{item.docEmt}</td>
                                 <td>
                                    <div class="btn-group btn-group-sm">
                                    <Link class="btn btn-success" to={`/${window.location.pathname.split("/")[1]}/preview/${item.token}`}><FontAwesomeIcon color='#fff' icon={faEye} /></Link>
                                    <Link class="btn btn-info" to={`/${window.location.pathname.split("/")[1]}/edit/${item.token}`}><FontAwesomeIcon color='#fff' icon={faPen} /></Link>
                                    <button class="btn btn-danger" onClick={async ()=>{
                                
                                      var r = await Confirm('Após a remoção, não será possível recuperar as informações!', 
                                        'Tem certeza?');
                                  
if(r){
  const id = toast.loading("Enviando...")
  fetch(`${apiUrl}${window.location.pathname}/remover-certificado/${item.token}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+Cookies.get("auth_token"),
    }
  }).then(response => {
    if(response.status == 200){
      toast.update(id, { render:"Certificado removido com sucesso!", type: "success", isLoading: false,autoClose: 3000});

      this.setState((prevState) => ({
          data: {
            ...prevState.data,
            certs: prevState.data.certs.filter(i => i.id !== item.id)
          }
        }));


    }
 

  })
}

                                       /*
                                        Modal.create({
                                          titulo:"Tem certeza?",
                                          html:<><p>Após a remoção, não será possível recuperar o dado.</p>
                                          <div class="card-footer"><button type="submit" style={{background:"#dc3545"}} class="btn btn-danger float-right ml-2" onClick={()=>{
                                           const id = toast.loading("Carregando...")
                                           fetch(`${apiUrl}${window.location.pathname}/remover-certificado/${item.token}`, {
                                              method: 'DELETE',
                                              credentials: 'include',
                                              headers: {
                                                'Content-Type': 'application/json',
                                                'Authorization': 'Bearer '+Cookies.get("auth_token"),
                                              }
                                            }).then(response => {
                                            if(response.status == 200){
                                              toast.update(id, { render:"Certificado removido com sucesso!", type: "success", isLoading: false,autoClose: 3000});
          
                                              this.setState((prevState) => ({
                                                  data: {
                                                    ...prevState.data,
                                                    certs: prevState.data.certs.filter(i => i.id !== item.id)
                                                  }
                                                }));
                                            Modal.close()
          
                                            }
                                            })
          
          
          
                                          }}><i class="fas fa-download"></i> Remover </button></div>
                                          </>
                                      })
                                          */
                                    }}> <FontAwesomeIcon icon={faTrash} /></button></div>
                                 </td>
                              </tr>
                           </>)}
                              

                           </tbody>
                        </table>
                        </> : <>
                        Não há dados cadastrados!
                        </>}
                       
                     </div>
                  </div>
               </div>


               {pageNumbers.length > 0 && <>
  <nav className='page-nvgt'>
               <ul class="pagination">
               <li class="page-item" onClick={() => this.handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}><a class="page-link" href="#">
                        <FontAwesomeIcon icon={faAngleLeft}/>
                        </a></li>
                   
                    {pageNumbers.map(number => (
                      
                      <li class="page-item" key={number}
                      onClick={() => this.handlePageChange(number)}
                      className={number === currentPage ? 'active' : ''}><a class="page-link" href="#"> {number}</a></li>  
                      
                    
                    ))}
                    <li class="page-item" onClick={() => this.handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}><a class="page-link" href="#">
                           <FontAwesomeIcon icon={faAngleRight}/>
                        </a></li>
                   
                </ul>
        </nav>
</>}
          
            </div>
         </div>
      </div>
   </div>
</main>
<div id="modal-root"></div>
<ToastContainer/>
      </>
  
    );
  }
}

export default TjGo;