import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { apiUrl } from '../../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave,faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import InputMask from 'react-input-mask';
class CreateCertTjEs extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
     totalgeral:'',
     atos:[],
     tjes: {
        nome:'',
        nomeSer:'',
        codeSer:'',
        ato: 'CERTIDÃO',
        servico: '',
        emolumentos: '0,00',
        funepj: '',
        fadespes: '',
        funemp: '',
        funcad: '',
        encargos: '',
        valor_referencia: '',
        protocolo_apontamento: '-',
        livro: '',
        termo: '',
        folha: '',
        data:'',
        firma: '-'
      },
      opcs:[
        { value: "Selecione:", label: "Selecione:"},
        { value: "Processamento de dados", label: "Processamento de dados",
        taxa:[8.10,0.81,0.40,0.40,0.40,0.40,0.0]
       },
        { value: "Certidão de casamento, nascimento e óbito - breve relato", label: "Certidão de casamento, nascimento e óbito - breve relato",
          taxa:[23.43,2.34,1.17,1.17,1.17,1.17,0.0]
        },
        { value: "Certidão de casamento, nascimento e óbito - inteiro teor", label: "Certidão de casamento, nascimento e óbito - inteiro teor",
          taxa:[48.73,4.87,2.44,2.44,2.44,2.44,0.0]
         },
        { value: "Busca de papéis, processos e doc arquivados, por período de 3 anos", label:"Busca de papéis, processos e doc arquivados, por período de 3 anos",
        taxa:[3.14,0.31,0.16,0.16,0.16,0.16,0.0]
        }
    ]
    
    };
  }

  somarValores(dados) {
    let total = 0;
  
    // Itera por cada objeto no array
    dados.forEach(item => {
      // Itera por cada chave-valor no objeto
      for (let chave in item) {
        if (item[chave].startsWith("R$")) {
          // Remove o "R$ " e substitui a vírgula por ponto para converter em número
          const valorNumerico = parseFloat(item[chave].replace("R$", "").replace(",", "."));
          // Adiciona ao total
          total += valorNumerico;
        }
      }
    });
  
    // Formata o total de volta para o formato "R$"
    return total
  }

 somarAto(json) {
    let total = 0;
  
    // Itera por cada chave-valor no objeto
    for (let chave in json) {
      const valor = json[chave];
      if (valor.startsWith("R$")) {
        // Remove o "R$ " e substitui a vírgula por ponto para converter em número
        const valorNumerico = parseFloat(valor.replace("R$", "").replace(",", "."));
        // Adiciona ao total
        total += valorNumerico;
      }
    }
  
    // Formata o total de volta para o formato "R$"
    return total;
  }
  componentDidMount() {
    document.title = "Emitir Certidão TJES ";
    fetch(`${apiUrl}/listar-cartorios/`+window.location.pathname.split("/")[1], {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({data,Loader:false})
})
}
      })
}

formatToBRL(amount) {
  // Convertendo o número para o formato BRL
  const formattedAmount = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(amount);

  return formattedAmount;
}


calcularValor(percentual, totalGeral) {
  return (percentual * totalGeral) / 100;
}



handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      tjes: {
        ...prevState.tjes,
        [name]: value
      }
    }));
  };



  handleInputChange = (index, event) => {
    const { name, value } = event.target;
    this.setState(prevState => {
      const atos = [...prevState.atos];
      atos[index][name] = value;
      return { atos };
    });
  }



isValidDate(dateString) {
    // Expressão regular para o formato dd/mm/yyyy
    const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
  

    if (!regex.test(dateString)) {
      return false;
    }
  

    const [day, month, year] = dateString.split('/').map(Number);
    

    const date = new Date(year, month - 1, day);
    

    if (date.getDate() !== day || date.getMonth() !== month - 1 || date.getFullYear() !== year) {
      return false;
    }
    

    const dayOfWeek = date.getDay();
    return dayOfWeek >= 1 && dayOfWeek <= 5; 
  }


  render() {
    const { tjes } = this.state;
    const valorTotal = this.somarValores(this.state.atos) + this.somarAto(this.state.tjes);
    
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight"> Emitir Certidão TJES  </h2></div></header>
        <main>
        <div class="py-12">
   <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="col-12">
       
            <div class="card">
               <div class="card-header">
                  <h3>Emissão de certidão:</h3>
               </div>
               <div class="card-body">
               <div className="form-group mt-2">
          <label>Nome:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="nome"
            value={tjes.nome}
            onChange={this.handleChange}
          />
        </div>
                  <div class="form-group">
                     <label>Nome da Serventia:</label>
                  
                     <select class="form-control form-control-sm" name='nomeSer' onChange={(e)=>{
                    
                    
                      const r = this.state.data.find(i=> i.id ===  Number(e.target.value));
                    

                        this.setState(prevState => ({
                          tjes: {
                            ...prevState.tjes,
                            ['nomeSer']: e.target.value,
                            ['codeSer']: r.Cns
                          }
                        }));
                     }}>
                     <option style={{display:'none'}}></option>
                        {this.state.data.map((item,index)=>{
                          
                            return(<>
                            <option value={item.id}>{item.nome}</option>
                            </>)
                        })}
                       
                     </select>
                  </div>
                 
          

        <div class="form-group mt-2">
                     <label>Tipo do ato:</label>
                     <select class="form-control form-control-sm"  name="ato" onChange={(e)=>{
                    const selectedIndex = e.target.selectedIndex;
                    const item = this.state.opcs[selectedIndex].taxa
                 

                    this.setState(prevState => ({
                      tjes: {
                        ...prevState.tjes,
                        ato:e.target.value,
                        emolumentos: this.formatToBRL(item[0]),
                        funepj:this.formatToBRL(item[1]),
                        fadespes:this.formatToBRL(item[2]),
                        funemp:this.formatToBRL(item[3]),
                        funcad:this.formatToBRL(item[4]),
                        encargos:this.formatToBRL(item[5]),
                        valor_referencia:this.formatToBRL(item[6])
                        
                        // Adicione mais propriedades conforme necessário
                      }
                    }));



                     
                     }}>
                      
                     {this.state.opcs.map((option, index) => (
   <option key={index} value={option.value}>
       {option.label}
   </option>
))}
                     </select>
                  </div>
       
        <div className="form-group mt-2">
          <label>Emolumentos:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            value={tjes.emolumentos}
            onChange={(e)=>{
         
              let newValue = e.target.value;

              // Remover todos os caracteres não numéricos (exceto ponto e vírgula)
              newValue = newValue.replace(/\D/g, '');
          
              // Adiciona as casas decimais
              newValue = (newValue / 100).toFixed(2);
          
              // Formata o valor para BRL
              newValue = newValue.replace('.', ',');

              newValue = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

var totalGeral = (newValue.replace(",", "."))
let funepj = this.calcularValor(10, totalGeral);
let fadespes = this.calcularValor(5, totalGeral);
let funemp = this.calcularValor(5, totalGeral);
let funcad = this.calcularValor(5, totalGeral);
let encargos = this.calcularValor(5, totalGeral);

       
              this.setState(prevState => ({
                tjes: {
                  ...prevState.tjes,
                  ['emolumentos']: newValue
                }
              }));

              this.setState(prevState => ({
                tjes: {
                  ...prevState.tjes,
                  emolumentos: newValue,
                  funepj:this.formatToBRL(funepj),
                  fadespes:this.formatToBRL(fadespes),
                  funemp:this.formatToBRL(funemp),
                  funcad:this.formatToBRL(funcad),
                  encargos:this.formatToBRL(encargos)
                  
                  // Adicione mais propriedades conforme necessário
                }
              }));




            
            }}
            disabled="true"
          />
        </div>
        <div className="form-group mt-2">
          <label>FUNEPJ:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="funepj"
            value={tjes.funepj}
            disabled="true"
          />
        </div>
        <div className="form-group mt-2">
          <label>FADESPES:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="fadespes"
            value={tjes.fadespes}
            disabled="true"
          />
        </div>
        <div className="form-group mt-2">
          <label>FUNEMP:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="funemp"
            value={tjes.funemp}
            disabled="true"
          />
        </div>
        <div className="form-group mt-2">
          <label>FUNCAD:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="funcad"
            value={tjes.funcad}
            disabled="true"
          />
        </div>
        <div className="form-group mt-2">
          <label>Encargos:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="encargos"
            value={tjes.encargos}
             disabled="true"
          />
        </div>
        <div className="form-group mt-2">
          <label>Valor de Referência:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="valor_referencia"
            value={tjes.valor_referencia}
            onChange={this.handleChange}
            disabled="true"
          />
        </div>
        <div className="form-group mt-2">
          <label>Protocolo/Apontamento:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="protocolo_apontamento"
            value={tjes.protocolo_apontamento}
            onChange={this.handleChange}
            disabled="true"
          />
        </div>
        <div className="form-group mt-2">
          <label>Livro:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="livro"
            value={tjes.livro}
            onChange={this.handleChange}
          />
        </div>
        <div className="form-group mt-2">
          <label>Termo:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="termo"
            value={tjes.termo}
            onChange={this.handleChange}
          />
        </div>
        <div className="form-group mt-2">
          <label>Folha:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="folha"
            value={tjes.folha}
            onChange={this.handleChange}
          />
        </div>


        <div className="form-group mt-2">
          <label>Data:</label>
          <InputMask
          mask="99/99/9999"
    
            className="form-control form-control-sm"
            name="data"
            value={tjes.data}
            onChange={this.handleChange}
          />
        </div>



        <div className="form-group mt-2">
          <label>Firma:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="firma"
            value={tjes.firma}
            disabled="true"

          />



        </div>
<hr/>

        <div className="form-group mt-2">
          <button className='btn btn-primary' onClick={()=>{
          if(this.state.atos.length > 1){
            return false;
          }
               this.setState(prevState => ({
                atos: [...prevState.atos,{}]
              }));

          }}>
            <FontAwesomeIcon icon={faPlus}/>
            Adicionar novo ato</button>
           
        </div>


        <div className="form-group mt-2">
    
                    

        {this.state.atos.map((form, index) =>
     
                      <div class="card" key={index} style={{
                        marginBottom:15
                      }}>
               <div class="card-header">
                  <h3>Ato: {index + 2}</h3>
               </div>
               <div class="card-body">
               <div class="form-group mt-2">
                     <label>Tipo do ato:</label>
                     <select class="form-control form-control-sm" 
              name="ato"
              onChange={(e) =>{
                const selectedIndex = e.target.selectedIndex;
                const item = this.state.opcs[selectedIndex].taxa
              

             
                this.setState(prevState => {
                  const atos = [...prevState.atos];
                  atos[index]['ato'] = e.target.value;
                  atos[index]['emolumentos'] = this.formatToBRL(item[0]);
                  atos[index]['funepj'] = this.formatToBRL(item[1]);
                  atos[index]['fadespes'] = this.formatToBRL(item[2]);
                  atos[index]['funemp'] = this.formatToBRL(item[3]);
                  atos[index]['funcad'] = this.formatToBRL(item[4]);
                  atos[index]['encargos'] = this.formatToBRL(item[5]);
                  return { atos };
                });





              }}>
                      
                     {this.state.opcs.map((option, index) => (
   <option key={index} value={option.value}>
       {option.label}
   </option>
))}
                     </select>
                  </div>

        <div className="form-group mt-2">
          <label>Emolumentos:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="emolumentos"
            value={form.emolumentos}
            onChange={(event) =>{
           

              let newValue = event.target.value;

       
              newValue = newValue.replace(/\D/g, '');
          

              newValue = (newValue / 100).toFixed(2);
          
  
              newValue = newValue.replace('.', ',');

              newValue = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');


              var totalGeral = (newValue.replace(",", "."))
              let funepj = this.calcularValor(10, totalGeral);
              let fadespes = this.calcularValor(5, totalGeral);
              let funemp = this.calcularValor(5, totalGeral);
              let funcad = this.calcularValor(5, totalGeral);
              let encargos = this.calcularValor(5, totalGeral);


              this.setState(prevState => {
                const atos = [...prevState.atos];
                atos[index][event.target.name] = newValue;
                atos[index]['funepj'] = this.formatToBRL(funepj);
                atos[index]['fadespes'] = this.formatToBRL(fadespes);
                atos[index]['funemp'] = this.formatToBRL(funemp);
                atos[index]['funcad'] = this.formatToBRL(funcad);
                atos[index]['encargos'] = this.formatToBRL(encargos);
                return { atos };
              });
            }}
            disabled="true"
          />
        </div>


        <div className="form-group mt-2">
          <label>FUNEPJ:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            value={form.funepj}
            disabled="true"
          />
        </div>


        <div className="form-group mt-2">
          <label>FADESPES:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            value={form.fadespes}
            disabled="true"
          />
        </div>




        <div className="form-group mt-2">
          <label>FUNEMP:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            value={form.funemp}
            disabled="true"
          />
        </div>

        <div className="form-group mt-2">
          <label>FUNCAD:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            value={form.funcad}
            disabled="true"
          />
        </div>



        <div className="form-group mt-2">
          <label>Encargos:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            value={form.encargos}
            disabled="true"
          />
        </div>


               </div>
               </div>



                      


                      )}   
                        
                         
                    
        </div>       
        Valor total: {valorTotal.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })}
               </div>
       
               <div class="card-footer">
               <button type="submit" class="btn btn-danger float-left ml-2" onClick={()=>{
                  this.props.navigate(-1)
               }}> <FontAwesomeIcon icon={faArrowLeft} /> Voltar</button>
                  
               <button type="submit" class="btn btn-primary float-right ml-2" onClick={()=>{
     



     
                if(this.state.tjes.nomeSer == undefined){
                  toast.error('Selecione o nome da Serventia!', {
                     position: "top-right",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     theme: "light",
                     });
                  return false;
                }
                if(this.state.tjes.nomeSer == ""){
                  toast.error('Selecione o nome da Serventia!', {
                     position: "top-right",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     theme: "light",
                     });
                  return false;
                }


if(this.isValidDate(this.state.tjes.data) == false){
  toast.error('Verifique a data informada!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });
  return false;
}




                const id = toast.loading("Enviando...")
                var body = this.state;
                
                fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/criar-certificado/`, {
                  method: 'POST',
                  credentials: 'include',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+Cookies.get("auth_token"),
                  },

                  body:JSON.stringify({tjes,atos:this.state.atos})
                }).then(response => {
if(response.status == 200){
   toast.update(id, { render:"Certificado criado com sucesso!", type: "success", isLoading: false,autoClose: 3000});
   
   response.json().then(data=>{
this.props.navigate(`/${window.location.pathname.split("/")[1]}/preview/${data.token}`)
   })
}
}).catch((err=>{
  toast.update(id, { render:"Documento não processado!", type: "error", isLoading: false,autoClose: 3000});
   
}))

               }}> <FontAwesomeIcon icon={faSave} /> Salvar </button></div>
            </div>
       
      </div>
   </div>
</div>
</main>
<ToastContainer/>
        </>
  
    );
  }
}

export default CreateCertTjEs;