import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from "../../../src/images/logo.png"
import { Link } from 'react-router-dom';
import { apiUrl } from '../../config';
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      senha: '',
      remember: false,
      errors: {
        email: '',
        password: ''
      }
    };
  }

  componentDidMount() {
    document.title = "Log in - Consulta";
}

 

setCookie = (name, value, expirationDays) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + expirationDays);

  const cookieString = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
  document.cookie = cookieString;
};

  render() {
    const { errors } = this.state;
    return (
      <div id="app" data-v-app="">
        <div className="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-gray-100">
          <div>
            <Link to="/">
            <img src={logo} width={75} height={75}/>
            </Link>
          </div>
          <div className="w-full sm:max-w-md mt-6 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg">
         
              <div>
                <label className="block font-medium text-sm text-gray-700" htmlFor="email">
                  <span>Email</span>
                </label>
                <input
                  className={`border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm mt-1 block w-full ${errors.email && 'is-invalid'}`}
                  id="email"
                  type="email"
                  name="email"
                  onChange={(e)=>{
                    this.setState({email:e.target.value})
                  }}
                  value={this.state.email}
                  autoFocus
          
                />
                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
              </div>
              <div className="mt-4">
                <label className="block font-medium text-sm text-gray-700" htmlFor="password">
                  <span>Senha:</span>
                </label>
                <input
                  className={`border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm mt-1 block w-full ${errors.password && 'is-invalid'}`}
                
                  type="password"
                  name="password"
                  onChange={(e)=>{
                    this.setState({senha:e.target.value})
                  }}
                  value={this.state.senha}
                
                />
                {errors.password && <div className="invalid-feedback">{errors.password}</div>}
              </div>
              
              <div className="flex items-center justify-end mt-4">
               
                <button
                
                  className="inline-flex items-center px-4 py-2 bg-gray-800 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-700 active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring focus:ring-gray-300 disabled:opacity-25 transition ml-4"
                  onClick={()=>{
                    var body = this.state;
                   console.log(this.state)
                    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if(body.email == ""){
                        toast.error('Preencha o campo de e-mail', {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                    
                            });
                            return false;
                    }

                    if(re.test(body.email) == false){
                        toast.error('E-mail inválido!', {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                    
                            });
                        return false;
                    }


                    if(body.senha == ""){
                        toast.error('Preencha o campo de senha', {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                    
                            });
                            return false;
                    }
                    const id = toast.loading("Carregando...")
                    fetch(`${apiUrl}/auth/login/`, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        // outras headers, se necessário
                      },
                      body: JSON.stringify({
                        email:this.state.email,
                        senha:this.state.senha
                      }), // corpo da requisição em formato JSON
                    })
                      .then(response => {
                       
                        
                       

if(response.status == 200){

response.json().then(data=>{
  this.setCookie('auth_token',data.Acess_Token,30);
  toast.update(id, { render:"Sucesso ao se conectar!", type: "success", isLoading: false,autoClose: 3000});
  window.location = ""
})
}
if(response.status == 404){
  response.json().then(data=>{
    toast.update(id, { render:data.msg, type: "error", isLoading: false,autoClose: 3000});
  })
}


                      })
                      
                      .catch(error => {
                        console.error('Erro:', error);
                        // trate o erro adequadamente
                      });
                  }
                
                }
                >
                 Entrar
                </button>
              </div>
   
          </div>
        </div>
        <ToastContainer/>
      </div>
  
    );
  }
}

export default Login;