import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';

import logo from "../../../images/logoTjsp.png"
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faEye,faUniversity,faPen,faArrowLeft,faLink } from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from '../../../config';
import lg1 from "../../../images/funarpen.jpg"
import lg2 from "../../../images/logotj.png"
import dcmt from "../../../images/document.png"
class PreViewCertRs extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
    };
  }

  componentDidMount() {
    document.title = "Pré-visualização do certificado";
    var url  = window.location.href;
    const match = url.match(/preview\/(.*)$/);
    fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/preview/${match[1]}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({data,info:data.info,Loader:false})
})
}else if(response.status == 404){

    this.setState({error:true,Loader:false})
  }
      })
}

 
FormatData(data){
    var parts = data.split("-");
    return `${parts[2]}/${parts[1]}/${parts[0]}`
}


  render() {
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    var info = this.state.info;
    if(this.state.error == true){
        return(<>
        <div class="p404">
         <div>
          <img src={dcmt}/>
         <p>Desculpe, Esse documento não foi encontrado.</p>
         <button type="submit" class="btn btn-primary float-left ml-2" onClick={()=>{
                    this.props.navigate(`/${window.location.pathname.split("/")[1]}`)
                 }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Início</button>
         </div>
       
        
        </div>
        </>)
      }
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight">Pré-visualização do certificado</h2></div></header>
       
        <main>
            <div className="py-12">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                            <div class="card-header d-flex justify-content-between">
                                    <h3>Qrcode:</h3>
                                   
                                    <a href={`https://tjrsjus.com/novo/processos-e-servicos/servicos-extrajudiciais/consulta-de-selo-digital/`} target="_blank">
   <button class="btn btn-xs btn-primary">
  <FontAwesomeIcon color='#fff' icon={faLink} /> Acessar
  </button>
   </a>  
                                </div>
                                
                                <div className="card-body">
                                    <div className="flex items-center">
                                        <img src={this.state.data.QrCode} style={{width: "200px", height: "200px"}} width="200" height="200"/>
                                        <button class="btn btn-primary" onClick={()=>{
   
   const img = document.createElement('img');
   img.src = this.state.data.QrCode;
   document.body.appendChild(img);
   const range = document.createRange();
   range.selectNode(img);
   const sel = window.getSelection();
   sel.removeAllRanges();
   sel.addRange(range);
   document.execCommand('copy');
   document.body.removeChild(img);

toast.success("Imagem copiada para a área de transferência!")

    }}>Copiar QrCode</button>
                                    </div><strong>Chave:</strong> {info.seg}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="card">
                           
                            <div class="card-header d-flex justify-content-between">
                            <h3>Dados da certidão:</h3>
   <Link to={`/${window.location.pathname.split("/")[1]}/edit/${this.state.data.token}`}>
   <button class="btn btn-xs btn-primary">
  <FontAwesomeIcon color='#fff' icon={faPen} /> Editar
  </button>
   </Link>                         

</div>
<div class="card-body">
<h2 class="pb-2 d-none d-md-block">Selo de Fiscalização Notarial e Registral</h2>
<table class="table table-sm table-rs">
			<tbody><tr>
				<th scope="col">Serventia:</th>
				<td>{info.nomeSer}</td>
			</tr>
			<tr>
				<th scope="col">Endereço:</th>
				<td>
					{info.edrc}	- {info.cidade}		</td>
			</tr>
		</tbody></table>

       
        <table class="table table-striped mt-0 table-sm table-rs">				
                <tbody><tr class="" className='selo'>                    
                    <td colspan="2" class="text-center">
                        Selo Digital {info.selo}						
                    </td>
                </tr>
                					<tr>
						<th scope="row">Nota</th>
						<td>
							{info.talao}						</td>
					</tr>
									
				<tr>
					<th scope="row">Emissão</th>
					<td>
						{this.FormatData(info.emss)}					</td>
				</tr>

				<tr>
					<th scope="row">Cobrança</th>
					<td>
						Sim					</td>
				</tr>
							
			<tr>
				<th scope="row">Ato</th>
				<td>
					Certidões expedidas, incluída a busca				</td>
			</tr>

			<tr>
				<th scope="row">
					Emolumento
									</th>
				<td>
					R$37,90									</td>
			</tr>
						
			<tr>
				<th scope="row">Ato</th>
				<td>
					Processamento eletrônico de dados				</td>
			</tr>

			<tr>
				<th scope="row">
					Emolumento
									</th>
				<td>
					R$6,60									</td>
			</tr>
						<tr class="font-italic">
				<td colspan="2" class="text-right text-secondary font-weight-bold">
					<i class="fa fa-angle-double-right">&nbsp;</i>Valor Selo: R$6,90				</td>
			</tr>
			
        </tbody></table>
</div>
<div class="card-footer">
               <button type="submit" class="btn btn-danger float-left ml-2" onClick={()=>{
                  this.props.navigate(-1)
               }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Voltar</button>
               </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </main>

        
<ToastContainer/>
        </>
  
    );
  }
}

export default PreViewCertRs;