import React, { Component } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faArrowLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from '../../../config';
import lg1 from "../../../images/funarpen.jpg"
import lg2 from "../../../images/logotj.png"
import dcmt from "../../../images/document.png"
class EditarCertSc extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
          
    };
   
  }

  componentDidMount() {
    document.title = "Editar certificado";
    var url  = window.location.href;
    const match = url.match(/edit\/(.*)$/);
    fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/dados-certificado/${match[1]}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({data,Loader:false})
})
}else if(response.status == 404){

  this.setState({error:true,Loader:false})
}
      })
}

 
handleChange = (e) => {

  this.setState((prevState) => ({
    data: {
      ...prevState.data,
      certificado: {
        ...prevState.data.certificado,
        [e.target.name]: e.target.value
      }
    }
  }));





}


  render() {
    
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    if(this.state.error == true){
      return(<>
      <div class="p404">
       <div>
        <img src={dcmt}/>
       <p>Desculpe, Esse documento não foi encontrado.</p>
       <button type="submit" class="btn btn-primary float-left ml-2" onClick={()=>{
                  this.props.navigate(`/${window.location.pathname.split("/")[1]}`)
               }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Início</button>
       </div>
     
      
      </div>
      </>)
    }
    var body = this.state.data.certificado;
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight">Editar Certidão TjSc </h2></div></header>
       
        <main>
      <div className="py-12">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="col-12">
       
            <div class="card">
               <div class="card-header">
                  <h3>Emissão de certidão:</h3>
               </div>
  <div class="card-body">
   <div class="form-group">
      <label>Nome da Serventia:</label>
      <select className="form-control form-control-sm" onChange={(e)=>{
                         this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              certificado: {
                                ...prevState.data.certificado,
                                serventia: e.target.value
                              }
                            }
                          }));
                      }} value={this.state.data.certificado.serventia}>
                       {this.state.data.cartorios.map((item,index)=><>
                        <option value={item.id}>{item.nome}</option>
                       </>)}
                        
                       
                      </select>
   </div>
  
   <div class="form-group mt-2">
                     <label>Tipo do ato:</label>
                     <select className="form-control form-control-sm" onChange={(e)=>{
                         this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              certificado: {
                                ...prevState.data.certificado,
                                docEmt: e.target.value
                              }
                            }
                          }));
                      }} value={this.state.data.certificado.docEmt}>
                      <option value="CERTIDÃO"> CERTIDÃO </option>
                      <option value="CERTIDÃO DE NASCIMENTO"> CERTIDÃO DE NASCIMENTO </option>
                      <option value="CERTIDÃO DE CASAMENTO"> CERTIDÃO DE CASAMENTO </option>
                      <option value="CERTIDÃO DE ÓBITO"> CERTIDÃO DE ÓBITO </option>
                      <option value="PROCURAÇÃO PÚBLICA"> PROCURAÇÃO PÚBLICA </option>
                      <option value="ESCRITURA PÚBLICA"> ESCRITURA PÚBLICA </option>
                      </select>
                  </div>

                 
<div className="form-group mt-2">
  <label>Nome:</label>
  <input 
    type="text" 
    name="nome"
    value={body.nome}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>
<div className="form-group mt-2">
  <label>Pessoa:</label>
  <input 
    type="text" 
    name="pessoa"
    value={body.pessoa}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>
<div className="form-group mt-2">
  <label>Profissão:</label>
  <input 
    type="text" 
    name="profissao"
    value={body.profissao}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>
<div className="form-group mt-2">
  <label>Nacionalidade:</label>
  <input 
    type="text" 
    name="nacionalidade"
    value={body.nacionalidade}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>
<div className="form-group mt-2">
  <label>Estado Civil:</label>
  <input 
    type="text" 
    name="estadoCivil"
    value={body.estadoCivil}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>
<div className="form-group mt-2">
  <label>Sexo:</label>
  <input 
    type="text" 
    name="sexo"
    value={body.sexo}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>
<div className="form-group mt-2">
  <label>Doc. Tipo:</label>
  <input 
    type="text" 
    name="docTipo"
    value={body.docTipo}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>
<div className="form-group mt-2">
  <label>Doc. No.:</label>
  <input 
    type="text" 
    name="docNo"
    value={body.docNo}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>
<div className="form-group mt-2">
  <label>Tipo:</label>
  <input 
    type="text" 
    name="tipo"
    value={body.tipo}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>
<div className="form-group mt-2">
  <label>Logradouro:</label>
  <input 
    type="text" 
    name="logradouro"
    value={body.logradouro}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>
<div className="form-group mt-2">
  <label>Número:</label>
  <input 
    type="text" 
    name="numero"
    value={body.numero}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>
<div className="form-group mt-2">
  <label>Bairro:</label>
  <input 
    type="text" 
    name="bairro"
    value={body.bairro}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>
<div className="form-group mt-2">
  <label>Cidade/UF:</label>
  <input 
    type="text" 
    name="cidadeUf"
    value={body.cidadeUf}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>
<div className="form-group mt-2">
  <label>CEP:</label>
  <input 
    type="text" 
    name="cep"
    value={body.cep}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>



<div className="form-group mt-2">
  <label>Tipo telefone:</label>
  <input 
    type="text" 
    name="tipoTel"
    value={body.tipoTel}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>




<div className="form-group mt-2">
  <label>DDD telefone:</label>
  <input 
    type="text" 
    name="telDDD"
    value={body.telDDD}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>




<div className="form-group mt-2">
  <label>Número do  telefone:</label>
  <input 
    type="text" 
    name="telNmr"
    value={body.telNmr}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>


<div className="form-group mt-2">
  <label>Valor:</label>
  <input 
    type="text" 
    name="valor"
    value={body.valor}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>
<div className="form-group mt-2">
  <label>Emolumento:</label>
  <input 
    type="text" 
    name="emolumento"
    value={body.emolumento}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>
<div className="form-group mt-2">
  <label>ISS:</label>
  <input 
    type="text" 
    name="emolumento"
    value={body.iss}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>
<div className="form-group mt-2">
  <label>Data e hora da finalização do ato:</label>
  <input 
    type="text" 
    name="dataHoraFinalizacao"
    value={body.dataHoraFinalizacao}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>
<div className="form-group mt-2">
  <label>Data e hora do recebimento do ato pelo TJSC:</label>
  <input 
    type="text" 
    name="dataHoraRecebimento"
    value={body.dataHoraRecebimento}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>
<div className="form-group mt-2">
  <label>Data em que o ato foi solicitado:</label>
  <input 
    type="text" 
    name="dataSolicitacao"
    value={body.dataSolicitacao}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>
<div className="form-group mt-2">
  <label>Nome do livro:</label>
  <input 
    type="text" 
    name="nomeLivro"
    value={body.nomeLivro}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>
<div className="form-group mt-2">
  <label>No do Livro:</label>
  <input 
    type="text" 
    name="noLivro"
    value={body.noLivro}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>
<div className="form-group mt-2">
  <label>Pagina inicial:</label>
  <input 
    type="text" 
    name="paginaInicial"
    value={body.paginaInicial}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>
<div className="form-group mt-2">
  <label>Pagina Final:</label>
  <input 
    type="text" 
    name="paginaFinal"
    value={body.paginaFinal}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>
<div className="form-group mt-2">
  <label>Data do Registro:</label>
  <input 
    type="text" 
    name="dataRegistro"
    value={body.dataRegistro}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>
<div className="form-group mt-2">
  <label>No Assento:</label>
  <input 
    type="text" 
    name="noAssento"
    value={body.noAssento}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
  />
</div>
<div className="form-group mt-2">
  <label>Obs:</label>
  <textarea 
    name="obs"
    value={body.obs}
    onChange={this.handleChange}
    className="form-control form-control-sm" 
    cols="30" 
    rows="10"
  />
</div>


        
   
   </div>

               <div class="card-footer">
               <button type="submit" class="btn btn-danger float-left ml-2" onClick={()=>{
                  this.props.navigate(-1)
               }}><FontAwesomeIcon icon={faArrowLeft} /> Voltar</button>
                  
               <button type="submit" class="btn btn-primary float-right ml-2" onClick={()=>{



                if(this.state.data.certificado.nomeServentia == undefined){
                  toast.error('Selecione o nome da Serventia!', {
                     position: "top-right",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     theme: "light",
                     });
                  return false;
                }
                if(this.state.data.certificado.nomeServentia == ""){
                  toast.error('Selecione o nome da Serventia!', {
                     position: "top-right",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     theme: "light",
                     });
                  return false;
                }

                const id = toast.loading("Enviando...")
                var url  = window.location.href;
                const match = url.match(/edit\/(.*)$/);
             var body = this.state.data.certificado;
                fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/atualizar-certificado/${match[1]}`, {
                  method: 'POST',
                  credentials: 'include',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+Cookies.get("auth_token"),
                  },
                  body:JSON.stringify({
                 
                    nomeServentia: body.nomeServentia,
                    nome: body.nome,
                    pessoa: body.pessoa,
                    profissao: body.profissao,
                    nacionalidade: body.nacionalidade,
                    estadoCivil: body.estadoCivil,
                    sexo: body.sexo,
                    docEmt:body.docEmt,
                    docTipo: body.docTipo,
                    docNo: body.docNo,
                    tipo: body.tipo,
                    logradouro: body.logradouro,
                    numero: body.numero,
                    bairro: body.bairro,
                    cidadeUf: body.cidadeUf,
                    cep: body.cep,
                    tipoTel: body.tipoTel,
                    telDDD: body.telDDD,
                    telNmr: body.telNmr,
                    valor: body.valor,
                    emolumento: body.emolumento,
                    iss: body.iss,
                    dataHoraFinalizacao: body.dataHoraFinalizacao,
                    dataHoraRecebimento: body.dataHoraRecebimento,
                    dataSolicitacao: body.dataSolicitacao,
                    nomeLivro: body.nomeLivro,
                    noLivro: body.noLivro,
                    paginaInicial: body.paginaInicial,
                    paginaFinal: body.paginaFinal,
                    dataRegistro: body.dataRegistro,
                    noAssento: body.noAssento,
                    obs: body.obs
                  
                 
                  })
                }).then(response => {
if(response.status == 200){
   toast.update(id, { render:"Certificado criado com sucesso!", type: "success", isLoading: false,autoClose: 3000});
   
   response.json().then(data=>{
this.props.navigate(`/${window.location.pathname.split("/")[1]}/preview/${this.state.data.certificado.token}`)
   })
}
})

               }}><FontAwesomeIcon icon={faSave} /> Salvar </button></div>
            </div>
       
      </div>
   </div>
      </div>
    </main>

        
<ToastContainer/>
        </>
  
    );
  }
}

export default EditarCertSc;