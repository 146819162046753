import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import "./c.css"
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft,faPen,faLink } from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from '../../../config';
import lg1 from "../../../images/funarpen.jpg"
import lg2 from "../../../images/logotj.png"
import dcmt from "../../../images/document.png"
class PreViewCertPR extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
    };
  }

  componentDidMount() {
    document.title = "Pré-visualização do certificado";
    var url  = window.location.href;
    const match = url.match(/preview\/(.*)$/);
    fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/preview/${match[1]}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({data,Loader:false})
})
}else if(response.status == 404){

    this.setState({error:true,Loader:false})
 }
      })
}

ocultarString(texto) {
    // Obter os 3 primeiros caracteres
    const primeirosCaracteres = texto.substring(0, 3);
    
    // Substituir o restante da string por '*'
    const estrelas = '*'.repeat(texto.length - 3);
    
    // Combinar os 3 primeiros caracteres com as '*'
    return primeirosCaracteres + estrelas;
  }


  render() {
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    if(this.state.error == true){
        return(<>
        <div class="p404">
         <div>
          <img src={dcmt}/>
         <p>Desculpe, Esse documento não foi encontrado.</p>
         <button type="submit" class="btn btn-primary float-left ml-2" onClick={()=>{
                    this.props.navigate(-1)
                 }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Voltar</button>
         </div>
       
        
        </div>
        </>)
      }
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight">Pré-visualização do certificado</h2></div></header>
       
        <main>
            <div className="py-12">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                            <div class="card-header d-flex justify-content-between">
                                    <h3>Qrcode:</h3>
                                    <a href={this.state.data.link} target="_blank">
   <button class="btn btn-xs btn-primary">
  <FontAwesomeIcon color='#fff' icon={faLink} /> Acessar
  </button>
   </a>  
                                </div>
                                <div className="card-body">
                                    <div className="flex items-center">
                                        <img src={this.state.data.QrCode} style={{width: "200px", height: "200px"}} width="200" height="200"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="card">
                           
                            <div class="card-header d-flex justify-content-between">
                            <h3>Dados da certidão:</h3>
   <Link to={`/${window.location.pathname.split("/")[1]}/edit/${this.state.data.token}`}>
   <button class="btn btn-xs btn-primary">
  <FontAwesomeIcon color='#fff' icon={faPen} /> Editar
  </button>
   </Link>                         

</div>

<div className="card-body">
<div class="panel-body">


<h2 class="title">VERIFICAÇÃO DO SELO DIGITAL</h2>
<h5>Número do Selo</h5>
<p>{this.state.data.token}</p>
<br/>
<h5>Controle</h5>
<p>{this.state.data.cert.controle}</p>
<br/>
<h2 class="title">INFORMAÇÕES DO CARTÓRIO</h2>
<h5>Nome da Serventia </h5>
<p> {this.state.data.cartorio.nome.toUpperCase()} </p>
<h5>Responsável</h5>
<p>{this.state.data.cartorio.resp.toUpperCase()}</p>
<h5>Endereço</h5>
<p>{this.state.data.cartorio.edrc.toUpperCase()}</p>
<h5>Cidade/UF</h5>
<p>{this.state.data.cartorio.cidade.toUpperCase()}</p>
<h5>Telefone</h5>
<p>{this.state.data.cartorio.telefone}</p>
<br/>
<h2 class="title">INFORMAÇÕES DO SELO</h2>
<h5>Tipo de Ato</h5>
<p>{this.state.data.cert.docEmt}</p>
<h5>Data de Geração</h5>
<p>{this.state.data.cert.dataGer}</p>
<h5>Protocolo</h5>
<p>{this.state.data.cert.nmrLiv}</p>
<h5>Número CPF/CNPJ</h5>
<p>{this.state.data.cert.nmrFol}</p>
<h5>Valor Total</h5>
    <p>R$ {this.state.data.cert.nmrAss}</p>   
<br/>
{this.state.data.cert.propriedade != undefined && <>
    <h5>PROPRIEDADES</h5>
<table className='pprt'>
    <tr>
        <td>Tipo Envolvido</td>
        <td>{this.state.data.cert.propriedade.tipo_env}</td>
    </tr>
    <tr>
        <td>Nome ou Razão Social</td>
        <td>{this.state.data.cert.propriedade.name_raz}</td>
    </tr>
    <tr>
        <td>CPF/CNPJ</td>
        <td>{this.ocultarString(this.state.data.cert.propriedade.doc)}</td>
    </tr>
</table>
</>}

<div style={{textAlign:'center'}}>
<br/>
<br/>
</div>
</div>
</div>

                            <div class="card-footer">
                            <button class="btn btn-danger" onClick={()=>{
                      this.props.navigate(-1)
                    }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} />  Voltar </button>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </main>

        
<ToastContainer/>
        </>
  
    );
  }
}

export default PreViewCertPR;