import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { apiUrl } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faEye,faUniversity,faPen,faPlus, faMagnifyingGlass, faSave, faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../Comps/Modal';
import { StaticDialog } from 'react-st-modal';

class TjMg extends Component {
  constructor(props) {
    super(props);
    this.state = {
    Loader:true,
    currentPage: 1,
    itemsPerPage: 5,
    totalPages: 0,
    editar:[],
    Medit:false
    };
    this.iptNome = React.createRef();
    this.iptResp = React.createRef();
    this.iptEdrc = React.createRef();
    this.iptCidade = React.createRef();
    this.iptQuali = React.createRef();
    this.iptCns = React.createRef();
  }

  componentDidMount() {
    document.title = "Minas Gerais - Consulta";
    fetch(`${apiUrl}/list`+window.location.pathname, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({data,
      Loader:false,
      totalPages:data.totalPage,
    })
})
}
      })


}

Edit = (e) => {
  const item = e.target;
  this.setState((prevState) => ({
    editar: {
      ...prevState.editar,
      [item.name]:item.value,
    },
  }));
};


  render() {
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }

    const { totalPages,currentPage} = this.state;
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
  }
    return (
     
      <>
      <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight"> TjMg </h2></div></header>
      <main>
 
   
   
      <div class="py-12">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
         <div class="col-12">
            <div class="card">
               <div class="card-header">
            <button class="btn btn-xs btn-primary-2" style={{background:"#dc3545",color:"#fff"}} onClick={()=>{
                Modal.create({
                    titulo:"Adicionar cartório",
                    html:<>
<div class="card-body">

   <div class="form-group mt-2"><label>Nome do Ofício:</label>
   <input type="text" class="form-control form-control-sm" onChange={(e)=>{
    this.setState({nomeOfc:e.target.value})
   }}/></div>


   <div class="form-group mt-2"><label>Responsável:</label>
   <input type="text" class="form-control form-control-sm" onChange={(e)=>{
    this.setState({Resp:e.target.value})
   }}/>
   </div>
   

   <div class="form-group mt-2"><label>Qualificação:</label>
   <input type="text" class="form-control form-control-sm" onChange={(e)=>{
    this.setState({Quali:e.target.value})
   }}/>
   </div>



   <div class="form-group mt-2">

  

<div class="form-group mt-2"><label>Cns:</label>
   <input type="text" class="form-control form-control-sm" onChange={(e)=>{
    this.setState({Cns:e.target.value})
   }}/>
   </div>
   </div>
</div>

<div class="card-footer">
<button type="submit" style={{background:"#0d6efd"}} class="btn btn-primary float-right ml-2" onClick={()=>{
    const id = toast.loading("Enviando...")
 
    fetch(`${apiUrl}${window.location.pathname}/adicionar-cartorio/`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        },
        body:JSON.stringify({
        nomeOfc:this.state.nomeOfc,
        Resp:this.state.Resp,
        Cma:this.state.Quali,
        Cns:this.state.Cns,
        })
      }).then(response => {
      if(response.status == 200){
       
        response.json().then(data=>{
            toast.update(id, { render:"cartório adicionado com sucesso!", type: "success", isLoading: false,autoClose: 3000});
            Modal.close()
            const newItem = {
              id:data.id,
              nome:this.state.nomeOfc,
              resp:this.state.Resp,
              Cma:this.state.Quali,
              Cns:this.state.Cns
            }
console.log(this.state)
            this.setState((prevState) => ({
                data: {
                  ...prevState.data,
                  cartorio: [...prevState.data.cartorio, newItem]
                }
              }));
       })
       



      }
      })
}}><i class="fas fa-download"></i> Salvar </button>
</div>
                    </>
                })
            }}>  <FontAwesomeIcon icon={faUniversity} /> Adicionar cartório </button></div>
               <div class="card-body">
                  <div>
                 
                  </div>
                  
                       
                       {this.state.data.cartorio.length > 0 ? <>
                     <table class="table table-sm table-bordered">
                     <thead>
                        <tr>
                           <th scope="col">#</th>
                           <th scope="col">Nome</th>
                           <th scope="col">Responsável</th>
                           <th scope="col">Qualificação</th>
                           <th></th>
                        </tr>
                     </thead>
                     <tbody>
                       {this.state.data.cartorio.map((item,index)=><>
                        <tr>
                           <td>{index + 1}</td>
                           <td>{item.nome}</td>
                           <td>{item.resp}</td>
                           <td>{item.Cma}</td>
                           <td>
                              <div class="btn-group btn-group-sm">
                            <button class="btn btn-info" onClick={()=>{
                              console.log(item)
                               this.setState({editar:item,Medit:true})
                               return;
                               
                                Modal.create({
                                    titulo:"Editar cartório",
                                    html:<>
                <div class="card-body">
                   <div class="form-group mt-2"><label>Nome do Ofício:</label>
                   <input type="text" class="form-control form-control-sm" ref={this.iptNome} /></div>
                 
                   <div class="form-group mt-2"><label>Responsável:</label>
                   <input type="text" class="form-control form-control-sm" ref={this.iptResp}/>
                   </div>

                   <div class="form-group mt-2"><label>Qualificação:</label>
                   <input type="text" class="form-control form-control-sm" ref={this.iptQuali}/>
                   </div>
                  

                   <div class="form-group mt-2"><label>Cns:</label>
                   <input type="text" class="form-control form-control-sm"  ref={this.iptCns}/>
                   </div>
                </div>
                
                <div class="card-footer">
                
                <button type="submit" style={{background:"#0d6efd"}} class="btn btn-primary float-right ml-2" onClick={()=>{
                    const id = toast.loading("Enviando...")
                   fetch(`${apiUrl}${window.location.pathname}/atualizar-cartorio/${item.id}`, {
                        method: 'PUT',
                        credentials: 'include',
                        headers: {
                          'Content-Type': 'application/json',
                          'Authorization': 'Bearer '+Cookies.get("auth_token"),
                        },
                        body:JSON.stringify({
                        nomeOfc:this.iptNome.current.value,
                        Resp:this.iptResp.current.value,
                   
                        Cns:this.iptCns.current.value
                        })
                      }).then(response => {
                      if(response.status == 200){
                       
                       response.json().then(data=>{

                        toast.update(id, { render:"Cartório atualizado com sucesso!", type: "success", isLoading: false,autoClose: 3000});
                      
                        this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              cartorio: prevState.data.cartorio.map(i =>
                                i.id === item.id ? { ...i,
                                nome: this.iptNome.current.value,
                                resp:this.iptResp.current.value,
                             
                                Cns:this.iptCns.current.value
                            } : i
                              )
                            }
                          }));
                        Modal.close()
                       })
                       
                
                
                
                      }
                      })
                }}><i class="fas fa-download"></i> Atualizar </button>
                </div>
                                    </>
                                 })

                                 this.iptNome.current.value = item.nome;
                                 this.iptResp.current.value = item.resp;
                               
                                 this.iptCns.current.value = item.Cns
                            }}>
                            <FontAwesomeIcon color='#fff' icon={faPen} />
                            </button>
                            <button  class="btn btn-danger" onClick={()=>{
                            Modal.create({
                                titulo:"Tem certeza?",
                                html:<><p>Após a remoção, não será possível recuperar o dado.</p>
                                <div class="card-footer"><button type="submit" style={{background:"#dc3545"}} class="btn btn-danger float-right ml-2" onClick={()=>{
                                 const id = toast.loading("Carregando...")
                                 fetch(`${apiUrl}${window.location.pathname}/remover-cartorio/${item.id}`, {
                                    method: 'DELETE',
                                    credentials: 'include',
                                    headers: {
                                      'Content-Type': 'application/json',
                                      'Authorization': 'Bearer '+Cookies.get("auth_token"),
                                    }
                                  }).then(response => {
                                  if(response.status == 200){
                                    toast.update(id, { render:"Cartório removido com sucesso!", type: "success", isLoading: false,autoClose: 3000});

                                    this.setState((prevState) => ({
                                        data: {
                                          ...prevState.data,
                                          cartorio: prevState.data.cartorio.filter(i => i.id !== item.id)
                                        }
                                      }));
                                  Modal.close()

                                  }
                                  })



                                }}><i class="fas fa-download"></i> Remover </button></div>
                                </>
                            })
                            }}>
                            <FontAwesomeIcon icon={faTrash} />
                            </button>
                            </div>
                           </td>
                        </tr>
                       </>)}
                       </tbody>
                  </table>
                       </> : <>
                       Não há dados cadastrados!
                       </>}
                        
                        
                      


                
               </div>
            </div>
         </div>
 
      </div>
   </div>

   <div class="py-12">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
         <div class="col-12">
            <div class="card">
<div class="card-header d-flex justify-content-between">


  <div className='area-buscas'>

  <Link class="btn btn-xs btn-primary-2" style={{background:"#dc3545",color:"#fff"}} to={`${window.location.pathname}/create`}>
  <FontAwesomeIcon icon={faPlus} /> Adicionar certidão
  </Link>

<input type="text" placeholder='Pesquisar:' style={{marginLeft:10}}/>
<button>
  <FontAwesomeIcon color='#fff' icon={faMagnifyingGlass}/>
</button>
</div>
</div>

               <div class="card-body">
                  <div class="row">
                     <div class="col-12">
                        <p>Lista de certidão</p>
                        <div>
                   
                        </div>
                        {this.state.data.certs.length > 0 ? <>
                           <table class="table table-sm table-bordered">
                           <thead>
                              <tr>
                                 <th scope="col">#</th>
                                 <th scope="col">nome</th>
                                 <th scope="col">Tipo de livro</th>
                              </tr>
                           </thead>
                           <tbody>

                           {this.state.data.certs.map((item,index)=><>
                           <tr>
                                 <td>{index + 1}</td>
                                 <td>{item.nome_pessoa}</td>
                                 <td>{item.tipo_de_livro}</td>
                                 <td>
                                    <div class="btn-group btn-group-sm">
                                    <Link class="btn btn-success" to={`/${window.location.pathname.split("/")[1]}/preview/${item.token}`}><FontAwesomeIcon color='#fff' icon={faEye} /></Link>
                                    <Link class="btn btn-info" to={`/${window.location.pathname.split("/")[1]}/edit/${item.token}`}><FontAwesomeIcon color='#fff' icon={faPen} /></Link>
                                    <button class="btn btn-danger" onClick={()=>{
                                         Modal.create({
                                          titulo:"Tem certeza?",
                                          html:<><p>Após a remoção, não será possível recuperar o dado.</p>
                                          <div class="card-footer"><button type="submit" style={{background:"#dc3545"}} class="btn btn-danger float-right ml-2" onClick={()=>{
                                           const id = toast.loading("Carregando...")
                                           fetch(`${apiUrl}${window.location.pathname}/remover-certificado/${item.token}`, {
                                              method: 'DELETE',
                                              credentials: 'include',
                                              headers: {
                                                'Content-Type': 'application/json',
                                                'Authorization': 'Bearer '+Cookies.get("auth_token"),
                                              }
                                            }).then(response => {
                                            if(response.status == 200){
                                              toast.update(id, { render:"Certificado removido com sucesso!", type: "success", isLoading: false,autoClose: 3000});
          
                                              this.setState((prevState) => ({
                                                  data: {
                                                    ...prevState.data,
                                                    certs: prevState.data.certs.filter(i => i.id !== item.id)
                                                  }
                                                }));
                                            Modal.close()
          
                                            }
                                            })
          
          
          
                                          }}><i class="fas fa-download"></i> Remover </button></div>
                                          </>
                                      })
                                    }}> <FontAwesomeIcon icon={faTrash} /></button></div>
                                 </td>
                              </tr>
                           </>)}
                              

                           </tbody>
                        </table>
                        </> : <>
                        Não há dados cadastrados!
                        </>}
                       
                     </div>
                  </div>
               </div>
               {pageNumbers.length > 0 && <>
  <nav className='page-nvgt'>
               <ul class="pagination">
               <li class="page-item" onClick={() => this.handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}><a class="page-link" href="#">
                        <FontAwesomeIcon icon={faAngleLeft}/>
                        </a></li>
                   
                    {pageNumbers.map(number => (
                      
                      <li class="page-item" key={number}
                      onClick={() => this.handlePageChange(number)}
                      className={number === currentPage ? 'active' : ''}><a class="page-link" href="#"> {number}</a></li>  
                      
                    
                    ))}
                    <li class="page-item" onClick={() => this.handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}><a class="page-link" href="#">
                           <FontAwesomeIcon icon={faAngleRight}/>
                        </a></li>
                   
                </ul>
        </nav>
</>}
            </div>
         </div>
      </div>
   </div>
</main>
<div id="modal-root"></div>
<ToastContainer/>
<StaticDialog
        isOpen={this.state.Medit}
        title="Editar"
        onAfterClose={(result) => {
         this.setState({Medit:false,editar:[]})
         
        }}
    >
    
<div style={{padding:15}}>

   <div class="form-group mt-2"><label>Nome do Ofício:</label>
   <input type="text" value={this.state.editar.nome || '' } name="nome" class="form-control form-control-sm" onChange={this.Edit}/></div>


   <div class="form-group mt-2"><label>Responsável:</label>
   <input type="text" class="form-control form-control-sm" value={this.state.editar.resp || ''} name="resp" onChange={this.Edit}/>
   </div>
   

   <div class="form-group mt-2"><label>Qualificação:</label>
   <input type="text" class="form-control form-control-sm" value={this.state.editar.Cma || ''} name="Cma" onChange={this.Edit}/>
   </div>

   <div class="form-group mt-2"><label>Cns:</label>
   <input type="text" class="form-control form-control-sm" value={this.state.editar.Cns || ''} name="Cns" onChange={this.Edit}/>
   </div>
   </div>
   <hr/>
   <div className='modal-button'>
<button className='btn btn-primary float-right ml-2' onClick={(e)=>{
e.target.classList.add('load')
var item = this.state.editar;
console.log(item)
fetch(`${apiUrl}${window.location.pathname}/atualizar-cartorio/${item.id}`, {
  method: 'PUT',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer '+Cookies.get("auth_token"),
  },
  body:JSON.stringify({
  nomeOfc:item.nome,
  Resp:item.resp,
  Cma:item.Cma,
  Cns:item.Cns,
  })
}).then(response => {
e.target.classList.remove('load')
if(response.status == 200){
 
 response.json().then(data=>{

  
  this.setState((prevState) => ({
      data: {
        ...prevState.data,
          cartorio: prevState.data.cartorio.map(i =>
          i.id === item.id ? { ...i,
          nome:item.nome,
          resp:item.resp,
          Cma:item.Cma,
          cns:item.Cns
      } : i
        )
      }
  }));

 this.setState({Medit:false,editar:[]})
 toast.success('Suceeso!')
 })
 



}
})
}}>
 <FontAwesomeIcon icon={faSave}/> Salvar
</button>
   </div>
</StaticDialog>
      </>
  
    );
  }
}

export default TjMg;