import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { apiUrl } from '../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft,faSave,faPlus} from '@fortawesome/free-solid-svg-icons';
import ReactInputMask from 'react-input-mask';
class CreateCertTjRj extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      docEmt: 'CERTIDÃO',
      participantes: [],
      nomeSer: '',
      tipoAto: 'Certidão de Central de Registro e Certidões de Outro Estado',
      tipoCertidao: '',
      numeroLivro: '',
      dataPratica: '',
      tipoCobranca: 'Com Cobrança',
      hipossuficiente: '',
      numeroFolha: '',
      numeroTermo: '',
      matriculaNacionalCnj: '',
      tipoLivroCnj: '',
      observacoes: '',
      nomeP: '',
      nmcP: '',
      dtnacP: '',
      docP: '',
      idtP: '',
      orgeP: '',
      estcP: '',
      ncldP: '',
      jdocP: '',
      opcs:[
        {value:'Selecione:'},
        {value:1,ad:'Data de nascimento'},
        {value:2,ad:'Data do casamento'},
        {value:3,ad:'Data do casamento'},
        {value:4,ad:'Data de óbito'},
        ]
    };
  }

  componentDidMount() {
    document.title = 'Emitir Certidão TjRj';
    fetch(`${apiUrl}/listar-cartorios/` + window.location.pathname.split('/')[1], {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Cookies.get('auth_token'),
      },
    }).then(response => {
      if (response.status === 200) {
        response.json().then(data => {
          this.setState({ data, Loader: false });
        });
      }
    });
  }

  handleSubmit = (event) => {
    
    if(this.state.nomeSer == undefined || this.state.nomeSer == ""){
        toast.error('Selecione um nome do oficio:');
        return false;
     
    }
    
if(this.state.participantes.length == 0){
    toast.error('Informe os participantes!');
    return false;
}


function isValidDateFormat(dateString) {
  const regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
  return regex.test(dateString);
}

//const errorIndex = this.state.participantes.findIndex(item => !isValidDateFormat(item.dtnacP));

if (isValidDateFormat(this.state.participantes[0].dtnacP) == false) {
  toast.warn("Informe a data do nascimento do participante")
  return false;
}




    const {
      nomeSer,
      tipoAto,
      tipoCertidao,
      numeroLivro,
      dataPratica,
      tipoCobranca,
      hipossuficiente,
      numeroFolha,
      numeroTermo,
      matriculaNacionalCnj,
      tipoLivroCnj,
      observacoes,
      dtmc,
      adc2,
      verificador,
      participantes
    } = this.state;

    const formData = {
      nomeSer,
      tipoAto,
      tipoCertidao,
      numeroLivro,
      dataPratica,
      tipoCobranca,
      hipossuficiente,
      numeroFolha,
      numeroTermo,
      matriculaNacionalCnj,
      tipoLivroCnj,
      observacoes,
      dtmc,
      adc2,
      verificador,
      participantes
    };

    fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/criar-certificado/`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Cookies.get('auth_token'),
      },
      body: JSON.stringify(formData),
    }).then(response => {
      if (response.status === 200) {
        response.json().then(data=>{
          this.props.navigate(`/${window.location.pathname.split("/")[1]}/preview/${data.token}`)

        })
      
      } else {
        toast.error('Erro ao salvar a certidão.');
      }
    });
  };

  handlePtcp = (e,index) =>{
    console.log(e)
    this.setState(prevState => {
      const participantes = [...prevState.participantes];
      participantes[index][e.target.name] = e.target.value;
      return { participantes };
    });
  }


  render() {
    if (this.state.Loader) {
      return (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      );
    }
    return (
      <>
        <header className="bg-white shadow">
          <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h2 className="font-semibold text-xl text-gray-800 leading-tight">
              Emitir Certidão TJRJ
            </h2>
          </div>
        </header>
        <main>
          <div className="py-12">
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="col-12">
        
                  <div className="card">
                    <div className="card-header">
                      <h3>Emissão de certidão:</h3>
                    </div>
                    <div className="card-body">
                      <div className="form-group">
                        <label>Nome do oficio:</label>
                        <select
                          className="form-control form-control-sm"
                          onChange={(e) => {
                            this.setState({ nomeSer: e.target.value });
                          }}
                        >
                          <option style={{ display: 'none' }}></option>
                          {this.state.data.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.nome}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      
                        <div className="col-12">
                          <div className="form-group mt-2">
                            <label>Tipo de Ato:</label>
                           
                             <input
                              className="form-control form-control-sm"
                              value={this.state.tipoAto}
                              disabled="true"
                           />
                          
                         
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group mt-2">
                            <label>Tipo de Certidão:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              value={this.state.tipoCertidao}
                              disabled="true"
                            />
                          </div>
                        </div>
                  
                        <div className="col-12">
                          <div className="form-group mt-2">
                            <label>Data da Prática:</label>
                            <input
                              type="date"
                              className="form-control form-control-sm"
                              value={this.state.dataPratica}
                              onChange={(e) =>
                                this.setState({ dataPratica: e.target.value })
                              }
                            />
                          </div>
                        </div>




                        <div className="col-12">


                        <div className="form-group mt-2">
                            <label>Tipo de Cobrança:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              value={this.state.tipoCobranca}
                              disabled="true"
                            />
                          </div>

                          <div className="form-group mt-2">
                            <label>Matricula Nacional CNJ:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              value="gerado automaticamente"
                              disabled="true"
                            />
                          </div>


                        </div>
                      
                      
                      
                        <div className="col-12">

                        <div className="form-group mt-2">
                            <label>Nº Livro:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              value={this.state.numeroLivro}
                              onChange={(e) =>
                                this.setState({ numeroLivro: e.target.value })
                              }
                            />
                          </div>


                        

                        </div>
                     
                      
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group mt-2">
                            <label>Nº Folha:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              value={this.state.numeroFolha}
                              onChange={(e) =>
                                this.setState({ numeroFolha: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group mt-2">
                            <label>Nº Termo:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              value={this.state.numeroTermo}
                              onChange={(e) =>
                                this.setState({ numeroTermo: e.target.value })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      
                        <div className="col-12">
                        
                        </div>
                        <div className="col-12">
                          <div className="form-group mt-2">
                            <label>Tipo de Livro CNJ:</label>
                           
                           <select className="form-control form-control-sm" 
                onChange={(e)=>{
                  const valorSelecionado = e.target.value;
                  const opcao = this.state.opcs.find(opc => opc.value === parseInt(valorSelecionado))
 
 
                  this.setState({ tipoLivroCnj: e.target.value,adc1:opcao.ad }) 

                           }}>
                         {this.state.opcs.map((item,index)=><>
                         <option>{item.value}</option>
                         </>)}
                           </select>
                           
                           
                          
                          </div>
                        </div>
                
                      <div className="form-group mt-2">
                        <label>Observação:</label>
                        <textarea
                          className="form-control form-control-sm"
                          value={this.state.observacoes}
                          onChange={(e) =>
                            this.setState({ observacoes: e.target.value })
                          }
                        />
                      </div>


  <div className="col-12">
                          <div className="form-group mt-2">
                            <label>Data:</label>
                            <input
                              type="date"
                              className="form-control form-control-sm"
                              onChange={(e)=>{
                                this.setState({adc2:e.target.value})
                              }}
                          
                            />
                          </div>
  </div>
  <div className="col-12">
                          <div className="form-group mt-2">
                            <label>Verificador:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              onChange={(e)=>{
                                this.setState({verificador:e.target.value})
                              }}
                          
                            />
                          </div>
  </div>

  <div className="form-group mt-2">
                        <label>Data de Transmissão:</label>
                       <ReactInputMask
                mask="99/99/9999 99:99:99"
                          className="form-control form-control-sm"
                          value={this.state.dtmc}
                          onChange={(e) =>
                            this.setState({ dtmc: e.target.value })
                          }
                        />
                      </div>               


{this.state.participantes.length > 0 ?<>
{this.state.participantes.map((item,index)=><>

  <div class="card" style={{marginTop:20}}>
   <div class="card-header">
      <h3>Participante {index+1}</h3>
   </div>
   <div class="card-body">
   <div className="row">
                      <div className="col-4">
                        <div className="form-group mt-2">
                          <label>Nome:</label>
                          <input type="text" className="form-control form-control-sm"
                         onChange={(e)=>this.handlePtcp(e,index)} name="nomeP" value={item.nomeP} />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group mt-2">
                          <label>Nomeclatura:</label>
                          <select class="form-control form-control-sm" name="nmcP" onChange={(e)=>this.handlePtcp(e,index)}>
                          {[
                          "Selecione:",
                          "231 - Pai",
                          "232 - Mãe",
                          "27 - Cônjuge",
                          "168 - Parte",
                          "230 - Nascido",
                          "28 - Falecido",
                          "0 - Não informado"
                          ].map((item)=><>
                          <option>{item}</option>
                          </>)}
                        </select>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group mt-2">
                          <label>Data Nascimento:</label>
                          <input type="date" className="form-control form-control-sm"
                        onChange={(e)=>this.handlePtcp(e,index)} name="dtnacP" value={item.dtnacP}  />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <div className="form-group mt-2">
                          <label>CPF / CNPJ:</label>
                          <input type="text" className="form-control form-control-sm" 
                       onChange={(e)=>this.handlePtcp(e,index)} name="docP" value={item.docP} />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group mt-2">
                          <label>Identidade:</label>
                          <input type="text" className="form-control form-control-sm" 
                          onChange={(e)=>this.handlePtcp(e,index)} name="idtP" value={item.idtP} />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group mt-2">
                          <label>Orgão Emissor:</label>
                          <input type="text" className="form-control form-control-sm" 
                           onChange={(e)=>this.handlePtcp(e,index)} name="orgeP" value={item.orgeP}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <div className="form-group mt-2">
                          <label>Estado Civil:</label>
                          <input type="text" className="form-control form-control-sm" 
                           onChange={(e)=>this.handlePtcp(e,index)} name="estcP" value={item.estcP}
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group mt-2">
                          <label>Nacionalidade:</label>
                          <input type="text" className="form-control form-control-sm" 
                           onChange={(e)=>this.handlePtcp(e,index)} name="ncldP" value={item.ncldP}
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group mt-2">
                          <label>Just. CPF/CNPJ:</label>
                          <input type="text" className="form-control form-control-sm" 
                           onChange={(e)=>this.handlePtcp(e,index)} name="jdocP" value={item.jdocP}
                          />
                        </div>
                      </div>
                    </div>
   </div>
</div>

</>)}
</> : <>


</>}







               
                    
                    <div className="p-2">
            
                      <button type="button" className="btn btn-primary" onClick={()=>{
                        
                        this.setState(prevState => ({
                          participantes: [...prevState.participantes,{}]
                        }));



                      }}>
                         <FontAwesomeIcon icon={faPlus} /> Adicionar participante
                      </button>
                    </div>
                    <div className="mt-4">
                 
                    </div>
                      
                    </div>
                    <div class="card-footer">
                    <button class="btn btn-danger" onClick={()=>{
                      this.props.navigate(-1)
                    }}> <FontAwesomeIcon icon={faArrowLeft} /> Voltar</button><button type="submit" class="btn btn-primary float-right ml-2" onClick={this.handleSubmit}> <FontAwesomeIcon icon={faSave} /> Salvar </button></div>
                  </div>
               
                <ToastContainer />
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default CreateCertTjRj;
