import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import "./e.css"
import logo from "../../../images/logoTjsp.png"
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faEye,faUniversity,faPen,faArrowLeft,faLink } from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from '../../../config';
import lg1 from "../../../images/funarpen.jpg"
import lg2 from "../../../images/logotj.png"
import dcmt from "../../../images/document.png"
class PreViewCertEs extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
     data:[],
     storage:{
        nomeSer:'',
        ato: 'CERTIDÃO',
        servico: '',
        emolumentos: '',
        funepj: '',
        fadespes: '',
        funemp: '',
        funcad: '',
        encargos: '',
        valor_referencia: '',
        protocolo_apontamento: '',
        livro: '',
        termo: '',
        folha: '',
        firma: ''
     }
    };
  }

ShowDate = () => {
  var date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
  
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${day}/${month}/${year} às ${hours}:${minutes}:${seconds}`;
  };
  formatDate(d) {
    const date = new Date(d);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Os meses começam do zero em JavaScript
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  }

  componentDidMount() {
    document.title = "Pré-visualização do certificado";
    var url  = window.location.href;
    const match = url.match(/preview\/(.*)$/);
    fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/preview/${match[1]}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({data,Loader:false})
})
}else if(response.status == 404){

    this.setState({error:true,Loader:false})
  }
      })
}

 
somarValoresReais(sub_certs) {
  let total = 0;

  sub_certs.forEach(cert => {
    Object.keys(cert).forEach(key => {
      if (typeof cert[key] === 'string' && cert[key].includes('R$')) {
        const value = parseFloat(cert[key].replace('R$', '').replace(',', '.').trim());
        total += value;
      }
    });
  });

  // Retorna o total formatado em reais
  return total;
}
sumReaisValues(data) {
  let total = 0;

  for (const key in data) {
    if (typeof data[key] === 'string' && data[key].startsWith('R$')) {
      const value = parseFloat(data[key].replace('R$', '').replace(',', '.').trim());
      total += value;
    }
  }

  return total; // Retorna o total com duas casas decimais
}
  render() {
      if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    if(this.state.error == true){
        return(<>
        <div class="p404">
         <div>
          <img src={dcmt}/>
         <p>Desculpe, Esse documento não foi encontrado.</p>
         <button type="submit" class="btn btn-primary float-left ml-2" onClick={()=>{
                    this.props.navigate(`/${window.location.pathname.split("/")[1]}`)
                 }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Início</button>
         </div>
       
        
        </div>
        </>)
      }
    
      var tjes = this.state.data.cert;
    var valorTotal =   this.sumReaisValues(tjes) + this.somarValoresReais(this.state.data.sub_certs)
    
      return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight">Pré-visualização do certificado</h2></div></header>
       
        <main>
            <div className="py-12">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                            <div class="card-header d-flex justify-content-between">
                                    <h3>Qrcode:</h3>
                                    <a href={this.state.data.link} target="_blank">
   <button class="btn btn-xs btn-primary">
  <FontAwesomeIcon color='#fff' icon={faLink} /> Acessar
  </button>
   </a>  
                                </div>
                                
                                <div className="card-body">
                                    <div className="flex items-center">
                                        <img src={this.state.data.QrCode} style={{width: "200px", height: "200px"}} width="200" height="200"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="card">
                           
                            <div class="card-header d-flex justify-content-between">
                            <h3>Dados da certidão:</h3>
   <Link to={`/${window.location.pathname.split("/")[1]}/edit/${this.state.data.token}`}>
   <button class="btn btn-xs btn-primary">
  <FontAwesomeIcon color='#fff' icon={faPen} /> Editar
  </button>
   </Link>                         

</div>
<div class="card-body">


<div id="geral">
    <table width="750px" border="0" cellspacing="0" cellpadding="0">
      <tbody>
        <tr>
          <td>
            <div className="Cabecalho">
              <table style={{ width: '100%', verticalAlign: 'middle' }}>
                <tbody>
                  <tr>
                    <td align="right" nowrap="nowrap" style={{ width: 'auto' }}></td>
                  </tr>
                  <tr>
                    <td align="center" style={{ width: 'auto', height: 'auto' }}>
                      <span id="ctl00_lblNomeSistema" className="labelSistema">
                        Consulta Pública do Selo Digital
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan="2" style={{ width: '750px' }} className="Menu"></td>
        </tr>
      </tbody>
    </table>
    <br />
    <div className="ContentPlaceHolder">
      <div style={{ paddingLeft: '10px', paddingTop: '5px' }}>
        <br />
        Nesta página é possível realizar a consulta de selos digitais utilizados em atos praticados nas serventias extra-judiciais.
        <br />
        <br />
        <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
          Notas:<br />
          1. A consulta de um determinado selo digital estará disponível até 48 horas após a prática do ato.<br />
          2. Os valores informados para cada ato são unitário.
        </span>
        <br />
        <br />
        <div style={{ border: 'solid 1px gray', width: '710px' }}>
          <table style={{ width: '710px' }}>
            <caption style={{ borderBottom: 'solid 1px gray', fontWeight: 'bold' }}>
              Detalhamento da Pesquisa
            </caption>
            <tbody>
              <tr>
                <td style={{ paddingLeft: '100px' }}>
                  Número do Selo: <strong>{tjes.token}</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Data da nota: {(tjes.created_at)}
                </td>
              </tr>
              <tr>
                <td style={{ paddingLeft: '100px' }}>
                  Serventia: {tjes.cns} {tjes.nome}
                </td>
              </tr>
            </tbody>
          </table>
          <table id="ctl00_ContentPlaceHolder1_dtlConsulta" cellSpacing="0" border="0" style={{ borderCollapse: 'collapse' }}>
            <tbody>
              <tr>
                <td>
                  <div style={{ width: '680px', padding: '5px', borderBottom: 'solid 1px black' }}>
                    <table>
                      <tbody>
                        <tr>
                          <td colSpan="4">
                            <span id="ctl00_ContentPlaceHolder1_dtlConsulta_ctl00_lblAtoCancelado"></span>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="4">
                            <span style={{ fontWeight: 'bold' }}>
                              ATO: {tjes.ato}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="4">
                            SERVIÇO: Registro Cívil de Pessoas Naturais 
                            <br />
                          </td>
                        </tr>
                        <tr style={{ fontWeight: 'bold' }}>
                          <td colSpan="2">Emolumentos:  R$ {tjes.emolumentos}</td>
                          <td>&nbsp;&nbsp;&nbsp;FUNEPJ:  {tjes.funepj}</td>
                          <td>&nbsp;&nbsp;&nbsp;FADESPES:  {tjes.fadespes}</td>
                        </tr>
                        <tr style={{ fontWeight: 'bold' }}>
                          <td colSpan="2">FUNEMP:  {tjes.funemp}</td>
                          <td>&nbsp;&nbsp;&nbsp;FUNCAD:  {tjes.funcad}</td>
                          <td>&nbsp;&nbsp;&nbsp;Encargos:  {tjes.encargos}</td>
                        </tr>
                        <tr>
                          <td colSpan="2">Valor de Referência: {tjes.valor_referencia}</td>
                          <td colSpan="2">&nbsp;&nbsp;&nbsp;Protocolo/Apontamento: {tjes.protocolo_apontamento}</td>
                        </tr>
                        <tr>
                          <td>Livro: {tjes.livro}</td>
                          <td>Termo: {tjes.termo}</td>
                          <td>&nbsp;&nbsp;&nbsp;Folha: {tjes.folha}</td>
                        </tr>
                        <tr>
                          <td colSpan="4">Firma: {tjes.firma}</td>
                        </tr>
                      </tbody>
                    </table>
                    
                  </div>
              
                </td>
                
              </tr>
              {this.state.data.sub_certs.map((item=><>
                <tr>
      <td>
        <div style={{ width: '680px', padding: '5px', borderBottom: 'solid 1px black' }}>
          <table>
            <tbody>
              <tr>
                <td colSpan="4">
                  <span id="ctl00_ContentPlaceHolder1_dtlConsulta_ctl01_lblAtoCancelado"></span>
                </td>
              </tr>
              <tr>
                <td colSpan="4">
                  <span style={{ fontWeight: 'bold' }}>
                    ATO: {item.ato}
                  </span>
                </td>
              </tr>
              <tr>
                <td colSpan="4">
                  SERVIÇO: Registro Civil de Pessoas Naturais<br />
                </td>
              </tr>
              <tr style={{ fontWeight: 'bold' }}>
                <td colSpan="2">Emolumentos: {item.emolumentos}</td>
                <td>&nbsp;&nbsp;&nbsp;FUNEPJ:  {item.funepj}</td>
                <td>&nbsp;&nbsp;&nbsp;FADESPES: {item.fadespes}</td>
              </tr>
              <tr style={{ fontWeight: 'bold' }}>
                <td colSpan="2">FUNEMP: {item.funemp}</td>
                <td>&nbsp;&nbsp;&nbsp;FUNCAD: {item.funcad}</td>
                <td>&nbsp;&nbsp;&nbsp;Encargos: {item.encargos}</td>
              </tr>
              <tr>
                <td colSpan="2">Valor de Referência: {tjes.valor_referencia}</td>
                <td colSpan="2">&nbsp;&nbsp;&nbsp;Protocolo/Apontamento: -</td>
              </tr>
              <tr>
                <td>Livro: {tjes.livro}</td>
                <td>Termo: {tjes.termo}</td>
                <td>&nbsp;&nbsp;&nbsp;Folha: {tjes.folha}</td>
              </tr>
              <tr>
                <td colSpan="4">Firma: -</td>
              </tr>
              <tr>
                <td colSpan="4"></td>
              </tr>
              <tr>
                <td colSpan="4"></td>
              </tr>
              <tr>
                <td colSpan="4"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </td>
    </tr>
   </>))}
              
            </tbody>
          </table>
          <br/>
          <span style={{ fontWeight: 'bold', paddingLeft: '5px' }}>
  Total Geral...: {valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
</span>
<br/>
<input type='button' class="btn-es" value="Nova Consulta"/>
       
        </div>
        <div>
          <br/>
          <span style={{ fontStyle: 'italic' }}>
  Consulta efetuada em {this.ShowDate()}
</span>
<br/>
<span style={{ fontStyle: 'italic' }}>
  Este documento não vale como certidão. Favor consultar a serventia informada.
</span>
        </div>
      </div>
    </div>
    <div style={{ width: '100%', height: '30px' }}></div>
    <div className="Rodape">
      <div className="conteudoRodape">
        <table>
          <tbody>
            <tr>
              <td>
               
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>


</div>
<div class="card-footer">
               <button type="submit" class="btn btn-danger float-left ml-2" onClick={()=>{
                  this.props.navigate(-1)
               }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Voltar</button>
               </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </main>

        
<ToastContainer/>
        </>
  
    );
  }
}

export default PreViewCertEs;