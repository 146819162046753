import React, { Component } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave,faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from '../../../config';
import lg1 from "../../../images/funarpen.jpg"
import lg2 from "../../../images/logotj.png"
import dcmt from "../../../images/document.png"
class EditarCertRs extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
    };
   
  }

  componentDidMount() {
    document.title = "Editar certificado";
    var url  = window.location.href;
    const match = url.match(/edit\/(.*)$/);
    fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/dados-certificado/${match[1]}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({data,Loader:false})
})
}else if(response.status == 404){

  this.setState({error:true,Loader:false})
}
      })
}

 


  render() {
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    if(this.state.error == true){
      return(<>
      <div class="p404">
       <div>
        <img src={dcmt}/>
       <p>Desculpe, Esse documento não foi encontrado.</p>
       <button type="submit" class="btn btn-primary float-left ml-2" onClick={()=>{
                  this.props.navigate(`/${window.location.pathname.split("/")[1]}`)
               }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Início</button>
       </div>
     
      
      </div>
      </>)
    }
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight">Editar Certidão TjRS </h2></div></header>
       
        <main>
      <div className="py-12">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="row">
            <div className="col-12">
         
                <div className="card">
                  <div className="card-header">
                    <h3>Editar certidão:</h3>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label>Nome da Serventia:</label>
                      <select className="form-control form-control-sm" onChange={(e)=>{
                         this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              certificado: {
                                ...prevState.data.certificado,
                                nomeSer: e.target.value
                              }
                            }
                          }));
                      }} value={this.state.data.certificado.nomeSer}>
                       {this.state.data.cartorios.map((item,index)=><>
                        <option value={item.id}>{item.nome}</option>
                       </>)}
                        
                       
                      </select>
                    </div>

                    <div className="form-group mt-2">
                      <label>Talão/Nota:</label>
                      <input type="text" className="form-control form-control-sm" onChange={(e)=>{
                         this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              certificado: {
                                ...prevState.data.certificado,
                                talao: e.target.value
                              }
                            }
                          }));
                      }}  value={this.state.data.certificado.talao} />
                    </div>

                    <div className="form-group mt-2">
                      <label>Emissão:</label>
                      <input type="text" className="form-control form-control-sm" onChange={(e)=>{
                         this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              certificado: {
                                ...prevState.data.certificado,
                                emss: e.target.value
                              }
                            }
                          }));
                      }}  value={this.state.data.certificado.emss} />
                    </div>

                    <div className="form-group mt-2">
                      <label>Cobrança:</label>
                      <input type="text" className="form-control form-control-sm" onChange={(e)=>{
                         this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              certificado: {
                                ...prevState.data.certificado,
                                cob: e.target.value
                              }
                            }
                          }));
                      }}  value={this.state.data.certificado.cob} />
                    </div>

                   

                    <div className="form-group mt-2">
                      <label>Tipo do ato:</label>
                      <select className="form-control form-control-sm" onChange={(e)=>{
                         this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              certificado: {
                                ...prevState.data.certificado,
                                docEmt: e.target.value
                              }
                            }
                          }));
                      }} value={this.state.data.certificado.docEmt}>
                      <option value="CERTIDÃO"> CERTIDÃO </option>
                      <option value="CERTIDÃO DE NASCIMENTO"> CERTIDÃO DE NASCIMENTO </option>
                      <option value="CERTIDÃO DE CASAMENTO"> CERTIDÃO DE CASAMENTO </option>
                      <option value="CERTIDÃO DE ÓBITO"> CERTIDÃO DE ÓBITO </option>
                      <option value="PROCURAÇÃO PÚBLICA"> PROCURAÇÃO PÚBLICA </option>
                      <option value="ESCRITURA PÚBLICA"> ESCRITURA PÚBLICA </option>
                      </select>
                    </div>
                   
                    <div className="form-group mt-2">
                      <label>Emonumento:</label>
                      <input type="text" className="form-control form-control-sm" onChange={(e)=>{
                         this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              certificado: {
                                ...prevState.data.certificado,
                                emn: e.target.value
                              }
                            }
                          }));
                      }}  value={this.state.data.certificado.emn} />
                    </div>
                  
                    <div className="form-group mt-2">
                      <label>Valor selo:</label>
                      <input type="text" className="form-control form-control-sm" onChange={(e)=>{
                         this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              certificado: {
                                ...prevState.data.certificado,
                                valor_selo: e.target.value
                              }
                            }
                          }));
                      }}  value={this.state.data.certificado.valor_selo} />
                    </div>
                  
                  </div>
                  <div className="card-footer">
                   

                  <button type="submit" class="btn btn-danger float-left ml-2" onClick={()=>{
                  this.props.navigate(-1)
               }}><FontAwesomeIcon icon={faArrowLeft} /> Voltar</button>



                    <button type="submit" className="btn btn-primary float-right ml-2" onClick={()=>{
                            var url  = window.location.href;
                            const match = url.match(/edit\/(.*)$/);
                            var body = this.state.data.certificado;
                            fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/atualizar-certificado/${match[1]}`, {
                            method: 'POST',
                            credentials: 'include',
                            headers: {
                              'Content-Type': 'application/json',
                              'Authorization': 'Bearer '+Cookies.get("auth_token"),
                            },
                            body:JSON.stringify({
                              nomeSer:body.nomeSer,
                              talao:body.talao,
                              emss:body.emss,
                              cob:body.cob,
                              docEmt:body.docEmt,
                              emn:body.emn,
                              valor_selo:body.valor_selo
                            })
                          }).then(response => {
                            if(response.status == 200){
                            response.json().then(data=>{

                              this.props.navigate(`/${window.location.pathname.split("/")[1]}/preview/${this.state.data.certificado.token}`)        
                            })
                                 }
                          })
                    }}>
               <FontAwesomeIcon icon={faSave} /> Salvar
                    </button>
                  </div>
                </div>
          
            </div>
          </div>
        </div>
      </div>
    </main>

        
<ToastContainer/>
        </>
  
    );
  }
}

export default EditarCertRs;