import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { apiUrl } from '../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave,faArrowLeft} from '@fortawesome/free-solid-svg-icons';
class CreateSeloFun extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
     tipo_env:'',
     name_raz:'',
     doc:''
    };
  }

  componentDidMount() {
    document.title = "Criar propriedade";
    fetch(`${apiUrl}/listar-cartorios/`+window.location.pathname.split("/")[1], {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({data,Loader:false})
})
}
      })
}

 


  render() {
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight"> Criae propriedade</h2></div></header>
        <main>
        <div class="py-12">
   <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="col-12">
       
            <div class="card">
               <div class="card-header">
                  <h3>PROPRIEDADES:</h3>
               </div>
               <div class="card-body">
        
             
                  <div class="form-group mt-2"><label>Tipo Envolvido:</label>
                  <input type="text" class="form-control form-control-sm" onChange={(e)=>{
                     this.setState({tipo_env:e.target.value})
                  }}/></div>
             
                  <div class="form-group mt-2"><label>nome ou Razão Social:</label>
                  <input type="text" class="form-control form-control-sm" onChange={(e)=>{
                     this.setState({name_raz:e.target.value})
                  }}/></div>
                  <div class="form-group mt-2"><label>CPF/CNPJ:</label>
                  <input type="text" class="form-control form-control-sm"  onChange={(e)=>{
                     this.setState({doc:e.target.value})
                  }}/></div>
                  
               </div>
               <div class="card-footer">
               <button type="submit" class="btn btn-danger float-left ml-2" onClick={()=>{
                  this.props.navigate(-1)
               }}>      <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Voltar</button>
                  
                  
                  <button type="submit" class="btn btn-primary float-right ml-2" onClick={()=>{
     
              
                const id = toast.loading("Enviando...")
                fetch(`${apiUrl}/funarpen/selos/create`, {
                  method: 'POST',
                  credentials: 'include',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+Cookies.get("auth_token"),
                  },
                  body:JSON.stringify({
                 dados:{
                 tipo_env:this.state.tipo_env,
                 name_raz:this.state.name_raz,
                 doc:this.state.doc,
                 ref:window.location.pathname.split("/")[3]

                 }
                  })
                }).then(response => {
if(response.status == 200){
   toast.update(id, { render:"Certificadi criado com sucesso!", type: "success", isLoading: false,autoClose: 3000});
   
   response.json().then(data=>{
this.props.navigate(`/${window.location.pathname.split("/")[1]}/selo/preview/${data.token}`)
   })
}
})

               }}>      <FontAwesomeIcon color='#fff' icon={faSave} /> Salvar </button></div>
            </div>
       
      </div>
   </div>
</div>
</main>
<ToastContainer/>
        </>
  
    );
  }
}

export default CreateSeloFun;