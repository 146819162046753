import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { apiUrl } from '../../../config';

class CreateCertTjSc extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
     docEmt:'CERTIDÃO',
     nomeServentia: '',
     nome: '',
     pessoa: '',
     profissao:'',
     nacionalidade: '',
     estadoCivil: '',
     sexo: '',
     docTipo: '',
     docNo: '',
     tipo: '',
     logradouro: '',
     numero: '',
     bairro: '',
     cidadeUf: '',
     cep: '',
     tipoTel:'',
     telDDD:'',
     telNmr:'',
     valor: '',
     emolumento:'',
     iss:'',
     dataHoraFinalizacao: '',
     dataHoraRecebimento: '',
     dataSolicitacao: '',
     nomeLivro: '',
     noLivro: '',
     paginaInicial: '',
     paginaFinal: '',
     dataRegistro: '',
     noAssento: '',
     obs: ''
    };
  }

  componentDidMount() {
    document.title = "Emitir Certidão TJSC ";
    fetch(`${apiUrl}/listar-cartorios/`+window.location.pathname.split("/")[1], {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({data,Loader:false})
})
}
      })
}

handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }



  render() {
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight"> Emitir Certidão TJSC  </h2></div></header>
        <main>
        <div class="py-12">
   <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="col-12">
       
            <div class="card">
               <div class="card-header">
                  <h3>Emissão de certidão:</h3>
               </div>
               <div class="card-body">
     
        <div className="card-body">
          <div className="form-group">
            <label>Nome da Serventia:</label>
            <select class="form-control form-control-sm" onChange={(e)=>{
                      this.setState({nomeServentia:e.target.value})
                     }}>
                     <option style={{display:'none'}}></option>
                        {this.state.data.map((item,index)=>{
                          
                            return(<>
                            <option value={item.id} onClick={()=>{
                              console.log(item)
                            }}>{item.nome}</option>
                            </>)
                        })}
                       
                     </select>
          </div>
          <div class="form-group mt-2">
                     <label>Tipo do ato:</label>
                     <select class="form-control form-control-sm"  onChange={(e)=>{
                      this.setState({docEmt:e.target.value})
                     }}>
                        
                        {[
    { value: "CERTIDÃO", label: "CERTIDÃO" },
    { value: "CERTIDÃO DE NASCIMENTO", label: "CERTIDÃO DE NASCIMENTO" },
    { value: "CERTIDÃO DE CASAMENTO", label: "CERTIDÃO DE CASAMENTO" },
    { value: "CERTIDÃO DE ÓBITO", label: "CERTIDÃO DE ÓBITO" },
    { value: "PROCURAÇÃO PÚBLICA", label: "PROCURAÇÃO PÚBLICA" },
    { value: "ESCRITURA PÚBLICA", label: "ESCRITURA PÚBLICA" },
    { value: "NASCIMENTO INTEIRO TEOR", label: "NASCIMENTO INTEIRO TEOR" },
    { value: "CASAMENTO BREVE RELATO", label: "CASAMENTO BREVE RELATO" },
    { value: "CASAMENTO INTEIRO TEOR", label: "CASAMENTO INTEIRO TEOR" },
    { value: "ÓBITO BREVE RELATO", label: "ÓBITO BREVE RELATO" },
    { value: "ÓBITO INTEIRO TEOR", label: "ÓBITO INTEIRO TEOR" },
    { value: "DECLARAÇÃO", label: "DECLARAÇÃO" },
    { value: "PROCURAÇÃO", label: "PROCURAÇÃO" },
].map((option, index) => (
   <option key={index} value={option.value}>
       {option.label}
   </option>
))}
                     </select>
                  </div>
          <div className="form-group mt-2">
            <label>Nome:</label>
            <input 
              type="text" 
              name="nome"
              value={this.state.nome}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>
          <div className="form-group mt-2">
            <label>Pessoa:</label>
            <input 
              type="text" 
              name="pessoa"
              value={this.state.pessoa}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>
          <div className="form-group mt-2">
            <label>Profissão:</label>
            <input 
              type="text" 
              name="profissao"
              value={this.state.profissao}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>
          <div className="form-group mt-2">
            <label>Nacionalidade:</label>
            <input 
              type="text" 
              name="nacionalidade"
              value={this.state.nacionalidade}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>
          <div className="form-group mt-2">
            <label>Estado Civil:</label>
            <input 
              type="text" 
              name="estadoCivil"
              value={this.state.estadoCivil}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>
          <div className="form-group mt-2">
            <label>Sexo:</label>
            <input 
              type="text" 
              name="sexo"
              value={this.state.sexo}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>
          <div className="form-group mt-2">
            <label>Doc. Tipo:</label>
            <input 
              type="text" 
              name="docTipo"
              value={this.state.docTipo}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>
          <div className="form-group mt-2">
            <label>Doc. No.:</label>
            <input 
              type="text" 
              name="docNo"
              value={this.state.docNo}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>
          <div className="form-group mt-2">
            <label>Tipo:</label>
            <input 
              type="text" 
              name="tipo"
              value={this.state.tipo}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>
          <div className="form-group mt-2">
            <label>Logradouro:</label>
            <input 
              type="text" 
              name="logradouro"
              value={this.state.logradouro}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>
          <div className="form-group mt-2">
            <label>Número:</label>
            <input 
              type="text" 
              name="numero"
              value={this.state.numero}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>
          <div className="form-group mt-2">
            <label>Bairro:</label>
            <input 
              type="text" 
              name="bairro"
              value={this.state.bairro}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>
          <div className="form-group mt-2">
            <label>Cidade/UF:</label>
            <input 
              type="text" 
              name="cidadeUf"
              value={this.state.cidadeUf}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>
          <div className="form-group mt-2">
            <label>CEP:</label>
            <input 
              type="text" 
              name="cep"
              value={this.state.cep}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>



          <div className="form-group mt-2">
            <label>Tipo telefone:</label>
            <input 
              type="text" 
              name="tipoTel"
              value={this.state.tipoTel}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>




          <div className="form-group mt-2">
            <label>DDD telefone:</label>
            <input 
              type="text" 
              name="telDDD"
              value={this.state.telDDD}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>




          <div className="form-group mt-2">
            <label>Número do  telefone:</label>
            <input 
              type="text" 
              name="telNmr"
              value={this.state.telNmr}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>


          <div className="form-group mt-2">
            <label>Valor:</label>
            <input 
              type="text" 
              name="valor"
              value={this.state.valor}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>
          <div className="form-group mt-2">
            <label>Emolumento:</label>
            <input 
              type="text" 
              name="emolumento"
              value={this.state.emolumento}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>
          <div className="form-group mt-2">
            <label>ISS:</label>
            <input 
              type="text" 
              name="emolumento"
              value={this.state.iss}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>
          <div className="form-group mt-2">
            <label>Data e hora da finalização do ato:</label>
            <input 
              type="text" 
              name="dataHoraFinalizacao"
              value={this.state.dataHoraFinalizacao}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>
          <div className="form-group mt-2">
            <label>Data e hora do recebimento do ato pelo TJSC:</label>
            <input 
              type="text" 
              name="dataHoraRecebimento"
              value={this.state.dataHoraRecebimento}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>
          <div className="form-group mt-2">
            <label>Data em que o ato foi solicitado:</label>
            <input 
              type="text" 
              name="dataSolicitacao"
              value={this.state.dataSolicitacao}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>
          <div className="form-group mt-2">
            <label>Nome do livro:</label>
            <input 
              type="text" 
              name="nomeLivro"
              value={this.state.nomeLivro}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>
          <div className="form-group mt-2">
            <label>No do Livro:</label>
            <input 
              type="text" 
              name="noLivro"
              value={this.state.noLivro}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>
          <div className="form-group mt-2">
            <label>Pagina inicial:</label>
            <input 
              type="text" 
              name="paginaInicial"
              value={this.state.paginaInicial}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>
          <div className="form-group mt-2">
            <label>Pagina Final:</label>
            <input 
              type="text" 
              name="paginaFinal"
              value={this.state.paginaFinal}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>
          <div className="form-group mt-2">
            <label>Data do Registro:</label>
            <input 
              type="text" 
              name="dataRegistro"
              value={this.state.dataRegistro}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>
          <div className="form-group mt-2">
            <label>No Assento:</label>
            <input 
              type="text" 
              name="noAssento"
              value={this.state.noAssento}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
            />
          </div>
          <div className="form-group mt-2">
            <label>Obs:</label>
            <textarea 
              name="obs"
              value={this.state.obs}
              onChange={this.handleChange}
              className="form-control form-control-sm" 
              cols="30" 
              rows="10"
            />
          </div>
          
        </div>
   
               </div>
               <div class="card-footer">
               <button type="submit" class="btn btn-danger float-left ml-2" onClick={()=>{
                  this.props.navigate(-1)
               }}>Voltar</button>
                  
               <button type="submit" class="btn btn-primary float-right ml-2" onClick={()=>{
     
                if(this.state.nomeServentia == undefined){
                  toast.error('Selecione o nome da Serventia!', {
                     position: "top-right",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     theme: "light",
                     });
                  return false;
                }
                if(this.state.nomeServentia == ""){
                  toast.error('Selecione o nome da Serventia!', {
                     position: "top-right",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     theme: "light",
                     });
                  return false;
                }
                const id = toast.loading("Enviando...")
                fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/criar-certificado/`, {
                  method: 'POST',
                  credentials: 'include',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+Cookies.get("auth_token"),
                  },
                  body: JSON.stringify(this.state),
                }).then(response => {
if(response.status == 200){
   toast.update(id, { render:"Certificado criado com sucesso!", type: "success", isLoading: false,autoClose: 3000});
   
   response.json().then(data=>{
this.props.navigate(`/${window.location.pathname.split("/")[1]}/preview/${data.token}`)
   })
}
})

               }}><i class="fas fa-download"></i> Salvar </button></div>





               
            </div>
       
      </div>
   </div>
</div>
</main>
<ToastContainer/>
        </>
  
    );
  }
}

export default CreateCertTjSc;