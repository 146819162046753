import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { apiUrl } from '../../config';


import parana from "../../../src/images/parana.png"
import sp from "../../../src/images/sp.jpeg"
import rj from "../../../src/images/rj.png"
import mg from "../../../src/images/mg.png"
import go from "../../../src/images/go.png"
import ap from "../../../src/images/ap.png"
import sc from "../../../src/images/sc.png"
import rs from "../../../src/images/rs.png"
import es from "../../../src/images/es.png"
class Logado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }



  componentDidMount() {
    document.title = "DashBoard - Consulta";
}

 



  render() {

    return (
     
      <>
      <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight"> DashBoard </h2></div></header>
      
     <div class="py-12 btns">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="col-12">
      <div class="row">
            
            
<div class="col-6 col-lg-3 col-md-6">
   
    <div class="card" onClick={()=>{
      this.props.navigate("/funarpen")
    }}>
        <div class="card-body px-4 py-4-5">
            <div class="row">
                <div class="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div class="stats-icon purple mb-2">
                        <img src={parana}  />
                    </div>
                </div>
                <div class="col-md-8 col-lg-12 col-xl-12 col-xxl-7 d-flex align-items-center">
                    <h6 class="text-muted font-semibold">Paraná</h6>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-6 col-lg-3 col-md-6">
    <div class="card" onClick={()=>{
      this.props.navigate("/tjsp")
    }}>
        <div class="card-body px-4 py-4-5">
            <div class="row">
                <div class="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div class="stats-icon purple mb-2">
                        <img src={sp}   />
                    </div>
                </div>
                <div class="col-md-8 col-lg-12 col-xl-12 col-xxl-7 d-flex align-items-center">
                    <h6 class="text-muted font-semibold">São Paulo</h6>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-6 col-lg-3 col-md-6">
    <div class="card" onClick={()=>{
      this.props.navigate("/tjrj")
    }}>
        <div class="card-body px-4 py-4-5">
            <div class="row">
                <div class="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div class="stats-icon purple mb-2">
                        <img src={rj}   />
                    </div>
                </div>
                <div class="col-md-8 col-lg-12 col-xl-12 col-xxl-7 d-flex align-items-center">
                    <h6 class="text-muted font-semibold">Rio De Janeiro</h6>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="col-6 col-lg-3 col-md-6">
    <div class="card" onClick={()=>{
      this.props.navigate("/tjmg")
    }}>
        <div class="card-body px-4 py-4-5">
            <div class="row">
                <div class="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div class="stats-icon purple mb-2">
                        <img src={mg}   />
                    </div>
                </div>
                <div class="col-md-8 col-lg-12 col-xl-12 col-xxl-7 d-flex align-items-center">
                    <h6 class="text-muted font-semibold">Minas Gerais</h6>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="py-12">
<div class="">
      <div class="col-12">
      <div class="row">

<div class="col-6 col-lg-3 col-md-6">
    <div class="card" onClick={()=>{
      this.props.navigate("/tjgo")
    }}>
        <div class="card-body px-4 py-4-5">
            <div class="row">
                <div class="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div class="stats-icon purple mb-2">
                        <img src={go}   />
                    </div>
                </div>
                <div class="col-md-8 col-lg-12 col-xl-12 col-xxl-7 d-flex align-items-center">
                    <h6 class="text-muted font-semibold">Goiás</h6>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="col-6 col-lg-3 col-md-6">
    <div class="card" onClick={()=>{
      this.props.navigate("/tjsc")
    }}>
        <div class="card-body px-4 py-4-5">
            <div class="row">
                <div class="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div class="stats-icon purple mb-2">
                        <img src={sc}   />
                    </div>
                </div>
                <div class="col-md-8 col-lg-12 col-xl-12 col-xxl-7 d-flex align-items-center">
                    <h6 class="text-muted font-semibold">Santa Catarina</h6>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-6 col-lg-3 col-md-6">
    <div class="card" onClick={()=>{
      this.props.navigate("/tjrs")
    }}>
        <div class="card-body px-4 py-4-5">
            <div class="row">
                <div class="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div class="stats-icon purple mb-2">
                        <img src={rs}   />
                    </div>
                </div>
                <div class="col-md-8 col-lg-12 col-xl-12 col-xxl-7 d-flex align-items-center">
                    <h6 class="text-muted font-semibold">Rio Grande Do Sul</h6>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="col-6 col-lg-3 col-md-6">
    <div class="card" onClick={()=>{
      this.props.navigate("/tjes")
    }}>
        <div class="card-body px-4 py-4-5">
            <div class="row">
                <div class="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div class="stats-icon purple mb-2">
                        <img src={es}   />
                    </div>
                </div>
                <div class="col-md-8 col-lg-12 col-xl-12 col-xxl-7 d-flex align-items-center">
                    <h6 class="text-muted font-semibold">Espírito Santo</h6>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="col-6 col-lg-3 col-md-6" style={{marginTop:'50px'}}>
    <div class="card" onClick={()=>{
      this.props.navigate("/apostil")
    }}>
        <div class="card-body px-4 py-4-5">
            <div class="row">
                <div class="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div class="stats-icon purple mb-2">
                        <img src={ap}   />
                    </div>
                </div>
                <div class="col-md-8 col-lg-12 col-xl-12 col-xxl-7 d-flex align-items-center">
                    <h6 class="text-muted font-semibold">Apostil</h6>
                </div>
            </div>
        </div>
    </div>
</div>

</div>


               
      </div>
      
      </div>
     </div>
     </div>
     </div>
     </div>
     </div>
     
      </>
  
    );
  }
}

export default Logado;