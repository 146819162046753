import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import "./g.css"
import logo from "../../../images/logoTjsp.png"
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faEye,faUniversity,faPen,faArrowLeft,faLink } from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from '../../../config';
import lg1 from "../../../images/funarpen.jpg"
import lg2 from "../../../images/logotj.png"
import dcmt from "../../../images/document.png"
class PreViewCertGo extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
    };
  }

  componentDidMount() {
    document.title = "Pré-visualização do certificado";
    var url  = window.location.href;
    const match = url.match(/preview\/(.*)$/);
    fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/preview/${match[1]}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({data,Loader:false})
})
}else if(response.status == 404){

  this.setState({error:true,Loader:false})
}
      })
}

 


  render() {
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    if(this.state.error == true){
      return(<>
      <div class="p404">
       <div>
        <img src={dcmt}/>
       <p>Desculpe, Esse documento não foi encontrado.</p>
       <button type="submit" class="btn btn-primary float-left ml-2" onClick={()=>{
                  this.props.navigate(`/${window.location.pathname.split("/")[1]}`)
               }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Início</button>
       </div>
     
      
      </div>
      </>)
    }
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight">Pré-visualização do certificado</h2></div></header>
       
        <main>
            <div className="py-12">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                            <div class="card-header d-flex justify-content-between">
                                    <h3>Qrcode:</h3>
                                    <a href={this.state.data.link} target="_blank">
   <button class="btn btn-xs btn-primary">
  <FontAwesomeIcon color='#fff' icon={faLink} /> Acessar
  </button>
   </a>  
                                </div>
                                <div className="card-body">
                                    <div className="flex items-center">
                                        <img src={this.state.data.QrCode} style={{width: "200px", height: "200px"}} width="200" height="200"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="card">
                           
                            <div class="card-header d-flex justify-content-between">
                            <h3>Dados da certidão:</h3>
   <Link to={`/${window.location.pathname.split("/")[1]}/edit/${this.state.data.token}`}>
   <button class="btn btn-xs btn-primary">
  <FontAwesomeIcon color='#fff' icon={faPen} /> Editar
  </button>
   </Link>                         

</div>
<div class="card-body">
<div class="panel panel-body panel-success">
    <div class="panel-heading">
      <h3 class="panel-title">Resultado da Busca: {this.state.data.token}</h3>
    </div>
    <div class="panel-body text-center table-responsive">
      <h4>O Selo Eletrônico <strong>{this.state.data.token}</strong> é válido e foi utilizado
       para a prática do seguinte  ato :</h4>

      <table class="table table-striped">
        <thead>
          <tr><th>Tipo de Ato Realizado</th>
          <th>Emolumento</th>
          <th>Taxa Judiciário</th>
          <th>Fundos Estaduais</th>
          <th>Código do Ato</th>
          <th>Data utilização</th>
        </tr></thead>
        <tbody>
             <tr class="">
    <td>
      {this.state.data.cert.docEmt}
    </td>
    <td>      {this.state.data.cert.emonumento}</td>
    <td>      {this.state.data.cert.taxa_j}</td>
    <td>      {this.state.data.cert.fundos_e}</td>
    <td><span class="text-semibold">{this.state.data.token}</span></td>
    <td>{this.state.data.cert.data_u}</td>
  </tr>

        </tbody>
      </table>
    </div>
    <div class="panel-footer">
      <h5>Atenção! O selo eletrônico é válido, porém somente para o  ato  listado acima, e para as pessoas a que se referem o ato.</h5>
    </div>
    <div className="row">
      <div className="col-md-6">
        <div className="panel panel-body panel-info">
          <div className="panel-heading">
            <h3 className="panel-title">Informações do Cartório</h3>
          </div>
          <div className="panel-body">
            <table className="table table-striped">
              <tbody>
                <tr>
                  <td className="text-bold">Cartorio que praticou o ato</td>
                  <td>
                    <a href="/serventias/138">
                    {this.state.data.cartorio.code} - {this.state.data.cartorio.nome}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="text-bold">Nome do serventuário que praticou o ato:</td>
                  <td>{this.state.data.cert.resp}</td>
                </tr>
                <tr>
                  <td className="text-bold">Cidade:</td>
                  <td>{this.state.data.cartorio.cidade}</td>
                </tr>
                <tr>
                  <td className="text-bold">Comarca:</td>
                  <td>{this.state.data.cartorio.cidade}</td>
                </tr>
                <tr>
                  <td className="text-bold">Endereço</td>
                  <td>
                  {this.state.data.cartorio.edrc}
                  </td>
                </tr>
                <tr>
                  <td className="text-bold">Telefone</td>
                  <td>{this.state.data.cartorio.telefone}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="panel panel-body panel-info">
          <div className="panel-heading">
            <h3 className="panel-title">Civil do Ato</h3>
          </div>
          <div className="panel-body">
            <h4>{this.state.data.cert.civilato}</h4>
          </div>
        </div>
      </div>
    </div>
</div>

</div>
<div class="card-footer">
               <button type="submit" class="btn btn-danger float-left ml-2" onClick={()=>{
                  this.props.navigate(-1)
               }}>  <FontAwesomeIcon color='#fff' icon={faArrowLeft} />  Voltar</button>
               </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </main>

        
<ToastContainer/>
        </>
  
    );
  }
}

export default PreViewCertGo;