import React, { Component } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft,faSave } from '@fortawesome/free-solid-svg-icons';
import dcmt from "../../../images/undraw_Folder_files_re_2cbm.png"
import { apiUrl } from '../../../config';
class EditApostil extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
    };
   
  }

  componentDidMount() {
    document.title = "Editar apostil";
    var url  = window.location.href;
    const match = url.match(/edit\/(.*)$/);
    fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/dados/${match[1]}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({data,Loader:false})
})
}else if(response.status == 404){

  this.setState({error:true,Loader:false})
}
      })
}

 


  render() {
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    if(this.state.error == true){
      return(<>
      <div class="p404">
       <div>
        <img src={dcmt}/>
       <p>Desculpe, Essa apostilha não foi encontrada.</p>
       <button type="submit" class="btn btn-primary float-left ml-2" onClick={()=>{
                  this.props.navigate(`/${window.location.pathname.split("/")[1]}`)
               }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Início</button>
       </div>
     
      
      </div>
      </>)
    }
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight">Editar apostil </h2></div></header>
       
        <main>
      <div className="py-12">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="row">
            <div className="col-12">
         
                <div className="card">
                  <div className="card-header">
                    <h3>Editar apostil:</h3>
                  </div>
                  <div class="card-body">
                
                <div class="row">
             <div class="col-md-8">
                 <div class="form-group">
                     <label for="input1">Data e hora (dm/yyy) h:m</label>
                     <input type="text" class="form-control" onChange={(e)=>{
                        this.setState((prevState) => ({
                            data: {
                                ...prevState.data,
                                data: e.target.value
                              
                            }
                          }));
                     }} value={this.state.data.data}/>
                 </div>
             </div>
             <div class="col-md-4">
                 <div class="form-group">
                     <label for="input2">ano: (yy)</label>
                     <input type="text" class="form-control" onChange={(e)=>{
                       this.setState((prevState) => ({
                        data: {
                            ...prevState.data,
                            ano: e.target.value
                          
                        }
                      }));
                     }} value={this.state.data.ano}/>
                 </div>
             </div>
         </div>
                   
         <div class="form-group mt-2"><label>Foi assinado por:</label>
                   <input type="text" class="form-control form-control-sm" onChange={(e)=>{
                  this.setState((prevState) => ({
                    data: {
                        ...prevState.data,
                        assn: e.target.value
                      
                    }
                  }));
                   }} value={this.state.data.assn}/></div>   
 
 
 <div class="form-group mt-2"><label>Na qualidade de :</label>
                   <input type="text" class="form-control form-control-sm" onChange={(e)=>{
             this.setState((prevState) => ({
                data: {
                    ...prevState.data,
                    qld: e.target.value
                  
                }
              }));
                   }} value={this.state.data.qld}/></div>  
                   
 
                   <div class="form-group mt-2"><label>Tem o selo / carimbo de:</label>
                   <input type="text" class="form-control form-control-sm" onChange={(e)=>{
                     this.setState((prevState) => ({
                        data: {
                            ...prevState.data,
                            tems: e.target.value
                          
                        }
                      }));
                   }} value={this.state.data.tems}/></div>  
 
 <p/>
  <div class="form-group">
                      <label>Em:</label>
                   
                      <select class="form-control form-control-sm" onChange={(e)=>{
                       this.setState((prevState) => ({
                        data: {
                            ...prevState.data,
                            slc1: e.target.value
                          
                        }
                      }));
                      }} value={this.state.data.slc1}>
                      <option style={{display:'none'}}></option>
                        
                      <option value="1">Brasília - Marco Antônio Barreto de Azeredo Bastos Junior</option>
  <option value="2">Rio de Janeiro - Washington Luiz Ribeiro Moreira</option>
  <option value="3">Taguatinga - KALVIN BRANDON DE OLIVEIRA</option>
  <option value="4">Curitiba - LAIRTON ROCHA RESENDE</option>
  <option value="5">Curitiba - GESSICA PEREIRA DA CRUZ</option>
  <option value="6">Curitiba - BIANCA DA ROCHA GRACIANO</option>
  <option value="7">GOIANIA - LEONARDO NERES COELHO DE MEDEIROS</option>
  <option value="8">PALMAS - DANIELY APARECIDA DE OLIVEIRA</option>
  <option value="9">CRICIUMA - CLAUDIA REGINA BADAM GOMES</option>
  <option value="10">BETIM - JOÃO BATISTA LARA</option>
  <option value="11">BELO HORIZONTE - BRUNO HENRIQUE VEIRA DA SILVA</option>
  <option value="12">IPATINGA - EMILIANO ROQUE FERRARI</option>
  <option value="13">JANDIRA - HENRIQUE VICENTE DE LIMA</option>
  <option value="14">ARARAQUARA - JOSÉ JANONE</option>
  <option value="15">IMPERATRIZ - KAMILY BORSI BARROS</option>
  <option value="16">UBERLANDIA - ANA PAULA CINTRA DE OLIVEIRA</option>
  <option value="17">OSASCO - DIOGO FERNANDO SIMPLICIO DA SILVA</option>
  <option value="18">BARUERI - ROSINEIS ANTONIA DE SOUZA SILVA</option>
  <option value="19">MACEIÓ - REINALDO CAVALCANTE MOURA</option>
  <option value="20">SÃO PAULO - RENAN CARLOS DE OLIVEIRA</option>
  <option value="21">ALAGOA GRANDE - GETULIO RAMOS DE OLIVEIRA</option>
  <option value="22">MACEIÓ - REINALDO CAVALCANTE MOURA</option>
  <option value="23">PALMAS - DANIKELLE SOUSA DIAS</option>
  <option value="24">PORTO ALEGRE - LEONOR CRISTINA REBESCHINI GROLLI</option>
  <option value="25">ALAGOA GRANDE - GETULIO RAMOS DE OLIVEIRA FILHO</option>
  <option value="26">RECIFE - LOURIVAL BRITO PEREIRA</option>
  <option value="27">SÃO PAULO - AMILTON NAVARRO</option>
  <option value="28">EMBU - ANDREA APARECIDA DA SILVA</option>
  <option value="29">SÃO PAULO - MARCUS FELIPE OLIVEIRA SANTANA</option>
                      </select>
                   </div>
 <p/>
                   <div class="form-group">
                      <label>Tipo de documento:</label>
                   
                      <select class="form-control form-control-sm" onChange={(e)=>{
                       this.setState((prevState) => ({
                        data: {
                            ...prevState.data,
                            slc2: e.target.value
                          
                        }
                      }));
                      }} value={this.state.data.slc2}>
                      <option style={{display:'none'}}></option>
                      <option value="1">Auto de Divórcio Consensual</option>
 <option value="2">Certidão de Casamento - de Inteiro Teor</option>
 <option value="3">Certidão de Nascimento - de Inteiro Teor</option>
 <option value="4">Certidão de Óbito - de Inteiro Teor</option>
 <option value="5">Certidão Negativa de Naturalização e sua Validação</option>
 <option value="6">Escritura Pública - de Certidão de Declaratória</option>
 <option value="7">Escritura Pública - de Certidão de Divórcio</option>
 <option value="8">Escritura Pública - de Reconhecimento de Maternidade</option>
 <option value="9">Escritura Pública - Declaratória de União Estável</option>
 <option value="10">Sentença</option>
 <option value="11">Certidão</option>
 <option value="12">Certidão Negativa</option>
 <option value="13">Certidão de Nascimento - Breve Relato</option>
 <option value="14">Certidão de Casamento - Breve Relato</option>
 <option value="15">Certidão de Óbito - Breve Relato</option>
 <option value="16">Declaração</option>
                        
                      </select>
                   </div>
 
                   <div class="form-group mt-2"><label>Nome titular:</label>
                   <input type="text" class="form-control form-control-sm" onChange={(e)=>{
                      this.setState((prevState) => ({
                        data: {
                            ...prevState.data,
                            nomet: e.target.value
                          
                        }
                      }));
                   }} value={this.state.data.nomet}/></div> 
                   <p/>

                </div>
                  <div className="card-footer">
                   

                  <button type="submit" class="btn btn-danger float-left ml-2" onClick={()=>{
                  this.props.navigate(-1)
               }}><FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Voltar</button>



                    <button type="submit" className="btn btn-primary float-right ml-2" onClick={()=>{
                            var url  = window.location.href;
                            const match = url.match(/edit\/(.*)$/);
                        console.log(this.state.data)
                            fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/atualizar/${match[1]}`, {
                            method: 'POST',
                            credentials: 'include',
                            headers: {
                              'Content-Type': 'application/json',
                              'Authorization': 'Bearer '+Cookies.get("auth_token"),
                            },
                            body:JSON.stringify({
                            data:this.state.data.data,
                            ano:this.state.data.ano,
                            assn:this.state.data.assn,
                            qld:this.state.data.qld,
                            tems:this.state.tems,
                            slc1:this.state.data.slc1,
                            slc2:this.state.data.slc2,
                            nomet:this.state.data.nomet
                            })
                          }).then(response => {
                            if(response.status == 200){
                            response.json().then(data=>{
                              this.props.navigate(`/${window.location.pathname.split("/")[1]}/preview/${this.state.data.token}`)        
                            })
                                 }
                          })
                    }}>
                     <FontAwesomeIcon color='#fff' icon={faSave} /> Salvar
                    </button>
                  </div>
                </div>
          
            </div>
          </div>
        </div>
      </div>
    </main>

        
<ToastContainer/>
        </>
  
    );
  }
}

export default EditApostil;