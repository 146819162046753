import React, { Component } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, NavLink } from 'react-router-dom';
import { apiUrl } from '../../../config';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft,faSave } from '@fortawesome/free-solid-svg-icons';
class CreateApostil extends Component {
  constructor(props) {
    super(props);
    this.state = {
  Loader:true,
  selectedFile:null,
  data:[],
  dia:'',
  ano:'',
  assn:'',
  qld:'',
  tems:'',
  slc1:'',
  slc2:'',
  nomet:''

    };
  }

componentDidMount(){
    document.title = "Criação de apostil"
    fetch(`${apiUrl}/listar-cartorios/`+window.location.pathname.split("/")[1], {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+Cookies.get("auth_token"),
      }
    }).then(response => {
if(response.status == 200){
response.json().then(data=>{
  this.setState({data,Loader:false})
})
}
    })
}


handleFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  }

  render() {
    
    return (
     
      <>

<header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight">Criação de apostil </h2></div></header>
<main>
        <div class="py-12">
   <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="col-12">
       
            <div class="card">
               <div class="card-header">
                  <h3>Dados:</h3>
               </div>
               <div class="card-body">
                
               <div class="form-group">
                     <label>Nome da Serventia:</label>
                  
                     <select class="form-control form-control-sm" onChange={(e)=>{
                      this.setState({nomeSer:e.target.value})
                     }}>
                     <option style={{display:'none'}}></option>
                        {this.state.data.map((item,index)=>{
                          
                            return(<>
                            <option value={item.id}>{item.nome}</option>
                            </>)
                        })}
                       
                     </select>
                  </div>
                  <br></br>
               <div class="row">
         
            <div class="col-md-8">
          
                <div class="form-group">
                    <label for="input1">Data e hora (dm/yyy) h:m</label>
                    <input type="text" class="form-control" onChange={(e)=>{
                        this.setState({dia:e.target.value})
                    }}/>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="input2">ano: (yy)</label>
                    <input type="text" class="form-control" onChange={(e)=>{
                        this.setState({ano:e.target.value})
                    }} />
                </div>
            </div>
        </div>
                  
        <div class="form-group mt-2"><label>Foi assinado por:</label>
                  <input type="text" class="form-control form-control-sm" onChange={(e)=>{
                     this.setState({assn:e.target.value})
                  }}/></div>   


<div class="form-group mt-2"><label>Na qualidade de :</label>
                  <input type="text" class="form-control form-control-sm" onChange={(e)=>{
                     this.setState({qld:e.target.value})
                  }}/></div>  
                  

                  <div class="form-group mt-2"><label>Tem o selo / carimbo de:</label>
                  <input type="text" class="form-control form-control-sm" onChange={(e)=>{
                     this.setState({tems:e.target.value})
                  }}/></div>  

<p/>
 <div class="form-group">
                     <label>Em:</label>
                  
                     <select class="form-control form-control-sm" onChange={(e)=>{
                      this.setState({slc1:e.target.value})
                     }}>
                     <option style={{display:'none'}}></option>
                       
                     <option value="1">Brasília - Marco Antônio Barreto de Azeredo Bastos Junior</option>
 <option value="2">Rio de Janeiro - Washington Luiz Ribeiro Moreira</option>
 <option value="3">Taguatinga - KALVIN BRANDON DE OLIVEIRA</option>
 <option value="4">Curitiba - LAIRTON ROCHA RESENDE</option>
 <option value="5">Curitiba - GESSICA PEREIRA DA CRUZ</option>
 <option value="6">Curitiba - BIANCA DA ROCHA GRACIANO</option>
 <option value="7">GOIANIA - LEONARDO NERES COELHO DE MEDEIROS</option>
 <option value="8">PALMAS - DANIELY APARECIDA DE OLIVEIRA</option>
 <option value="9">CRICIUMA - CLAUDIA REGINA BADAM GOMES</option>
 <option value="10">BETIM - JOÃO BATISTA LARA</option>
 <option value="11">BELO HORIZONTE - BRUNO HENRIQUE VEIRA DA SILVA</option>
 <option value="12">IPATINGA - EMILIANO ROQUE FERRARI</option>
 <option value="13">JANDIRA - HENRIQUE VICENTE DE LIMA</option>
 <option value="14">ARARAQUARA - JOSÉ JANONE</option>
 <option value="15">IMPERATRIZ - KAMILY BORSI BARROS</option>
 <option value="16">UBERLANDIA - ANA PAULA CINTRA DE OLIVEIRA</option>
 <option value="17">OSASCO - DIOGO FERNANDO SIMPLICIO DA SILVA</option>
 <option value="18">BARUERI - ROSINEIS ANTONIA DE SOUZA SILVA</option>
 <option value="19">MACEIÓ - REINALDO CAVALCANTE MOURA</option>
 <option value="20">SÃO PAULO - RENAN CARLOS DE OLIVEIRA</option>
 <option value="21">ALAGOA GRANDE - GETULIO RAMOS DE OLIVEIRA</option>
 <option value="22">MACEIÓ - REINALDO CAVALCANTE MOURA</option>
 <option value="23">PALMAS - DANIKELLE SOUSA DIAS</option>
 <option value="24">PORTO ALEGRE - LEONOR CRISTINA REBESCHINI GROLLI</option>
 <option value="25">ALAGOA GRANDE - GETULIO RAMOS DE OLIVEIRA FILHO</option>
 <option value="26">RECIFE - LOURIVAL BRITO PEREIRA</option>
 <option value="27">SÃO PAULO - AMILTON NAVARRO</option>
 <option value="28">EMBU - ANDREA APARECIDA DA SILVA</option>
 <option value="29">SÃO PAULO - MARCUS FELIPE OLIVEIRA SANTANA</option>
                     </select>
                  </div>
<p/>
                  <div class="form-group">
                     <label>Tipo de documento:</label>
                  
                     <select class="form-control form-control-sm" onChange={(e)=>{
                      this.setState({slc2:e.target.value})
                     }}>
                     <option style={{display:'none'}}></option>
                     <option value="1">Auto de Divórcio Consensual</option>
<option value="2">Certidão de Casamento - de Inteiro Teor</option>
<option value="3">Certidão de Nascimento - de Inteiro Teor</option>
<option value="4">Certidão de Óbito - de Inteiro Teor</option>
<option value="5">Certidão Negativa de Naturalização e sua Validação</option>
<option value="6">Escritura Pública - de Certidão de Declaratória</option>
<option value="7">Escritura Pública - de Certidão de Divórcio</option>
<option value="8">Escritura Pública - de Reconhecimento de Maternidade</option>
<option value="9">Escritura Pública - Declaratória de União Estável</option>
<option value="10">Sentença</option>
<option value="11">Certidão</option>
<option value="12">Certidão Negativa</option>
<option value="13">Certidão de Nascimento - Breve Relato</option>
<option value="14">Certidão de Casamento - Breve Relato</option>
<option value="15">Certidão de Óbito - Breve Relato</option>
<option value="16">Declaração</option>
                       
                     </select>
                  </div>

                  <div class="form-group mt-2"><label>Nome titular:</label>
                  <input type="text" class="form-control form-control-sm" onChange={(e)=>{
                     this.setState({nomet:e.target.value})
                  }}/></div> 
                  <p/>
<div class="mb-3">
  <label for="formFile" class="form-label">Documento - Anexo:</label>
  <input class="form-control" type="file" onChange={this.handleFileChange} />
</div>
               </div>
               <div class="card-footer">
               <button type="submit" class="btn btn-danger float-left ml-2" onClick={()=>{
                  this.props.navigate(-1)
               }}><FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Voltar</button>
                  
               <button type="submit" class="btn btn-primary float-right ml-2" onClick={()=>{
     
     
     if(this.state.nomeSer == undefined){
      toast.error('Selecione um nome de serventia!', {
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "light",
         });
      return false;
    }
    if(this.state.nomeSer == ""){
      toast.error('Selecione um nome de serventia!', {
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "light",
         });
      return false;
    }
     
     
     if(this.state.selectedFile == null){
        toast.error("Selecione um arquivo!")
        return false;
    }
    const id = toast.loading("Enviando...")
    const formData = new FormData();
    formData.append('nomeSer',this.state.nomeSer)
    formData.append('data',this.state.dia)
    formData.append('ano',this.state.ano)
    formData.append('assn',this.state.assn)
    formData.append('qld',this.state.qld)
    formData.append('tems',this.state.tems)
    formData.append('slc1',this.state.slc1)
    formData.append('slc2',this.state.slc2)
    formData.append('nomet',this.state.nomet)
    formData.append('file', this.state.selectedFile);
    fetch(`${apiUrl}/apostil/upload`, {
        method: 'POST',
        body: formData
      })
      .then(response => {
if(response.status == 200){

   response.json().then(data=>{
      this.props.navigate(`/${window.location.pathname.split("/")[1]}/preview/${data.token}`)
   })
}

      })    
               }}><FontAwesomeIcon color='#fff' icon={faSave} /> Salvar </button></div>
            </div>
       
      </div>
   </div>
</div>
</main>
<ToastContainer/>
      </>
  
    );
  }
}

export default CreateApostil;