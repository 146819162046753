import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import "../s.css"
import logo from "../../../images/logoTjsp.png"
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faEye,faUniversity,faPen,faArrowLeft,faLink } from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from '../../../config';
import lg1 from "../../../images/funarpen.jpg"
import lg2 from "../../../images/logotj.png"
import dcmt from "../../../images/document.png"
class PreViewCertSc extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
    };
  }

  componentDidMount() {
    document.title = "Pré-visualização do certificado";
    var url  = window.location.href;
    const match = url.match(/preview\/(.*)$/);
    fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/preview/${match[1]}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({data,Loader:false})
})
}else if(response.status == 404){

  this.setState({error:true,Loader:false})
}
      })
}

 


  render() {
    var body = this.state.data;
  
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    if(this.state.error == true){
      return(<>
      <div class="p404">
       <div>
        <img src={dcmt}/>
       <p>Desculpe, Esse documento não foi encontrado.</p>
       <button type="submit" class="btn btn-primary float-left ml-2" onClick={()=>{
                  this.props.navigate(`/${window.location.pathname.split("/")[1]}`)
               }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Início</button>
       </div>
     
      
      </div>
      </>)
    }
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight">Pré-visualização do certificado</h2></div></header>
       
        <main>
            <div className="py-12">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                            <div class="card-header d-flex justify-content-between">
                                    <h3>Qrcode:</h3>
                                    <a href={this.state.data.link} target="_blank">
   <button class="btn btn-xs btn-primary">
  <FontAwesomeIcon color='#fff' icon={faLink} /> Acessar
  </button>
   </a>  
                                </div>
                                <div className="card-body">
                                    <div className="flex items-center">
                                        <img src={this.state.data.QrCode} style={{width: "200px", height: "200px"}} width="200" height="200"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="card">
                           
                        <div class="card-header d-flex justify-content-between">
                            <h3>Dados da certidão:</h3>
   <Link to={`/${window.location.pathname.split("/")[1]}/edit/${this.state.data.token}`}>
   <button class="btn btn-xs btn-primary">
  <FontAwesomeIcon color='#fff' icon={faPen} /> Editar
  </button>
   </Link>                         

</div>
<div className="card-body">
<div id="central">
      <div id="marca_selo">
        <a href="https://selo.tjsc.jus.br/index.html">
          <img
            src="https://selo.tjsc.jus.br/html/imagens/marca_selo_digital.png"
            width="235"
            height="56"
            alt="Selo Digital"
          />
        </a>
      </div>
      <div>
        <i>
          Caso as informações abaixo não confiram com as apresentadas no ato
          consultado, favor <b>dirigir-se à serventia extrajudicial que o elaborou</b>.
          Caso não seja possível, encaminhar uma comunicação via{' '}
          <a
            href="https://cgjweb.tjsc.jus.br/atendimento/extrajudicial/formulario.action"
            target="_blank"
            rel="noopener noreferrer"
          >
            Central de Atendimento Eletrônico - Foro Extrajudicial
          </a>{' '}
          para CGJ - Assessoria Extrajudicial.
        </i>
      </div>
      <div style={{ fontWeight: 'bold', fontStyle: 'italic', fontSize: '16px' }}>
        Atenção: Dados para simples conferência. A prática de atos jurídicos apenas terá validade com a apresentação do documento original entregue pelo cartório que o emitiu.
      </div>
      <p> </p>
      <table width="100%" className="cgj2">
        <tbody>
          <tr>
            <th colSpan="4" className="cgj2">
              <h3>Certidão de Inteiro Teor - Registro Civil</h3>
            </th>
          </tr>
          <tr>
            <td colSpan="4">
              <b>Serventia: </b>{body.cert.nome}
            </td>
          </tr>
          <tr>
            <td>
              <b>Endereço: </b>{body.cert.edrc}
            </td>
            <td>
              <b>Bairro: </b>{body.cert.bairro}
            </td>
            <td>
              <b>Município/UF: </b>{body.cert.municipio}
            </td>
            <td>
              <b>Telefone(s): </b> {body.cert.telefone}
            </td>
          </tr>
          <tr>
            <td>
              <b>E-mail: </b>
              <a href={`mailto:cartorio@${body.cert.email}`}>{body.cert.email}</a>
            </td>
            <td colSpan="3">
              <b>Cobrança: </b>Normal
            </td>
          </tr>
          <tr>
            <td>
              <b>Emolumento principal (ato): </b>{body.cert.emolumento}
            </td>
            <td>
              <b>FRJ: </b>
            </td>
            <td>
              <b>ISS: </b>{body.cert.iss}
            </td>
            <td>
              <b>Valor: </b>{body.cert.valor}
            </td>
          </tr>
          <tr>
            <td colSpan="4">
              <b>Data e hora da finalização do ato: </b>{body.cert.dataHoraFinalizacao}
            </td>
          </tr>
          <tr>
            <td colSpan="4">
              <b>Data e hora do recebimento do ato pelo TJSC: </b>{body.cert.dataHoraRecebimento}
            </td>
          </tr>
          <tr>
            <td colSpan="4">
              <b>Data em que o ato foi solicitado: </b>{body.cert.dataSolicitacao}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <fieldset className="cgj">
        <legend className="cgj">Ato Protocolar</legend>
        <table width="100%" className="cgj">
          <tbody>
            <tr>
              <td colSpan="4">Campo 'atoProtocolar' ausente.</td>
            </tr>
          </tbody>
        </table>
      </fieldset>
      <br />
      <fieldset className="cgj">
        <legend className="cgj">Solicitante</legend>
        <table width="100%" className="cgj">
          <tbody>
            <tr>
              <td colSpan="5">
                <b>Nome: </b>{body.cert.nome_cert_sc}
              </td>
            </tr>
            <tr>
              <td colSpan="5">
                <b>Pessoa: </b>Física - <b>Estado Civil: </b> (a) - <b>Profissão:  </b>{body.cert.profissao} - <b>Nacionalidade: </b>{body.cert.nacionalidade} - <b>Sexo: </b>{body.cert.sexo}
              </td>
            </tr>
            <tr>
              <td valign="top">
                <b>Documentos</b>
              </td>
              <td colSpan="4">
                <table width="100%" className="cgj">
                  <tbody>
                    <tr>
                      <td>
                        <b>Doc. Tipo: </b>{body.cert.docTipo}
                      </td>
                      <td>
                        <b>Doc. Nº: </b>{body.cert.docNo}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td valign="top">
                <b>Endereços</b>
              </td>
              <td colSpan="4">
                <table width="100%" className="cgj">
                  <tbody>
                    <tr>
                      <td colSpan="3">
                        <b>Tipo: </b>{body.cert.tipo}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Logradouro: </b>{body.cert.logradouro}
                      </td>
                      <td>
                        <b>Número: </b>{body.cert.numero}
                      </td>
                      <td>
                        <b>Bairro: </b>{body.cert.bairro}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <b>Cidade/UF: </b>{body.cert.cidadeUf}
                      </td>
                      <td>
                        <b>CEP: </b>{body.cert.cep}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td valign="top">
                <b>Telefones</b>
              </td>
             
              <td colSpan="4">
                <table width="100%" className="cgj">
                  <tbody>
                    <tr>
                      <td>
                        <b>Tipo: </b>{body.cert.tipoTel}
                      </td>
                      <td>
                        <b>DDD: </b>{body.cert.telDDD}
                      </td>
                      <td>
                        <b>Número: </b>{body.cert.telNmr}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </fieldset>
      <br />
      <fieldset>
        <legend>Selo Digital</legend>
        <b>Tipo: </b>Selo Normal<br />
        <b>Selo Nº: </b>{body.cert.token}<br />
      </fieldset>
      <br />
      <fieldset>
        <legend>Informações Complementares</legend>
        <div>
          <b>Retificador: </b> Não
        </div>
      </fieldset>
      <br />
      <fieldset>
        <legend>Certidão</legend>
        <b>Número da via: </b>2<br />
      </fieldset>
      <br />
      <fieldset>
        <legend>Observações da certidão</legend>
    {body.cert.obs}
      </fieldset>
      <p />
    </div>
</div>
    <div class="card-footer"><button class="btn btn-danger" onClick={()=>{
      this.props.navigate(-1)
    }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Voltar</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        
<ToastContainer/>
        </>
  
    );
  }
}

export default PreViewCertSc;