import React, { Component } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave,faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from '../../../config';
import lg1 from "../../../images/funarpen.jpg"
import lg2 from "../../../images/logotj.png"
import dcmt from "../../../images/document.png"
class EditarCertEs extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
     data:[],
     tjes:[]
    };
   
  }

  componentDidMount() {
    document.title = "Editar certificado";
    var url  = window.location.href;
    const match = url.match(/edit\/(.*)$/);
    fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/dados-certificado/${match[1]}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({data,tjes:data.certificado,Loader:false})
})
}else if(response.status == 404){

  this.setState({error:true,Loader:false})
}
      })
}

 
handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      tjes: {
        ...prevState.tjes,
        [name]: value
      }
    }));
  };


  render() {
    var tjes = this.state.tjes;

    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    if(this.state.error == true){
      return(<>
      <div class="p404">
       <div>
        <img src={dcmt}/>
       <p>Desculpe, Esse documento não foi encontrado.</p>
       <button type="submit" class="btn btn-primary float-left ml-2" onClick={()=>{
                  this.props.navigate(`/${window.location.pathname.split("/")[1]}`)
               }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Início</button>
       </div>
     
      
      </div>
      </>)
    }
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight">Editar Certidão TjRS </h2></div></header>
       
        <main>
      <div className="py-12">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="row">
            <div className="col-12">
         
                <div className="card">
                  <div className="card-header">
                    <h3>Editar certidão:</h3>
                  </div>
                  <div class="card-body">
                  <div class="form-group">
                     <label>Nome da Serventia:</label>
                  
                     <select class="form-control form-control-sm" name='nomeSer' value={tjes.nomeSer} onChange={this.handleChange}>
                     <option style={{display:'none'}}></option>
                        {this.state.data.cartorios.map((item,index)=>{
                          
                            return(<>
                            <option value={item.id}>{item.nome}</option>
                            </>)
                        })}
                       
                     </select>
                  </div>
                 
          

        <div class="form-group mt-2">
                     <label>Tipo do ato:</label>
                     <select class="form-control form-control-sm"  name="ato" value={tjes.ato} onChange={this.handleChange}>
                       <option value="CERTIDÃO">CERTIDÃO</option>
                       <option value="CERTIDÃO DE NASCIMENTO"> CERTIDÃO DE NASCIMENTO </option>
                       <option value="CERTIDÃO DE CASAMENTO"> CERTIDÃO DE CASAMENTO </option>
                       <option value="CERTIDÃO DE ÓBITO"> CERTIDÃO DE ÓBITO </option>
                     </select>
                  </div>
        <div className="form-group mt-2">
          <label>Serviço:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="servico"
            value={tjes.servico}
            onChange={this.handleChange}
          />
        </div>
        <div className="form-group mt-2">
          <label>Emolumentos:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="emolumentos"
            value={tjes.emolumentos}
            onChange={this.handleChange}
          />
        </div>
        <div className="form-group mt-2">
          <label>FUNEPJ:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="funepj"
            value={tjes.funepj}
            onChange={this.handleChange}
          />
        </div>
        <div className="form-group mt-2">
          <label>FADESPES:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="fadespes"
            value={tjes.fadespes}
            onChange={this.handleChange}
          />
        </div>
        <div className="form-group mt-2">
          <label>FUNEMP:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="funemp"
            value={tjes.funemp}
            onChange={this.handleChange}
          />
        </div>
        <div className="form-group mt-2">
          <label>FUNCAD:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="funcad"
            value={tjes.funcad}
            onChange={this.handleChange}
          />
        </div>
        <div className="form-group mt-2">
          <label>Encargos:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="encargos"
            value={tjes.encargos}
            onChange={this.handleChange}
          />
        </div>
        <div className="form-group mt-2">
          <label>Valor de Referência:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="valor_referencia"
            value={tjes.valor_referencia}
            onChange={this.handleChange}
          />
        </div>
        <div className="form-group mt-2">
          <label>Protocolo/Apontamento:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="protocolo_apontamento"
            value={tjes.protocolo_apontamento}
            onChange={this.handleChange}
          />
        </div>
        <div className="form-group mt-2">
          <label>Livro:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="livro"
            value={tjes.livro}
            onChange={this.handleChange}
          />
        </div>
        <div className="form-group mt-2">
          <label>Termo:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="termo"
            value={tjes.termo}
            onChange={this.handleChange}
          />
        </div>
        <div className="form-group mt-2">
          <label>Folha:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="folha"
            value={tjes.folha}
            onChange={this.handleChange}
          />
        </div>
        <div className="form-group mt-2">
          <label>Firma:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="firma"
            value={tjes.firma}
            onChange={this.handleChange}
          />
        </div>

                 
               </div>
                  <div className="card-footer">
                   

                  <button type="submit" class="btn btn-danger float-left ml-2" onClick={()=>{
                  this.props.navigate(-1)
               }}><FontAwesomeIcon icon={faArrowLeft} /> Voltar</button>



                    <button type="submit" className="btn btn-primary float-right ml-2" onClick={()=>{
                            var url  = window.location.href;
                            const match = url.match(/edit\/(.*)$/);
                            var body = this.state.data.certificado;
                            fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/atualizar-certificado/${match[1]}`, {
                            method: 'POST',
                            credentials: 'include',
                            headers: {
                              'Content-Type': 'application/json',
                              'Authorization': 'Bearer '+Cookies.get("auth_token"),
                            },
                            body:JSON.stringify({
                            tjes
                            })
                          }).then(response => {
                            if(response.status == 200){
                            response.json().then(data=>{

                              this.props.navigate(`/${window.location.pathname.split("/")[1]}/preview/${this.state.data.certificado.token}`)        
                            })
                                 }
                          })
                    }}>
               <FontAwesomeIcon icon={faSave} /> Salvar
                    </button>
                  </div>
                </div>
          
            </div>
          </div>
        </div>
      </div>
    </main>

        
<ToastContainer/>
        </>
  
    );
  }
}

export default EditarCertEs;